import React, { } from "react";
import "./style.scss";
import { useSelector } from "react-redux";
import { selectIsBankTransfer, selectIsBankTransferCepBank, selectIsThorPay, selectProgressIndex } from "../../../redux/slices/paymentSlice";
import DepositModal1 from "./DepositModal1";
import DepositModal2 from "./DepositModal2";
import DepositModal3 from "./DepositModal3";
import Popup from "../../commons/ModalET";
import DepositModalBankTransfer from "./DepositModalBankTransfer";
import DepositModalBankTransferCepBank from "./DepositModalBankTransferCepBank";
import DepositModalThorPay from "./DepositModalThorPay";

const DepositModal = ({ isOpen, onClose, logo, title, modalRef }) => {
  const progressIndex = useSelector(selectProgressIndex);
  const isBankTransfer = useSelector(selectIsBankTransfer)
  const isBankTransferCepBank = useSelector(selectIsBankTransferCepBank)

  return (
    <Popup modalRef={modalRef} isOpen={isOpen} onClose={onClose}>
      <div className="depositModal">
        <div className="depositModal--top">
          <span className="depositModal--top--title">Para Yatır</span>
          <img src={logo} alt={'icon'} style={{ maxWidth: '130px' }} />
        </div>

        {
          progressIndex === 0 ? isBankTransfer ? <DepositModalBankTransfer /> : isBankTransferCepBank ? <DepositModalBankTransferCepBank /> : <DepositModal1 /> :
            progressIndex === 1 ? <DepositModal2 /> :
              progressIndex === 2 ? <DepositModal3 /> :
                progressIndex === 3 ? <DepositModalThorPay /> :
                  <></>
        }

      </div>
    </Popup>
  );
};

export default DepositModal;
