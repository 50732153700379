//React Imports
import React from "react";

//Style Imports
import "./styles.scss";

//Component Imports
import { Container } from "react-bootstrap";
import FooterColumn from "../../commons/FooterColumn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faCoffee } from "@fortawesome/free-solid-svg-icons";

//Img Imports
import logo from "../../../assets/png/logo.png";
import angleUp from "../../../assets/icons/angleUp.svg";
import age from "../../../assets/icons/+18.svg";
import mga from "../../../assets/icons/mga.svg";
import begam from "../../../assets/icons/begam.png";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer--top">
        <img src={angleUp} className="footer--top--angleUpIcon" />
        <img src={logo} />
      </div>
      <div className="footer--mid">
        <Container>
          <div className="footer--mid--inner">
            <FooterColumn />
          </div>
        </Container>
      </div>
      <div className="footer--bottom">
        <Container>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel
            condimentum enim. Cras sed ex ornare urna interdum maximus id sed
            massa. Donec faucibus quis lectus eu aliquet. Integer id varius
            nunc. Sed ac facilisis eros, vitae facilisis lacus. Morbi massa
            lacus, egestas consequat ligula vitae, volutpat feugiat augue.
            Vestibulum fringilla pharetra mi nec bibendum. Praesent sem mauris,
            posuere vel ipsum sed, condimentum finibus augue. Morbi ac nisl nec
            lectus cursus rutrum. Mauris ultrices nisi et odio egestas, ut
            sodales ex bibendum. In hac habitasse platea dictumst. Cras dui
            turpis, lobortis ut pulvinar in, viverra in nulla. Donec elit felis,
            consequat finibus metus in, eleifend ullamcorper ipsum. Nunc mauris
            tortor, interdum non pellentesque nec, dapibus non ipsum. Nam lacus
            ligula, convallis eget tortor at, ultrices porttitor erat.
          </p>
          <div className="footer--bottom--logos">
            <img src={age} />
            <img src={mga} />
            <img src={begam} />
          </div>
        </Container>
      </div>
      <div className="footer--designer-sign">
        <Container>
          <div className="footer--designer-sign--inner">Designed by Etorobet ®  1998 - 2022 - Etorobet Ltd</div>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
