import React from "react";

import "../../style.scss";
import {
  TypeFootball,
  TypeTennis,
  TypeBasketball,
} from "./SportsHeaderColumnTypes";


const SportHeader = ({ title, icon, columnType }) => {

  const detectType = () => {
    switch (columnType) {
      case "ColumnFootBall":
        return <TypeFootball />;
      case "ColumnTennis":
        return <TypeTennis />;
      case "ColumnBasketBall":
        return <TypeBasketball />;
      default:
        break;
    }
  };

  return (
    <div className="sportHeader">
      <div className="sportHeader--left">
        <img src={icon} />
        <span>{title}</span>
      </div>
      <div className="sportHeader--right">{detectType()}</div>
    </div>
  );
};

export default SportHeader;
