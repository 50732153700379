import React from 'react';
import logo from './logo.svg';
import './App.css';
import Router from './router';
import Layout from './components/layout/Layout';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <div className="App">
      <Layout>
        <ToastContainer
          position="top-left"
          limit={3}
          pauseOnFocusLoss={false}
          pauseOnHover={false}
        />
        <Router />
      </Layout>
    </div>
  );
}

export default App;