import React from "react";
import "./style.scss";

const SettingsBox = ({ title, icon, onClick }) => {
  return <div className="settingsBox" onClick={() => onClick && onClick()}>
      <img src={icon}/>
      <span>{title}</span>
  </div>
};

export default SettingsBox;
