import React from "react";

//Style Imports
import "./style.scss";

//Img Imports
import paymentLine from "../../../../assets/png/homePage/payment.png";
import { Container } from "react-bootstrap";

const PaymentTypes = () => {
  return (
    <Container>
      <div className="paymentTypes">
        <img src={paymentLine} />
      </div>
    </Container>
  );
};

export default PaymentTypes;
