import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    banners: null,
    summary: null,
    bonuses: null,
    casino: null,
    liveCasino: null,
    gameUrl: "",
    iframeModal: false,
}

export const gamesSlice = createSlice({
    name: 'games',
    initialState,
    reducers: {
        setBanners: (state, action) => {
            state.banners = action.payload
        },
        setSummary: (state, action) => {
            state.summary = action.payload
        },
        setBonuses: (state, action) => {
            state.bonuses = action.payload
        },
        setCasino: (state, action) => {
            state.casino = action.payload
        },
        setLiveCasino: (state, action) => {
            state.liveCasino = action.payload
        },
        setGameUrl: (state, action) => {
            state.gameUrl = action.payload
        },
        setIframeModal: (state, action) => {
            state.iframeModal = action.payload
        },
    }
});

//Actions 
export const { setBanners, setSummary, setBonuses, setCasino, setLiveCasino, setGameUrl, setIframeModal } = gamesSlice.actions;

///Selectors
export const selectBanners = (state) => state.games.banners;
export const selectSummary = (state) => state.games.summary;
export const selectBonuses = (state) => state.games.bonuses;
export const selectCasino = (state) => state.games.casino;
export const selectLiveCasino = (state) => state.games.liveCasino;
export const selectGameUrl = (state) => state.games.gameUrl;
export const selectIframeModal = (state) => state.games.iframeModal;

export default gamesSlice.reducer;