import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom"
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Styles Imports
import "./style.scss";
import GameCard from "../../../commons/GameCard";

//Component Imports

//Img Imports

const Popular3DGames = () => {
  const count = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <div className="populer3DGames">
      <Container>
        <div className="populer3DGames--title">
          <p className="tab-title">Popüler 3D Slot Oyunları</p>
          <div className="populer3DGames--title--showAll showAllBtnIcons">
            <FontAwesomeIcon icon={faChevronLeft} />
            <p className="showAllBtn"><Link to="/casino" onClick={() => window != undefined && window.scrollTo(0, 0)}>Tümünü Gör</Link></p>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
        <div className="tab-title-underLine"></div>
        <div className="gameCardsCont">
            {
                count.map(i => (
                    <GameCard key={i+2}/>
                ))
            }
        </div>
      </Container>
    </div>
  );
};

export default Popular3DGames;
