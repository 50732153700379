import React, { useState } from "react";
import "../style.scss";

const HighestProfits = () => {
  const [activeProfitRange, setActiveProfitRange] = useState(0);
  const count = [1, 2, 3, 4, 5, 6, 7, 8, 9,10];

  return (
    <div className="sportsbook--content--right--highestProfits">
      <div className="sportsbook--content--right--highestProfits--header">
        <span className="sportsbook--content--right--highestProfits--header--title">
          Yüksek Kazançlar
        </span>
        <div className="sportsbook--content--right--highestProfits--header--range">
          <span>1 Gün</span>
          <span>1 Hafta</span>
          <span>1 Ay</span>
        </div>
      </div>
      <div className="sportsbook--content--right--highestProfits--title">
        <span>Bahis ID</span>
        <span>Miktar</span>
        <span>Kazanç</span>
      </div>
      <div className="sportsbook--content--right--highestProfits--table">
        {count.map((c,index) => (
          <div key={index + 3} className="sportsbook--content--right--highestProfits--table--item">
            <div className="sportsbook--content--right--highestProfits--table--item--order">
              <span className={`${index == 0 ? "firstOrder" : index == 1 ? "secondOrder" : index == 2 ? "thirdOrder" : ''}`}>{index + 1}</span>
              <span>41714507</span>
            </div>
            <span>5000</span>
            <span>21450 ₺</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HighestProfits;
