import React from 'react';
import MatchBetRatio from './MatchBetRatio';
import MatchBetRatioHeader from './MatchBetRatioHeader';
import SportHeader from './SportHeader';

const MatchItem = ({ icon, title, columnType }) => {
  return (
    <div>
        <SportHeader icon={icon} title={title} columnType={columnType} />
        <div className="ratioHeaderAndRatiosWrapper">
            <MatchBetRatioHeader />
            <MatchBetRatio />
        </div>
    </div>
  )
}

export default MatchItem