import React from 'react';
import './style.scss';

const SelectComponent = ({ selectedOption, setSelectedOption, data, currencyType, labelText, style }) => {

    return (
        <div className='selectETwrapper'>
            {
                labelText !== "" ?
                    <div className={"selectETwrapper__label"}>{labelText}</div>
                    : <></>
            }
            <select value={selectedOption} onChange={setSelectedOption} className='custom-select' style={style}>
                {
                    currencyType !== "phone" ?
                        <option value="Seçiniz">Seçiniz</option>
                        : <></>
                }
                {
                    currencyType === "currency" && data?.map(currency => (
                        <option value={currency?.currency}>{currency?.currency}</option>
                    ))
                }
                {
                    currencyType === "cryptoPayTypes" && data?.map(currency => (
                        <option value={currency?.type}>{currency?.type}</option>
                    ))
                }
                {
                    currencyType === "bankList" && data?.map(bank => (
                        <option value={bank?.code}>{bank?.name}</option>
                    ))
                }
                {
                    currencyType === "phone" && data?.map(phoneCode => (
                        <option value={phoneCode?.code}>{phoneCode?.code}</option>
                    ))
                }
                {
                    currencyType === "currencyRegister" && data?.map(currencyCode => (
                        <option value={currencyCode?.code}>{currencyCode?.code}</option>
                    ))
                }
                {
                    currencyType === "lang" && data?.map(currencyCode => (
                        <option value={currencyCode?.val}>{currencyCode?.code}</option>
                    ))
                }
                {
                    currencyType === "bankName" && data?.map(currencyCode => (
                        <option value={currencyCode?.id}>{currencyCode?.bankName}</option>
                    ))
                }
                {
                    (currencyType === "country" || currencyType === "question" || currencyType === "gender") && data?.map(country => (
                        <option value={country?.code}>{country?.code}</option>
                    ))
                }
                {
                    (currencyType === "day" || currencyType === "month" || currencyType === "year") && data
                }
            </select>
        </div>
    );
};

export default SelectComponent;