import React, { useEffect, useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import "./style.scss";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import InputBoxArea from "../../../commons/CustomModal/InputBoxArea";
import CustomButton from "../../../commons/Button";
import { disableMfa, enable2FA, getProfile, updateProfile, verifyMfaToken } from "../../../../services/profile";
import { selectMfaModal, selectMfaQrCode, selectMfaSecret, setMfaModal, selectProfile, selectUpdateInputsEnabled, setUpdateInputEnabled, selectIsDisabledProfile } from "../../../../redux/slices/profileSlice";
import { selectLoggedInUser } from "../../../../redux/slices/authSlice";

const ProfileAyarlari = () => {

    const dispatch = useDispatch();
    const profile = useSelector(selectProfile)?.data?.UserAttributes;
    const loggedUser = useSelector(selectLoggedInUser);
    const modalStatus = useSelector(selectMfaModal)
    const mfaSecret = useSelector(selectMfaSecret);
    const mfaQrCode = useSelector(selectMfaQrCode);
    const [verifyToken, setVerifyToken] = useState("")
    const isDisabled = useSelector(selectIsDisabledProfile);
    const updateInputsEnabled = useSelector(selectUpdateInputsEnabled);

    useEffect(() => {
        dispatch(getProfile());
    }, [])

    const loginSchema = yup.object({
        userName: yup.string().required("* Kullanıcı adı girin"),
        given_name: yup.string().required("* Adınızı girin"),
        family_name: yup.string().required("* Soyadınızı girin"),
        birthdate: yup.string(),
        email: yup.string().matches(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            "* Geçerli bir mail adresi girin"
        ).required("* Mail adresi girin"),
        countryCode: "",
        address: yup.string().required("* Adres giriniz")
    });

    const initialValues = {
        address: profile?.filter(prof => prof?.Name === "address")?.[0].Value,
        birthdate: profile?.filter(prof => prof?.Name === "birthdate")?.[0].Value,
        given_name: profile?.filter(prof => prof?.Name === "given_name")?.[0].Value,
        countryCode: profile?.filter(prof => prof?.Name === "custom:countryCode")?.[0].Value,
        family_name: profile?.filter(prof => prof?.Name === "family_name")?.[0].Value,
        email: profile?.filter(prof => prof?.Name === "email")?.[0].Value,
        userNo: profile?.filter(prof => prof?.Name === "sub")?.[0].Value,
        userName: loggedUser?.Username,
    };

    const handleUpdateProfile = (values) => {
        dispatch(updateProfile(values))
    }

    return (
        <Container>
            <div className="profileSettings-wrapper">
                <span className="profileSettings-wrapper--header">Profil Ayarları</span>

                {
                    profile ?
                        <>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={loginSchema}
                                onSubmit={(values) => { }}
                            >
                                {({ handleSubmit, handleChange, values, touched, resetForm, errors }) => (
                                    <Form noValidate onSubmit={handleSubmit}>

                                        <div className="profileSettings-wrapper--inputs-line">
                                            <InputBoxArea
                                                key={"userNo"}
                                                error={errors["userNo"]}
                                                value={values["userNo"]}
                                                handleChange={handleChange}
                                                touched={touched["userNo"]}
                                                formName={"userNo"}
                                                placeholder={"••••••••"}
                                                title={"Kullanıcı No"}
                                                disabled={true}
                                                type={"text"}
                                            />

                                            <InputBoxArea
                                                key={"userName"}
                                                error={errors["userName"]}
                                                value={values["userName"]}
                                                handleChange={handleChange}
                                                touched={touched["userName"]}
                                                formName={"userName"}
                                                placeholder={"••••••••"}
                                                title={"Kullanıcı Adı"}
                                                disabled={updateInputsEnabled}
                                                type={"text"}
                                            />
                                        </div>

                                        <div className="profileSettings-wrapper--inputs-line">
                                            <InputBoxArea
                                                key={"given_name"}
                                                error={errors["given_name"]}
                                                value={values["given_name"]}
                                                handleChange={handleChange}
                                                touched={touched["given_name"]}
                                                formName={"given_name"}
                                                placeholder={"••••••••"}
                                                title={"Ad"}
                                                disabled={updateInputsEnabled}
                                                type={"text"}
                                            />

                                            <InputBoxArea
                                                key={"family_name"}
                                                error={errors["family_name"]}
                                                value={values["family_name"]}
                                                handleChange={handleChange}
                                                touched={touched["family_name"]}
                                                formName={"family_name"}
                                                placeholder={"••••••••"}
                                                title={"Soyad"}
                                                disabled={updateInputsEnabled}
                                                type={"text"}
                                            />
                                        </div>

                                        <div className="profileSettings-wrapper--inputs-line">
                                            <InputBoxArea
                                                key={"birthdate"}
                                                error={errors["birthdate"]}
                                                value={values["birthdate"]}
                                                handleChange={handleChange}
                                                touched={touched["birthdate"]}
                                                formName={"birthdate"}
                                                placeholder={"••••••••"}
                                                title={"Doğum Tarihi"}
                                                disabled={updateInputsEnabled}
                                                type={"text"}
                                            />

                                            <InputBoxArea
                                                key={"email"}
                                                error={errors["email"]}
                                                value={values["email"]}
                                                handleChange={handleChange}
                                                touched={touched["email"]}
                                                formName={"email"}
                                                placeholder={"••••••••"}
                                                title={"E-posta"}
                                                disabled={updateInputsEnabled}
                                                type={"text"}
                                            />
                                        </div>

                                        <div className="profileSettings-wrapper--inputs-line-w-50">
                                            <div className="inputBoxArea">
                                                <span>Ülke *</span>
                                                <Form.Select
                                                    onChange={handleChange}
                                                    value={values.countryCode}
                                                    name={"countryCode"}
                                                    defaultValue={""}
                                                    disabled={updateInputsEnabled}
                                                >
                                                    <option value={""}>Ülke seçin</option>
                                                    <option value={"Turkiye"}>Türkiye</option>
                                                    <option value={"Amerika"}>Amerika</option>
                                                    <option value={"Hollanda"}>Hollanda</option>
                                                    <option value={"Almanya"}>Almanya</option>
                                                </Form.Select>
                                            </div>
                                        </div>

                                        <div className="profileSettings-wrapper--inputs-line">
                                            <InputBoxArea
                                                as={'textarea'}
                                                key={"address"}
                                                error={errors["address"]}
                                                value={values["address"]}
                                                handleChange={handleChange}
                                                touched={touched["address"]}
                                                formName={"address"}
                                                placeholder={"••••••••"}
                                                title={"Adres"}
                                                disabled={updateInputsEnabled}
                                                type={"text"}
                                            />
                                        </div>

                                        <div className='MFA-info-box'>
                                            <div className="MFA-info-box__title">2 AŞAMALI KİMLİK DOĞRULAMA</div>
                                            <div className="MFA-info-box__p1">İki aşamalı kimlik doğrulama henüz etkinleştirilmedi.</div>
                                            <div className="MFA-info-box__p2">İki aşamalı kimlik doğrulama, oturum açmak için yalnızca bir paroladan fazlasını gerektirerek hesabınıza ek bir güvenlik katmanı ekler.</div>
                                        </div>

                                        <div className="btn-wrapper">
                                            <div className="loginModal--btn">
                                                <CustomButton
                                                    title="2FA AKTİF ET"
                                                    backgroundColor={null}
                                                    textColor={"#fff"}
                                                    backgroundImage={"linear-gradient(to right, #f19524 0%, #fb713a 100%)"}
                                                    fontWeight={"normal"}
                                                    disabled={isDisabled}
                                                    fontSize={"14px"}
                                                    width={"300px"}
                                                    onClick={() => dispatch(enable2FA())}
                                                />
                                            </div>

                                            <div className="loginModal--btn">
                                                <CustomButton
                                                    title="2FA Devre Dışı Bırak"
                                                    backgroundColor={null}
                                                    textColor={"#fff"}
                                                    backgroundImage={"linear-gradient(to right, #f19524 0%, #fb713a 100%)"}
                                                    fontWeight={"normal"}
                                                    disabled={true}
                                                    fontSize={"14px"}
                                                    width={"300px"}
                                                    onClick={() => dispatch(disableMfa())}
                                                />
                                            </div>
                                        </div>

                                        {
                                            updateInputsEnabled ?
                                                <>
                                                    <div className="loginModal--btn">
                                                        <CustomButton
                                                            title="Profili Güncelle"
                                                            backgroundColor={null}
                                                            textColor={"#fff"}
                                                            backgroundImage={"linear-gradient(to right, #f19524 0%, #fb713a 100%)"}
                                                            fontWeight={"normal"}
                                                            disabled={isDisabled}
                                                            fontSize={"14px"}
                                                            width={"300px"}
                                                            onClick={() => dispatch(setUpdateInputEnabled(false))}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <div className="btn-wrapper">
                                                    <div className="loginModal--btn">
                                                        <CustomButton
                                                            title="Değişiklikleri Kaydet"
                                                            backgroundColor={null}
                                                            textColor={"#fff"}
                                                            backgroundImage={"linear-gradient(84deg, #863cc4 0%, #1631ba 100%)"}
                                                            fontWeight={"normal"}
                                                            disabled={
                                                                values?.address === "" ||
                                                                values?.birthdate === "" ||
                                                                values?.given_name === "" ||
                                                                values?.countryCode === "" ||
                                                                values?.family_name === "" ||
                                                                values?.email === "" ||
                                                                values?.userNo === "" ||
                                                                values?.userName === "" || Object.keys(errors)?.length || isDisabled
                                                            }
                                                            fontSize={"14px"}
                                                            width={"300px"}
                                                            onClick={() => handleUpdateProfile(values)}
                                                        />
                                                    </div>

                                                    <div className="loginModal--btn">
                                                        <CustomButton
                                                            title="İptal"
                                                            backgroundColor={null}
                                                            textColor={"#fff"}
                                                            backgroundImage={"linear-gradient(84deg, #863cc4 0%, #1631ba 100%)"}
                                                            fontWeight={"normal"}
                                                            disabled={false}
                                                            fontSize={"14px"}
                                                            width={"300px"}
                                                            onClick={() => {
                                                                resetForm()
                                                                dispatch(setUpdateInputEnabled(true))
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                        }

                                    </Form>
                                )}
                            </Formik>
                        </>
                        : <></>
                }

                <Modal className={"Mfa-Modal"} show={modalStatus} onHide={(status) => dispatch(setMfaModal(status))}>
                    <Modal.Header closeButton>
                        <Modal.Title>2FA AKTİF ET</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Aşağıdaki QR kodunu telefonunuzda Google Authenticator gibi bir kimlik doğrulama uygulamasıyla tarayın
                        </p>

                        <img src={mfaQrCode} />

                        <span>Doğrulama Kodu:</span>
                        <input placeholder="Doğrulama Kodu" value={verifyToken} onChange={(e) => setVerifyToken(e.target.value)} />

                        <CustomButton
                            title="Kodu Doğrula"
                            backgroundColor={null}
                            textColor={"#fff"}
                            backgroundImage={"linear-gradient(74deg, #863cc4 0%, #1631ba 100%)"}
                            fontWeight={"normal"}
                            // disabled={}
                            fontSize={"14px"}
                            width={"300px"}
                            onClick={() => dispatch(verifyMfaToken(mfaSecret, verifyToken))}
                        />

                    </Modal.Body>
                </Modal>

            </div>
        </Container>
    );
};

export default ProfileAyarlari;
