import React from "react";
import MobileDetect from "mobile-detect";

//Style Imports
import "./style.scss";

//Img Imports
import gameCardImg from "../../assets/casino-icons/gameCard.png";
import playtechIcon from "../../assets/casino-icons/providerPlaytech2.svg";
import CustomButton from "../commons/Button";
import peopleIcon from "../../assets/icons/userIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { selectLoggedInUser, setLoginModal } from "../../redux/slices/authSlice";
import { launchGame } from "../../services/games";

const CasinoGameCard = (props) => {

  const dispatch = useDispatch();
  const loggedInUser = useSelector(selectLoggedInUser);
  const md = new MobileDetect(window.navigator.userAgent);

  const handleGetIFrameUrl = () => {

  }

  return (
    <div className="games-wrapper--card" onClick={() => {
      if (loggedInUser?.Authenticated) {
        console.log('props.provider', props.provider)
        if (props.provider === "ezugi" || props.provider === "png" || props.provider === "egt") {
          dispatch(launchGame(props.id, props.gameCategory, props.tableId, props.localGameId, JSON.parse(props?.localGameType)['en'], props.isLive, props.provider, "v1", md.mobile()))
        } else {
          dispatch(launchGame(props.id, props.gameCategory, props.tableId, props.localGameId, JSON.parse(props?.localGameType)['en'], props.isLive, props.provider, "v2", md.mobile()))
        }
      } else {
        dispatch(setLoginModal(true))
      }
    }}>
      <img src={gameCardImg} />
      <div className="games-wrapper--card--providerCard">
        <div className="games-wrapper--card--providerCard--providerbg"></div>
        <div className="games-wrapper--card--providerCard--providerInfo">
          <img src={playtechIcon} />
          <span>{props.provider}</span>
        </div>
      </div>
      <div className="games-wrapper--card--onhover" >
        <div className="games-wrapper--card--onhover--top">
          <img src={playtechIcon} />
          <span>{props.provider}</span>
        </div>
        <div className="games-wrapper--card--onhover--mid">
          <span>{props.remote_name}</span>
        </div>
        <div className="games-wrapper--card--onhover--bot">
          <CustomButton
            title={"((  )) Şimdi Oyuna Gir"}
            backgroundColor={null}
            textColor={"#fff"}
            backgroundImage={
              "linear-gradient(to right, #f19524 0%, #fb713a 100%)"
            }
            fontWeight={"normal"}
            fontSize={"14px"}
            width={"252px"}
            height={"40px"}
            onClick={props.onClick}
          />
          <div className="games-wrapper--card--onhover--bot--infoBoxes">
            <div className="infoBox infoBox-red">
              <img src={peopleIcon} />
              <span>Canlı</span>
            </div>
            <div className="infoBox">
              <img src={peopleIcon} />
              <span>21503</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CasinoGameCard;
