import React from "react";

//Style Imports
import "./style.scss";
//Img Imports
import gameImg from "../../../assets/png/homePage/sweet_bonanza.png";

const GameCard = () => {
  return (
    <div className="gameCard">
      <img src={gameImg} />
      <div className="gameCard-content">
        <div className="gameCard-content--game-info">
          <span>Sweet Bonanza</span>
          <span>Betsoft</span>
        </div>
        <div className="gameCard-btn">Hemen Oyna</div>
      </div>
    </div>
  );
};

export default GameCard;
