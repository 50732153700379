import React, { useState } from "react";
import "./style.scss";

// Component Imports
import { Button, Container, Offcanvas } from "react-bootstrap";
import Tabs from "../commons/PageSideBar";
import Aboutus from "./TabComponents/Aboutus";
import SSS from "./TabComponents/SSS";
import CustomButton from "../commons/Button";
import logo from "../../assets/png/logo.png"

const AboutUs = () => {
  const tabs = [
    { title: "Hakkımızda", component: <Aboutus /> },
    { title: "Sıçka Sorulan Sorular", component: <SSS /> },
    { title: "KYC Politikaları ve KVKK", component: <Aboutus /> },
    { title: "Hakkımızda", component: <SSS /> },
    { title: "Hesap, Ödemeler", component: <Aboutus /> },
    { title: "Bonuslar", component: <SSS /> },
    { title: "AML Politikası", component: <Aboutus /> },
    { title: "Kullanım Şartları", component: <SSS /> },
    { title: "Uyuşmazlık Çözümü", component: <Aboutus /> },
    { title: "Ödeme Yöntemleri", component: <SSS /> },
    { title: "Sorumlu Oyun", component: <Aboutus /> },
    { title: "Ortaklıktan Çıkma", component: <SSS /> },
  ];

  const [activeTab, setActiveTab] = useState(0);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="aboutUs">
      <Offcanvas show={show} onHide={handleClose} className="aboutUs--aboutUsMobileMenu">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title><img src={logo}/></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Tabs
            tabs={tabs}
            isActiveTab={activeTab}
            setActiveTab={setActiveTab}
            setShow={setShow}
          />
        </Offcanvas.Body>
      </Offcanvas>

      <Container>
        <div className="aboutUs--inner">
          <div
            className="aboutUs--inner--menubtn"
            onClick={handleShow}
          >
            <CustomButton
              title={"Diğer Başlıklar"}
              backgroundColor={null}
              backgroundImage={"linear-gradient(to right, #f19524 0%, #fb713a 100%)"}
              textColor={"#fff"}
              fontWeight={"normal"}
              width={"100%"}
              fontSize={"14px"}
            />
          </div>

          <div className="aboutUs--inner--links">
            <Tabs
              tabs={tabs}
              isActiveTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
          <div className="aboutUs--inner--content">
            {tabs[activeTab].component}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AboutUs;
