import React from 'react';

import "../style.scss";

//Img Imports 
import footBallIcon from "../../../assets/sportsbook/football.svg";

const SportBookLineItem = () => {
  return (
    <div className='sportsBookLineItem'>
        <img src={footBallIcon}/>
        <span>FC Botosani - Real Sociedad</span>
    </div>
  )
}

export default SportBookLineItem