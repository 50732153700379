import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    profile: null,
    balance: null,
    mfaSecret: "",
    mfaQrCode: "",
    mfaModal: false,
    isDisabled: false,
    updateInputsEnabled: true,
    userMenu: false,
    userPanel: false
};

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setProfile: (state, action) => {
            state.profile = action.payload
        },
        setBalance: (state, action) => {
            state.balance = action.payload
        },
        setMfaSecret: (state, action) => {
            state.mfaSecret = action.payload
        },
        setMfaQrCode: (state, action) => {
            state.mfaQrCode = action.payload
        },
        setMfaModal: (state, action) => {
            state.mfaModal = action.payload
        },
        setIsDisabledProfile: (state, action) => {
            state.isDisabled = action.payload
        },
        setUpdateInputEnabled: (state, action) => {
            state.updateInputsEnabled = action.payload
        },
        setUserMenu: (state, action) => {
            state.userMenu = action.payload
        },
        setUserPanel: (state, action) => {
            state.userPanel = action.payload
        }
    }
});

//Actions 
export const { setProfile, setBalance, setMfaSecret, setMfaQrCode, setMfaModal, setIsDisabledProfile, setUpdateInputEnabled, setUserMenu, setUserPanel } = profileSlice.actions;

///Selectors
export const selectProfile = (state) => state.profile.profile;
export const selectBalance = (state) => state.profile.balance;
export const selectMfaSecret = (state) => state.profile.mfaSecret;
export const selectMfaQrCode = (state) => state.profile.mfaQrCode;
export const selectMfaModal = (state) => state.profile.mfaModal;
export const selectIsDisabledProfile = (state) => state.profile.isDisabled;
export const selectUpdateInputsEnabled = (state) => state.profile.updateInputsEnabled;
export const selectUserMenu = (state) => state.profile.userMenu;
export const selectUserPanel = (state) => state.profile.userPanel;

export default profileSlice.reducer;