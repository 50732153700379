import { setBankList, setBonusForSinglePaymentMethod, setBonuses, setDepositErrorPopup, setDepositErrorText, setDepositModal, setIsLoading, setIsPageLoading, setPaymentMethods, setProgressIndex, setPromotionCodeStatus, setRedirectBtnUrlForPayment } from "../redux/slices/paymentSlice"
import { BankList, BankTransfer, BankTransferCepbank, Bonuses, CryptoPayment, EtoroCryptoPayment, FxCuzdan, GuvenilirQR, HizliPapara, MovenPayCreditCard, MovenpayPapara, OdendibilPep, OdendibilQr, PaymentMethods, PromotionCode, SapphirePapara, ThorPay, TurboHavale, TurboYatirim, Vevopay } from "../utils/httphelper"
import { setGenericPopupInfo } from '../redux/slices/genericsSlice'
import { Fixturka } from "../utils/httphelper"
import { setBonusesWithdraw, setPaymentMethodsWithdraw } from "../redux/slices/paymentSliceWithdraw"

export const getBonuses = (method) => async (dispatch) => {
    const getBonusesResult = await Bonuses.getBonuses(method)

    if (method === "Deposit") {
        dispatch(setBonuses(getBonusesResult.data?.bonusList))
    } else {
        dispatch(setBonusesWithdraw(getBonusesResult.data?.bonusList))
    }
}

export const getBankList = () => async (dispatch) => {
    dispatch(setIsLoading(true))
    const getBankListResult = await BankList.getBankList();
    dispatch(setIsLoading(false))

    if (getBankListResult?.status === 200) {
        let newList = [];

        Object.entries(getBankListResult?.data?.message?.banks).forEach(([key, value]) => {
            newList.push(value)
        });

        dispatch(setBankList(newList))
        dispatch(setDepositModal(true));
        window.scroll({ top: 0, left: 0, behavior: "smooth" })
    }
}

export const getBankListForPep = (reqBody) => async (dispatch) => {
    dispatch(setIsLoading(true))
    const getBankListResult = await BankList.getBankListPep(reqBody);
    dispatch(setIsLoading(false))
    const newList = [];

    getBankListResult?.data?.result?.forEach(bank => {
        newList.push({
            code: bank?.bank_id,
            name: bank?.name,
        })
    })

    if (getBankListResult?.status === 200) {
        dispatch(setBankList(newList))
        dispatch(setDepositModal(true));
        window.scroll({ top: 0, left: 0, behavior: "smooth" })
    }
}

export const getBankListForOdebitir = (reqBody) => async (dispatch) => {
    dispatch(setIsLoading(true))
    const getBankListResult = await BankList.getBankListOdebitir(reqBody);
    dispatch(setIsLoading(false))
    const newList = [];

    // getBankListResult?.data?.result?.forEach(bank => {
    //     newList.push({
    //         code: bank?.bank_id,
    //         name: bank?.name,
    //     })
    // })

    if (getBankListResult?.status === 200) {
        dispatch(setBankList(newList))
        dispatch(setDepositModal(true));
        window.scroll({ top: 0, left: 0, behavior: "smooth" })
    }
}

export const getMethodsApp = (operation) => async (dispatch) => {
    dispatch(setIsPageLoading(true))
    let getMethodsAppResult = await PaymentMethods.getMethodsApp(operation)
    dispatch(setIsPageLoading(false))

    if (getMethodsAppResult.status === 200) {
        if (operation === "Deposit") {
            dispatch(setPaymentMethods(getMethodsAppResult.data.paymentMethods))
        } else {
            dispatch(setPaymentMethodsWithdraw(getMethodsAppResult?.data?.paymentMethods))
        }
    }

}

export const getBonusByPaymentMethod = (amount, currency, paymentMethod, username) => async (dispatch) => {

    const reqBody = {
        amount: { value: amount, currency: currency },
        paymentMethod: paymentMethod,
        username: username
    }

    dispatch(setIsLoading(true))
    const getBonusByPaymentMethodResult = await Bonuses.getBonusByPaymentMethod(reqBody)
    dispatch(setIsLoading(false))

    if (getBonusByPaymentMethodResult.status === 200) {
        dispatch(setProgressIndex(1))
        dispatch(setBonusForSinglePaymentMethod(getBonusByPaymentMethodResult.data))
    } else {
        dispatch(setDepositErrorText(getBonusByPaymentMethodResult?.data?.message))
        dispatch(setDepositErrorPopup(true));
    }
}

export const movenpayPaparaDeposit = (reqBody) => async (dispatch) => {

    dispatch(setIsLoading(true))
    let MovenpayPaparaDepositResult = await MovenpayPapara.deposit(reqBody)
    dispatch(setIsLoading(false))

    if (MovenpayPaparaDepositResult.status === 200) {
        dispatch(setProgressIndex(2))
        dispatch(setRedirectBtnUrlForPayment(MovenpayPaparaDepositResult?.data.urlToRedirect))
    } else {
        dispatch(setDepositErrorText(MovenpayPaparaDepositResult?.data?.message))
        dispatch(setDepositErrorPopup(true));
    }
}

export const turboYatirimDeposit = (reqBody) => async (dispatch) => {

    dispatch(setIsLoading(true))
    let turboYatirimDepositResult = await TurboYatirim.deposit(reqBody)
    dispatch(setIsLoading(false))

    if (turboYatirimDepositResult.status === 200) {
        dispatch(setProgressIndex(2))
        dispatch(setRedirectBtnUrlForPayment(turboYatirimDepositResult?.data.url))
    } else {
        dispatch(setDepositErrorText(turboYatirimDepositResult?.data?.message))
        dispatch(setDepositErrorPopup(true));
    }
}

export const sapphirePapara = (reqBody) => async (dispatch) => {
    dispatch(setIsLoading(true))
    let sapphirePaparaResult = await SapphirePapara.deposit(reqBody)
    dispatch(setIsLoading(false))

    if (sapphirePaparaResult.status === 200) {
        dispatch(setProgressIndex(2))
        dispatch(setRedirectBtnUrlForPayment(sapphirePaparaResult?.data.payment_url))
    } else {
        dispatch(setDepositErrorText(sapphirePaparaResult?.data?.message))
        dispatch(setDepositErrorPopup(true));
    }
}

export const guvenilirQr = (reqBody) => async (dispatch) => {
    dispatch(setIsLoading(true))
    let guvenilirQrResult = await GuvenilirQR.deposit(reqBody)
    dispatch(setIsLoading(false))

    if (guvenilirQrResult.status === 200) {
        dispatch(setProgressIndex(2))
        dispatch(setRedirectBtnUrlForPayment(guvenilirQrResult?.data.url))
    } else {
        dispatch(setDepositErrorText(guvenilirQrResult?.data?.message))
        dispatch(setDepositErrorPopup(true));
    }
}

export const turboHavale = (reqBody) => async (dispatch) => {
    dispatch(setIsLoading(true))
    let turboHavaleResult = await TurboHavale.deposit(reqBody)
    dispatch(setIsLoading(false))

    if (turboHavaleResult.status === 200) {
        dispatch(setProgressIndex(2))
        dispatch(setRedirectBtnUrlForPayment(turboHavaleResult?.data.url))
    } else {
        dispatch(setDepositErrorText(turboHavaleResult?.data?.message))
        dispatch(setDepositErrorPopup(true));
    }
}

export const odendibilPep = (reqBody) => async (dispatch) => {
    dispatch(setIsLoading(true))
    let odendibilPepResult = await OdendibilPep.deposit(reqBody)
    dispatch(setIsLoading(false))

    if (odendibilPepResult.status === 200) {
        dispatch(setProgressIndex(2))
        dispatch(setRedirectBtnUrlForPayment(odendibilPepResult?.data.url))
    } else {
        dispatch(setDepositErrorText(odendibilPepResult?.data?.message))
        dispatch(setDepositErrorPopup(true));
    }
}

export const odendibilQR = (reqBody) => async (dispatch) => {
    dispatch(setIsLoading(true))
    let odendibilQRResult = await OdendibilQr.deposit(reqBody)
    dispatch(setIsLoading(false))

    if (odendibilQRResult.status === 200) {
        dispatch(setProgressIndex(2))
        dispatch(setRedirectBtnUrlForPayment(odendibilQRResult?.data.url))
    } else {
        dispatch(setDepositErrorText(odendibilQRResult?.data?.message))
        dispatch(setDepositErrorPopup(true));
    }
}

export const movenpayCreditCard = (reqBody) => async (dispatch) => {
    dispatch(setIsLoading(true))
    let movenpayCreditCardResult = await MovenPayCreditCard.deposit(reqBody)
    dispatch(setIsLoading(false))

    if (movenpayCreditCardResult.status === 200) {
        dispatch(setProgressIndex(2))
        dispatch(setRedirectBtnUrlForPayment(movenpayCreditCardResult?.data.urlToRedirect))
    } else {
        dispatch(setDepositErrorText(movenpayCreditCardResult?.data?.message))
        dispatch(setDepositErrorPopup(true));
    }
}

export const promotionCodeActivate = (bonusCode) => async (dispatch) => {
    dispatch(setIsLoading(true))
    let promotionCodeActivateResult = await PromotionCode.deposit(bonusCode)
    dispatch(setIsLoading(false))

    if (promotionCodeActivateResult.status === 200) {
        dispatch(setProgressIndex(2))
        dispatch(setPromotionCodeStatus("1"))
    } else if (promotionCodeActivateResult.response.status === 400) {
        dispatch(setProgressIndex(2))
        dispatch(setPromotionCodeStatus("2"))
    } else {
        dispatch(setDepositErrorText(promotionCodeActivateResult?.data?.message))
        dispatch(setDepositErrorPopup(true));
    }
}

export const vevoPay = (reqBody) => async (dispatch) => {
    dispatch(setIsLoading(true))
    let VevoPayResult = await Vevopay.deposit(reqBody)
    dispatch(setIsLoading(false))

    if (VevoPayResult.status === 201) {
        dispatch(setProgressIndex(2))
        dispatch(setRedirectBtnUrlForPayment(VevoPayResult?.data.url))
    } else {
        dispatch(setDepositErrorText(VevoPayResult?.data?.message))
        dispatch(setDepositErrorPopup(true));
    }
}

export const fxCuzdan = (reqBody) => async (dispatch) => {
    dispatch(setIsLoading(true))
    let fxCuzdanResult = await FxCuzdan.deposit(reqBody)
    dispatch(setIsLoading(false))

    if (fxCuzdanResult.status === 200) {
        dispatch(setProgressIndex(2))
        dispatch(setRedirectBtnUrlForPayment(fxCuzdanResult?.data.payment_url))
    } else {
        dispatch(setDepositErrorText(fxCuzdanResult?.data?.message))
        dispatch(setDepositErrorPopup(true));
    }
}

export const bankTransfer = (reqBody) => async (dispatch) => {
    dispatch(setIsLoading(true))
    let bankTransferResult = await BankTransfer.deposit(reqBody)
    dispatch(setIsLoading(false))

    if (bankTransferResult.status === 200) {
        dispatch(setDepositModal(false));
        dispatch(setGenericPopupInfo({
            title: "Başarılı",
            text: bankTransferResult?.data?.message
        }))
    } else {
        dispatch(setDepositErrorText(bankTransferResult?.data?.message))
        dispatch(setDepositErrorPopup(true));
    }
}

export const bankTransferCepBank = (reqBody) => async (dispatch) => {
    dispatch(setIsLoading(true))
    let bankTransferCepBankResult = await BankTransferCepbank.deposit(reqBody)
    dispatch(setIsLoading(false))

    if (bankTransferCepBankResult.status === 200) {
        dispatch(setDepositModal(false));
        dispatch(setGenericPopupInfo({
            title: "Başarılı",
            text: bankTransferCepBankResult?.data?.message
        }))
    } else {
        dispatch(setDepositErrorText(bankTransferCepBankResult?.response?.data?.message))
        dispatch(setDepositErrorPopup(true));
    }
}

export const fixturka = (reqBody) => async (dispatch) => {
    dispatch(setIsLoading(true))
    let fixturkaResult = await Fixturka.deposit(reqBody)
    dispatch(setIsLoading(false))

    if (fixturkaResult.status === 200) {
        dispatch(setProgressIndex(2))
        dispatch(setRedirectBtnUrlForPayment(fixturkaResult?.data.url))
    } else {
        dispatch(setDepositErrorText(fixturkaResult?.response?.data?.message))
        dispatch(setDepositErrorPopup(true));
    }
}

export const etoroCryptoPayment = (reqBody) => async (dispatch) => {
    dispatch(setIsLoading(true))
    let etoroCryptoPaymentResult = await EtoroCryptoPayment.deposit(reqBody)
    dispatch(setIsLoading(false))

    if (etoroCryptoPaymentResult.status === 200) {
        dispatch(setProgressIndex(2))
        dispatch(setRedirectBtnUrlForPayment(etoroCryptoPaymentResult?.data.redirect_url))
    } else {
        dispatch(setDepositErrorText(etoroCryptoPaymentResult?.response?.data?.message))
        dispatch(setDepositErrorPopup(true));
    }
}

export const cryptoPayment = (reqBody) => async (dispatch) => {
    dispatch(setIsLoading(true))
    let cryptoPaymentResult = await CryptoPayment.deposit(reqBody)
    dispatch(setIsLoading(false))

    if (cryptoPaymentResult.status === 200) {
        dispatch(setProgressIndex(2))
        dispatch(setRedirectBtnUrlForPayment(cryptoPaymentResult?.data.hosted_page_url))
    } else {
        dispatch(setDepositErrorText(cryptoPaymentResult?.response?.data?.message))
        dispatch(setDepositErrorPopup(true));
    }
}

export const hizliPapara = (reqBody) => async (dispatch) => {
    dispatch(setIsLoading(true))
    let hizliPaparaResult = await HizliPapara.deposit(reqBody)
    dispatch(setIsLoading(false))

    if (hizliPaparaResult.status === 200) {
        dispatch(setProgressIndex(2))
        dispatch(setRedirectBtnUrlForPayment(hizliPaparaResult?.data.urlToRedirect))
    } else {
        dispatch(setDepositErrorText(hizliPaparaResult?.response?.data?.message))
        dispatch(setDepositErrorPopup(true));
    }
}

export const thorPay = (reqBody) => async (dispatch) => {
    dispatch(setIsLoading(true))
    let hizliPaparaResult = await ThorPay.deposit(reqBody)
    dispatch(setIsLoading(false))

    if (hizliPaparaResult.status === 200) {
        dispatch(setProgressIndex(2))
        dispatch(setRedirectBtnUrlForPayment(hizliPaparaResult?.data.urlToRedirect))
    } else {
        dispatch(setDepositErrorText(hizliPaparaResult?.response?.data?.message))
        dispatch(setDepositErrorPopup(true));
    }
}