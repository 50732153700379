import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import WalletDeposit from "./WalletDeposit";
import WalletWithdraw from "./WalletWithdraw";
import './style.scss'

const Wallet = () => {

  const location = useLocation();
  const navigate = useNavigate()
  const [isDeposit, setIsDeposit] = useState('0')

  useEffect(() => {
    if (location.pathname === "/uye-paneli/wallet/deposit") {
      setIsDeposit('1')
    } else if (location.pathname === "/uye-paneli/wallet/withdraw") {
      setIsDeposit('2')
    } else if (location.pathname === "/uye-paneli") {
      setIsDeposit('0')
    }
  }, [location.pathname])

  return (
    <div>



      {
        isDeposit === "1" ? <WalletDeposit /> : isDeposit === "2" ? <WalletWithdraw /> :
          <div className="payment-btn-wrapper">
            <div className="payment-btn-wrapper__btn primary-payment-bg" onClick={() => navigate('/uye-paneli/wallet/deposit')}>Para Yatir</div>
            <div className="payment-btn-wrapper__btn secondary-payment-bg" onClick={() => navigate('/uye-paneli/wallet/withdraw')}>Para Cek</div>
          </div>
      }

    </div>
  )
};
export default Wallet;
