import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {
    getBonuses,
    getMethodsApp
} from '../../../services/payment';
import {
    resetValues,
    selectBonuses,
    selectIsCryptoPay,
    selectIsLoading,
    selectPaymentMethods,
    selectWithdrawErrorPopup,
    selectWithdrawErrorText,
    selectWithdrawModal,
    setBankList,
    setIsBankTransfer,
    setIsBankTransferCepBank, setPaymentType,
    setProgressIndex,
    setSelectedPaymentMethod,
    setWithdrawErrorPopup,
    setWithdrawModal
} from '../../../redux/slices/paymentSliceWithdraw';
import {selectLoggedInUser} from '../../../redux/slices/authSlice';
import Popup from '../../commons/ModalET';
import CustomButton from '../../commons/Button';
import LoadingIndicator from '../../commons/LoadingIndicator';
import {
    selectIsPageLoading
} from '../../../redux/slices/paymentSlice';
import WithdrawBox from '../../commons/WithdrawBox/WithdrawBox';
import WithdrawModal from '../WithdrawModal/WithdrawModal';
import {
    getBankListForFxCuzdan,
    getOdendibilWithdrawType
} from "../../../services/paymentWithdraw";

const WalletWithdraw = () => {

    const dispatch = useDispatch();

    const [cryptoPaymentType, setCryptoPaymentType] = useState("")
    const [selectedMethod, setSelectedMethod] = useState()
    const withdrawErrorPopupRef = useRef(null);
    const withdrawErrorText = useSelector(selectWithdrawErrorText)
    const withdrawErrorPopup = useSelector(selectWithdrawErrorPopup)
    const withdrawModalStatus = useSelector(selectWithdrawModal);
    const loggedInUser = useSelector(selectLoggedInUser);
    const isLoading = useSelector(selectIsLoading)
    const isPageLoading = useSelector(selectIsPageLoading);
    // const withdrawPaymentMethods = useSelector(selectPaymentMethods);
    const paymentMethods = useSelector(selectPaymentMethods)
    const bonuses = useSelector(selectBonuses)
    let sessionId = JSON.parse(window.localStorage.getItem("user_data"))?.sessionId


    const etoroCryptoPaymentList = [
        {method: "EtoroCryptopayment", cryptoCurrencyType: "ETH", imgSrc: '/assets/etoroETH.svg'},
        {method: "EtoroCryptopayment", cryptoCurrencyType: "BTC", imgSrc: '/assets/etoroBTC.svg'},
        {method: "EtoroCryptopayment", cryptoCurrencyType: "TRX", imgSrc: '/assets/etoroTRX.svg'},
        {method: "EtoroCryptopayment", cryptoCurrencyType: "USDT.TRC20", imgSrc: '/assets/etoroUSDTTRC20.svg'},
    ]

    const paymentMethodIconFinder = (method) => {
        switch (method) {
            case "CryptoPayment":
                return "/assets/USDT.svg"
            case "ETH":
                return "/assets/etoroETH.svg"
            case "BTC":
                return "/assets/etoroBTC.svg"
            case "TRX":
                return "/assets/etoroTRX.svg"
            case "USDT.TRC20":
                return "/assets/etoroUSDTTRC20.svg"
            case "MovenPay-Papara":
                return "/assets/movenpayPapara.svg"
            case "HizliPapara":
                return "/assets/paparaFastTR.svg"
            case "CryptoPay":
                return "/assets/cryptopay.svg"
            case "Vevopay":
                return "/assets/vevopay.svg"
            case "BackofficeDeposit":
                return "/assets/123.svg"
            case "FxCuzdan":
                return "/assets/fxcuzdan.png"
            case "MovenPay-CreditCard":
                return "/assets/kredikarti-hizli.svg"
            case "PromotionalCode":
                return "/assets/pcode-tr.svg		"
            case "Thorpay":
                return "/assets/kredikartiTr.svg"
            case "Fixturka":
                return "/assets/payfix.png"
            case "Odebitir":
                return "/assets/odebitir.png"
            case "Odendibil-qr":
                return "/assets/QR.svg"
            case "MFT":
                return "/assets/mft.png"
            case "Odendibil-pep":
                return "/assets/PEP.svg"
            case "TurboHavale":
                return "/assets/turbohavale.png"
            case "GuvenilirQR":
                return "/assets/guvenilirqr.svg"
            case "SapphirePapara":
                return "/assets/sapphirepapara.png"
            case "TurboYatirim":
                return "/assets/paybol.svg"
            case "SendToFriend":
                return "/assets/sendToFriend.svg"
            case "BankTransfer":
                return "/assets/atm.svg"
            default:
                break;
        }
    }

    useEffect(() => {
        dispatch(getMethodsApp("Withdraw"));
        dispatch(getBonuses("Withdraw"))
    }, [])

    const promotionCheck = (method) => {
        let promotionText = ""

        bonuses?.forEach(element => {
            if (element?.paymentMethods?.filter(mthd => mthd === method)[0]) {
                promotionText += `?%${element?.instruction?.percentage} Bonus`
            }
        });

        return promotionText.trimStart()
    }

    const isCryptoPay = useSelector(selectIsCryptoPay)

    const handleShowDepositModal = (paymentMethod, cryptoPaymentType = null) => {
        dispatch(setIsBankTransfer(false))
        dispatch(setIsBankTransferCepBank(false))
        dispatch(setBankList([]))
        dispatch(setProgressIndex(0))
        dispatch(setSelectedPaymentMethod(paymentMethod))
        setSelectedMethod(paymentMethod)
        if (cryptoPaymentType) {
            setCryptoPaymentType(cryptoPaymentType)
        } else {
            setCryptoPaymentType("")
        }

        dispatch(resetValues())


        switch (paymentMethod?.method) {
            case "CryptoPay":
                dispatch(setPaymentType('1'))
                dispatch(setWithdrawModal(true))
                return
            case "Vevopay":
                dispatch(setPaymentType('2'))
                dispatch(setWithdrawModal(true))
                return
            case "FxCuzdan":
                dispatch(getBankListForFxCuzdan())
                return
            case "Fixturka":
                dispatch(setPaymentType('4'))
                dispatch(setWithdrawModal(true))
                return
            case "Odendibil-qr":
                dispatch(getOdendibilWithdrawType({sessionId}))
                dispatch(setPaymentType('5'))
                dispatch(setWithdrawModal(true))
                return
            case "SendToFriend":
                dispatch(setPaymentType('6'))
                dispatch(setWithdrawModal(true))
                return
            case "MFT":
                dispatch(setPaymentType('7'))
                dispatch(setWithdrawModal(true))
                return
            case "TurboHavale":
                dispatch(setPaymentType('8'))
                dispatch(setWithdrawModal(true))
                return
            case "SapphirePapara":
                dispatch(setPaymentType('9'))
                dispatch(setWithdrawModal(true))
                return
            case "MovenPay-Papara":
                dispatch(setPaymentType('10'))
                dispatch(setWithdrawModal(true))
                return
            case "BankTransfer":
                dispatch(setPaymentType('11'))
                dispatch(setWithdrawModal(true))
                return
            default:
                // dispatch(setDepositModal(!depositModalStatus));
                // window.scroll({ top: 0, left: 0, behavior: "smooth" })
                break;
        }

    };

    return (
        <>
            <WithdrawModal modalRef={withdrawErrorPopupRef} isOpen={withdrawModalStatus}
                           onClose={() => dispatch(setWithdrawModal(false))}
                           logo={paymentMethodIconFinder(cryptoPaymentType ? cryptoPaymentType : selectedMethod?.method)}/>

            <div className="depositOptionsWrapper">
                <div className="depositOptionsWrapper--header">
                    <div className="depositOptionsWrapper--header--titles">
                        <span>Para Çek</span>
                        <span>{`Hoşgeldin, ${loggedInUser?.Username}!`}</span>
                    </div>
                </div>
                {
                    isPageLoading ? <LoadingIndicator/> :
                        paymentMethods?.map(paymentMethod => {
                            if (paymentMethod?.method === "EtoroCryptopayment") {
                                return etoroCryptoPaymentList?.map(etoroPayment => {
                                    return <WithdrawBox
                                        isLoading={isLoading}
                                        key={etoroPayment.cryptoCurrencyType}
                                        method={paymentMethod?.method}
                                        logoSrc={etoroPayment?.imgSrc}
                                        min={paymentMethod?.currencies[0]?.withdraw?.min}
                                        max={paymentMethod?.currencies[0]?.withdraw?.max}
                                        promotionText={promotionCheck(paymentMethod?.method)}
                                        handleShowModal={() => handleShowDepositModal(paymentMethod, etoroPayment.cryptoCurrencyType)}
                                    />
                                })
                            } else {
                                if (paymentMethod?.isActive) {
                                    return <WithdrawBox
                                        isLoading={isLoading}
                                        key={paymentMethod?.method}
                                        method={paymentMethod?.method}
                                        logoSrc={paymentMethodIconFinder(paymentMethod?.method)}
                                        min={paymentMethod?.currencies[0]?.withdraw?.min}
                                        max={paymentMethod?.currencies[0]?.withdraw?.max}
                                        promotionText={promotionCheck(paymentMethod?.method)}
                                        handleShowModal={() => handleShowDepositModal(paymentMethod)}
                                    />
                                }
                            }
                        })
                }
            </div>

            {/* DEPOSIT ERROR POPUP */}
            <Popup isOpen={withdrawErrorPopup} onClose={setWithdrawErrorPopup} modalRef={withdrawErrorPopupRef}>
                <div className="deposit-error-popup">
                    <div className="deposit-error-popup__title">HATA</div>
                    <div className="deposit-error-popup__description">{withdrawErrorText}</div>
                    <div className="deposit-error-popup__btn">
                        <CustomButton
                            title="Tamam"
                            backgroundColor={null}
                            textColor={"#fff"}
                            backgroundImage={
                                "linear-gradient(84deg, #863cc4 0%, #1631ba 100%)"
                            }
                            fontWeight={"normal"}
                            fontSize={"14px"}
                            width={"100%"}
                            isLoadingIndicator={isLoading}
                            onClick={() => dispatch(setWithdrawErrorPopup(false))}
                        />
                    </div>
                </div>
            </Popup>

        </>
    );
}

export default WalletWithdraw