import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectBankTransferValues, selectIsLoading, selectPaymentStateErrors, setAmount, setDepositModal, setPaymentMethodsCurrency, setPaymentStateErrors, setPaymentValues, setSelectedBankCode } from '../../../redux/slices/paymentSlice'
import { thorPay } from '../../../services/payment'
import ButtonET from '../../commons/CustomButton'
import Input from "../../commons/CustomInput/Input";
import { onlyLetterFilter, onlyNumberFilter } from '../../../utils/regex';
import { creditCardInputMask, dateInputMaskCreditCard } from '../../../utils/phoneInputMask'
import { selectProfile } from '../../../redux/slices/profileSlice'

const DepositModalThorPay = () => {

    const dispatch = useDispatch()
    const values = useSelector(selectBankTransferValues)

    const isLoading = useSelector(selectIsLoading)
    const errors = useSelector(selectPaymentStateErrors)

    const locale = useSelector(selectProfile)?.data?.UserAttributes?.filter(att => att?.Name === "locale")[0]?.Value;

    useEffect(() => {
        dispatch(setPaymentMethodsCurrency("Seçiniz"))
        dispatch(setSelectedBankCode("Seçiniz"))
        dispatch(setAmount(0))
    }, [])

    const getInputsError = (type) => {
        return errors.filter(err => err.inputType === type)[0]
    }

    const handleOnChange = (e, type) => {
        dispatch(setPaymentStateErrors({ inputType: type, errorText: "" }))
        if (type === "cartNo") {
            dispatch(setPaymentValues({ inputType: type, state: creditCardInputMask(e.target.value.replace(onlyNumberFilter, '')) }))
        } else if (type === "creditCardDate") {
            dispatch(setPaymentValues({ inputType: type, state: dateInputMaskCreditCard(e.target.value.replace(onlyNumberFilter, '')) }))
        } else if (type === "fullName") {
            dispatch(setPaymentValues({ inputType: type, state: e.target.value.replace(onlyLetterFilter, '') }))
        } else if (type === 'cvv') {
            dispatch(setPaymentValues({ inputType: type, state: e.target.value.replace(onlyNumberFilter, '') }))
        }
    }

    const handleOnBlur = (e, type) => {

        if (type === "cartNo") {
            if (e.target.value === "" || e.target.value.length !== 19) {
                dispatch(setPaymentStateErrors({ inputType: type, errorText: "Lütfen alanı doldurunuz" }))
            }
        } else {
            if (e.target.value === "") {
                dispatch(setPaymentStateErrors({ inputType: type, errorText: "Lütfen alanı doldurunuz" }))
            }
        }
    }

    const checkIfAllInputValid = () => {
        if (
            (!getInputsError("cartNo").error && values?.cartNo !== "" && values?.cartNo?.length === 19) &&
            (!getInputsError("creditCardDate").error && values?.creditCardDate !== "") &&
            (!getInputsError("fullName").error && values?.fullName !== "") &&
            (!getInputsError("cvv").error && values?.cvv !== "" && values?.cvv?.length === 3)
        ) {
            return false
        } else {
            return true
        }
    }

    return (
        <>
            <div className="depositModal--mid">
                <Input
                    type={"fullName"}
                    labelText={`Kart Sahibinin Adı Soyadı *`}
                    placeholder={"Ad Soyad"}
                    value={values?.fullName}
                    onChange={(e) => handleOnChange(e, "fullName")}
                    onBlur={(e) => handleOnBlur(e, "fullName")}
                    error={getInputsError("fullName").error}
                    errorText={getInputsError("fullName").errorText}
                    maxLength={80}
                />

                <Input
                    type={"cartNo"}
                    labelText={`Kart Numarası *`}
                    placeholder={"Kart Numarası"}
                    value={values?.cartNo}
                    onChange={(e) => handleOnChange(e, "cartNo")}
                    onBlur={(e) => handleOnBlur(e, "cartNo")}
                    error={getInputsError("cartNo").error}
                    errorText={getInputsError("cartNo").errorText}
                    maxLength={80}
                />

                <Input
                    type={"creditCardDate"}
                    labelText={`Son Kullanma Tarihi *`}
                    placeholder={"Son Kullanma Tarihi"}
                    value={values?.creditCardDate}
                    onChange={(e) => handleOnChange(e, "creditCardDate")}
                    onBlur={(e) => handleOnBlur(e, "creditCardDate")}
                    error={getInputsError("creditCardDate").error}
                    errorText={getInputsError("creditCardDate").errorText}
                    maxLength={80}
                />

                <Input
                    type={"cvv"}
                    labelText={`CVV *`}
                    placeholder={"CVV"}
                    value={values?.cvv}
                    onChange={(e) => handleOnChange(e, "cvv")}
                    onBlur={(e) => handleOnBlur(e, "cvv")}
                    error={getInputsError("cvv").error}
                    errorText={getInputsError("cvv").errorText}
                    maxLength={3}
                />

            </div>

            <div className="depositModal--btns">
                <ButtonET
                    title="İptal Et ve Geri Dön" disabled={isLoading}
                    onClick={() => dispatch(setDepositModal(false))}
                />
                <ButtonET
                    btnType='secondaryBtn'
                    title="İlerle ve Ödeme Yöntemine Git"
                    isLoadingIndicator={isLoading}
                    disabled={checkIfAllInputValid()}
                    onClick={() => {
                        dispatch(thorPay({
                            amount: Number(values?.amount),
                            cardMonth: values?.creditCardDate?.split('/')[0] ?? "",
                            cardNumber1: values?.cartNo?.split('/')[0] ?? "",
                            cardNumber2: values?.cartNo?.split('/')[1] ?? "",
                            cardNumber3: values?.cartNo?.split('/')[2] ?? "",
                            cardNumber4: values?.cartNo?.split('/')[3] ?? "",
                            cardOwnerName: values?.fullName,
                            cardSecurityNum: values?.cvv,
                            cardType: "10",
                            cardYear: values?.creditCardDate?.split('/')[1] ?? "",
                            currency: values?.currency,
                            language: locale
                        }))
                    }}
                />
            </div>
        </>
    )
}

export default DepositModalThorPay;