import React from 'react';

import "./style.scss";
import redCard from "../../../assets/sportsbook/redCard.svg"
import yellowCard from "../../../assets/sportsbook/yellowCard.svg"
import goalIcon from "../../../assets/sportsbook/goal.svg"
import footballIcon from "../../../assets/sportsbook/football.svg"

const MatchInfoAccordionBody = () => {
    return (
        <div className="matchinfoAccordionBody">
            <div className="matchinfoAccordionBody--result">
                <div className="matchinfoAccordionBody--result--left">
                    <span>Ev Sahibi</span>
                    <span>Aston Villa</span>
                </div>
                <div className="matchinfoAccordionBody--result--mid">
                    <span>3</span>
                    <span>:</span>
                    <span>0</span>
                </div>
                <div className="matchinfoAccordionBody--result--right">
                    <span>Deplasman</span>
                    <span>Leeds United</span>
                </div>
            </div>
            <div className="matchinfoAccordionBody--info">
                <div className="matchinfoAccordionBody--info--line">
                    <span>83' 2. Yarı</span>
                    <div>
                        <span><img src={footballIcon}/></span>
                        <span><img src={goalIcon}/></span>
                        <span><img src={redCard}/></span>
                        <span><img src={yellowCard}/></span>
                    </div>
                </div>
                <div className="matchinfoAccordionBody--info--line">
                    <span>Aston Villa</span>
                    <div>
                        <span>3</span>
                        <span>9</span>
                        <span>0</span>
                        <span>1</span>
                    </div>
                </div>
                <div className="matchinfoAccordionBody--info--line">
                    <span>Leeds United</span>
                    <div>
                        <span>3</span>
                        <span>9</span>
                        <span>0</span>
                        <span>1</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MatchInfoAccordionBody