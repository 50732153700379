import React from "react";
import { Accordion } from "react-bootstrap";

import "../style.scss";
import clockIcon from "../../../assets/sportsbook/clock.svg";
import favoritedIcon from "../../../assets/sportsbook/favorited.svg";
import footballIcon from "../../../assets/sportsbook/football.svg";
import ukFlag from "../../../assets/sportsbook/ukFlag.svg";
import LiveBetsBoxAccordionItem from "./LiveBetsBoxAccordionItem";

const LiveBetsBox = () => {
  const count = [1, 2, 3, 4, 5];

  return (
    <div className="sportsbook-live-liveBetsBox">
      <div className="sportsbook-live-liveBetsBox--title">
        <img src={clockIcon} />
        <span>Canlı Bahisler</span>
      </div>

      <div className="tab-title-underLine"></div>

      <div className="sportsbook-live-liveBetsBox--content">
        <div className="sportsbook-live-liveBetsBox--content--tabs">
          <div className="sportsbook-live-liveBetsBox--content--tabs--tabItem activeLiveBetsTab">
            <img src={footballIcon} />
            <span>Futbol</span>
          </div>
          <div className="sportsbook-live-liveBetsBox--content--tabs--tabItem">
            <img src={footballIcon} />
            <span>Futbol</span>
          </div>
          <div className="sportsbook-live-liveBetsBox--content--tabs--tabItem">
            <img src={footballIcon} />
            <span>Futbol</span>
          </div>
          <div className="sportsbook-live-liveBetsBox--content--tabs--tabItem">
            <img src={footballIcon} />
            <span>Futbol</span>
          </div>
          <div className="sportsbook-live-liveBetsBox--content--tabs--tabItem">
            <img src={footballIcon} />
            <span>Futbol</span>
          </div>
        </div>
        <div className="sportsbook-live-liveBetsBox--content--countries">
          {count.map((c, index) => (
            <LiveBetsBoxAccordionItem isActive={index == 0 && true} key={index + 5}/>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LiveBetsBox;
