import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectAmount, selectBankList, selectBankTransferValues, selectIsLoading, selectPaymentMethodCurrency, selectPaymentStateErrors, selectSelectedBankName, selectSelectedPaymentMethod, setAmount, setDepositModal, setPaymentMethodsCurrency, setPaymentStateErrors, setPaymentValues, setSelectedBankCode, setSelectedBankName } from '../../../redux/slices/paymentSlice'
import { getBonusByPaymentMethod } from '../../../services/payment'
import { selectLoggedInUser } from '../../../redux/slices/authSlice'
import ButtonET from '../../commons/CustomButton'
import Input from "../../commons/CustomInput/Input";
import SelectComponent from '../../commons/CustomSelect';
import { onlyLetterFilter, onlyNumberFilter } from '../../../utils/regex';
import { dateInputMask, phoneInputMaskWithCode } from '../../../utils/phoneInputMask'
import './style.scss'

const DepositModalBankTransferCepBank = () => {

    const dispatch = useDispatch()
    const values = useSelector(selectBankTransferValues)

    const [minMax, setMinMax] = useState({})

    const bankNameList = [
        {
            id: "akbank",
            bankName: "AKBANK T.A.Ş"
        },
        {
            id: "adabank",
            bankName: "ADABANK A.Ş."
        },
        {
            id: "kuveytturk",
            bankName: "KUVEYTTÜRK"
        },
        {
            id: "sekerbank",
            bankName: "ŞEKERBANK"
        },
        {
            id: "albarakaturk",
            bankName: "ALBARAKA TÜRK KATILIM BANKASI A.Ş"
        },
        {
            id: "anadolubank",
            bankName: "ANADOLUBANK A.Ş"
        },
        {
            id: "asyakatilim",
            bankName: "ASYA KATILIM BANKASI A.Ş"
        },
        {
            id: "bankpozitif",
            bankName: "BANKPOZİTİF KREDİ VE KALKINMA BANKASI A.Ş"
        },
        {
            id: "denizbank",
            bankName: "DENİZBANK A.Ş"
        },
        {
            id: "finansbank",
            bankName: "FINANSBANK"
        },
        {
            id: "fibabanka",
            bankName: "FİBABANKA A.Ş"
        },
        {
            id: "hsbc",
            bankName: "HSBC BANK A.Ş"
        },
        {
            id: "icbc",
            bankName: "ICBC TURKEY BANK ANONİM ŞİRKETİ"
        },
        {
            id: "ing",
            bankName: "ING BANK A.Ş"
        },
        {
            id: "kuveytturk",
            bankName: "KUVEYT TÜRK KATILIM BANKASI A.Ş"
        },
        {
            id: "odeabank",
            bankName: "ODEA BANK A.Ş"
        },
        {
            id: "sekerbank",
            bankName: "ŞEKERBANK T.A.Ş"
        },
        {
            id: "teb",
            bankName: "TÜRK EKONOMİ BANKASI A.Ş"
        },
        {
            id: "turkiyefinans",
            bankName: "TÜRKİYE FİNANS KATILIM BANKASI A.Ş"
        },
        {
            id: "garanti",
            bankName: "TÜRKİYE GARANTİ BANKASI A.Ş"
        },
        {
            id: "isbank",
            bankName: "TÜRKİYE İŞ BANKASI A.Ş"
        },
        {
            id: "vakifbank",
            bankName: "TÜRKİYE VAKIFLAR BANKASI T.A.O"
        },
        {
            id: "ykb",
            bankName: "YAPI VE KREDİ BANKASI A.Ş"
        }
    ]

    const amount = useSelector(selectAmount)
    const loggedInUser = useSelector(selectLoggedInUser)
    const paymentMethod = useSelector(selectSelectedPaymentMethod)
    const selectedPaymentMethodCurrency = useSelector(selectPaymentMethodCurrency)
    const bankList = useSelector(selectBankList)
    const isLoading = useSelector(selectIsLoading)
    const errors = useSelector(selectPaymentStateErrors)
    const selectedBankName = useSelector(selectSelectedBankName)

    useEffect(() => {
        setMinMax({})
        dispatch(setPaymentMethodsCurrency("Seçiniz"))
        dispatch(setSelectedBankCode("Seçiniz"))
        dispatch(setAmount(0))
    }, [])

    const getInputsError = (type) => {
        return errors.filter(err => err.inputType === type)[0]
    }

    useEffect(() => {
        setMinMax(paymentMethod.currencies.filter(currency => currency?.currency === selectedPaymentMethodCurrency)[0]?.deposit)
    }, [selectedPaymentMethodCurrency])

    const handleGetBonusByPaymentMethod = () => {
        dispatch(getBonusByPaymentMethod(amount, selectedPaymentMethodCurrency, paymentMethod.method, loggedInUser?.Username))
    }

    const handleOnChange = (e, type) => {
        dispatch(setPaymentStateErrors({ inputType: type, errorText: "" }))
        if (type === "phoneNumber" || type === "phoneNumber1") {
            dispatch(setPaymentValues({ inputType: type, state: phoneInputMaskWithCode(e.target.value) }))
        } else if (type === "accountNo" || type === "tckn" || type === "iban" || type === "citizenNo") {
            dispatch(setPaymentValues({ inputType: type, state: e.target.value.replace(onlyNumberFilter, '') }))
        } else if (type === "fullName") {
            dispatch(setPaymentValues({ inputType: type, state: e.target.value.replace(onlyLetterFilter, '') }))
        } else if (type === "expireDate" || type === "birthdate") {
            dispatch(setPaymentValues({ inputType: type, state: dateInputMask(e.target.value.replace(onlyNumberFilter, '')) }))
        } else {
            dispatch(setPaymentValues({ inputType: type, state: e.target.value }))
        }
    }

    const handleOnBlur = (e, type) => {
        if (type === "depositAmount") {
            if (minMax?.min && minMax?.max) {
                if (amount < minMax?.min) {
                    dispatch(setPaymentStateErrors({ inputType: type, errorText: "Girdiğiniz değer minimum değerin altındadır." }))
                } else if (amount > minMax?.max) {
                    dispatch(setPaymentStateErrors({ inputType: type, errorText: "Girdiğiniz değer maksimum değerin üstündedir." }))
                } else if (bankList?.length && (amount < minMax?.min || amount > minMax?.max || amount % 10 !== 0)) {
                    dispatch(setPaymentStateErrors({ inputType: type, errorText: "Girdiğiniz değer 10 ve katları olmalıdır." }))
                }
            }
        } else {
            if (e.target.value === "") {
                dispatch(setPaymentStateErrors({ inputType: type, errorText: "Lütfen alanı doldurunuz" }))
            }
        }
    }

    const checkIfAllInputValid = () => {
        if (
            (!getInputsError("tckn").error && values?.tckn !== "") &&
            (!getInputsError("fullName").error && values?.fullName !== "") &&
            (!getInputsError("phoneNumber").error && values?.cepBankPhoneNumber !== "") &&
            (!getInputsError("phoneNumber1").error && values?.cepBankPhoneNumber2 !== "") &&
            (!getInputsError("reference").error && values?.reference !== "") &&
            (!getInputsError("expireDate").error && values?.expireDate !== "") &&
            (!getInputsError("citizenNo").error && values?.citizenNo !== "") &&
            (!getInputsError("birthdate").error && values?.birthdate !== "") &&
            (!getInputsError("depositAmount").error && amount !== "") &&
            (amount >= minMax?.min && amount <= minMax?.max)
        ) {
            return false
        } else {
            return true
        }
    }

    return (
        <>
            <div className="depositModal--mid">
                <SelectComponent labelText={"Para Birimi *"} data={paymentMethod?.currencies} currencyType={"currency"} selectedOption={selectedPaymentMethodCurrency} setSelectedOption={(e) => dispatch(setPaymentMethodsCurrency(e.target.value))} />

                {
                    <SelectComponent labelText={"Banka Adı *"} data={bankNameList} currencyType={"bankName"} selectedOption={selectedBankName} setSelectedOption={(e) => dispatch(setSelectedBankName(e.target.value))} />
                }

                <Input
                    type={"tckn"}
                    labelText={`T.C. Kimlik Numarası`}
                    placeholder={"T.C. Kimlik Numarası"}
                    value={values?.tckn}
                    onChange={(e) => handleOnChange(e, "tckn")}
                    onBlur={(e) => handleOnBlur(e, "tckn")}
                    error={getInputsError("tckn").error}
                    errorText={getInputsError("tckn").errorText}
                    maxLength={11}
                />

                <Input
                    type={"fullName"}
                    labelText={`Ad Soyad`}
                    placeholder={"Ad Soyad"}
                    value={values?.fullName}
                    onChange={(e) => handleOnChange(e, "fullName")}
                    onBlur={(e) => handleOnBlur(e, "fullName")}
                    error={getInputsError("fullName").error}
                    errorText={getInputsError("fullName").errorText}
                    maxLength={80}
                />

                <div className='prefix-input-wrapper cepbank-prefix'>
                    <span style={{ fontSize: '14px' }}>+90</span>
                    <Input
                        type={"phoneNumber"}
                        labelText={`Gönderici Telefon`}
                        placeholder={"Gönderici Telefon"}
                        value={values?.cepBankPhoneNumber}
                        onChange={(e) => handleOnChange(e, "phoneNumber")}
                        onBlur={(e) => handleOnBlur(e, "phoneNumber")}
                        error={getInputsError("phoneNumber").error}
                        errorText={getInputsError("phoneNumber").errorText}
                        maxLength={15}
                    />
                </div>

                <div className='prefix-input-wrapper cepbank-prefix'>
                    <span style={{ fontSize: '14px' }}>+90</span>
                    <Input
                        type={"phoneNumber1"}
                        labelText={`Alıcı Telefon`}
                        placeholder={"Alıcı Telefon"}
                        value={values?.cepBankPhoneNumber2}
                        onChange={(e) => handleOnChange(e, "phoneNumber1")}
                        onBlur={(e) => handleOnBlur(e, "phoneNumber1")}
                        error={getInputsError("phoneNumber1").error}
                        errorText={getInputsError("phoneNumber1").errorText}
                        maxLength={15}
                    />
                </div>

                <Input
                    type={"reference"}
                    labelText={`Referans`}
                    placeholder={"Referans"}
                    value={values?.reference}
                    onChange={(e) => handleOnChange(e, "reference")}
                    onBlur={(e) => handleOnBlur(e, "reference")}
                    error={getInputsError("reference").error}
                    errorText={getInputsError("reference").errorText}
                />

                <Input
                    type={"expireDate"}
                    labelText={`Son Kullanma Tarihi`}
                    placeholder={"Son Kullanma Tarihi"}
                    value={values?.expireDate}
                    onChange={(e) => handleOnChange(e, "expireDate")}
                    onBlur={(e) => handleOnBlur(e, "expireDate")}
                    error={getInputsError("expireDate").error}
                    errorText={getInputsError("expireDate").errorText}
                />

                <Input
                    type={"citizenNo"}
                    labelText={`Alici Ulusal Kimlik Numarasi`}
                    placeholder={"Alici Ulusal Kimlik Numarasi"}
                    value={values?.citizenNo}
                    onChange={(e) => handleOnChange(e, "citizenNo")}
                    onBlur={(e) => handleOnBlur(e, "citizenNo")}
                    error={getInputsError("citizenNo").error}
                    errorText={getInputsError("citizenNo").errorText}
                    maxLength={11}
                />

                <Input
                    type={"birthdate"}
                    labelText={`Alici Dogum Tarihi`}
                    placeholder={"Alici Dogum Tarihi"}
                    value={values?.birthdate}
                    onChange={(e) => handleOnChange(e, "birthdate")}
                    onBlur={(e) => handleOnBlur(e, "birthdate")}
                    error={getInputsError("birthdate").error}
                    errorText={getInputsError("birthdate").errorText}
                />

                <Input
                    type={"depositAmount"}
                    labelText={`Miktar * ${minMax?.min && minMax?.max ? `( Min: ${minMax?.min} - Max: ${minMax?.max} )` : ""}`}
                    placeholder={
                        minMax?.min && minMax?.max ?
                            `${minMax?.min ? minMax?.min : 0} - ${minMax?.max ? minMax?.max : 0}` :
                            `Tutarı Giriniz`
                    }
                    disabled={selectedPaymentMethodCurrency === "Seçiniz"}
                    value={amount}
                    onChange={(e) => handleOnChange(e, "depositAmount")}
                    onBlur={(e) => handleOnBlur(e, "depositAmount")}
                    error={getInputsError("depositAmount").error}
                    errorText={getInputsError("depositAmount").errorText}
                    maxLength={80}
                />

            </div>

            <div className="depositModal--btns">
                <ButtonET
                    title="İptal Et ve Geri Dön" disabled={isLoading}
                    onClick={() => dispatch(setDepositModal(false))}
                />
                <ButtonET
                    btnType='secondaryBtn'
                    title="İlerle ve Ödeme Yöntemine Git"
                    isLoadingIndicator={isLoading}
                    disabled={checkIfAllInputValid()}
                    onClick={() => handleGetBonusByPaymentMethod()}
                />
            </div>
        </>
    )
}

export default DepositModalBankTransferCepBank;