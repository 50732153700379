
import axios from "axios";

const sleep = (delay) => {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
};


axios.defaults.withCredentials = true;
axios.defaults.headers = {
    "Content-Type":
        "application/json; charset=utf-8; application/x-www-form-urlencoded;",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept, ",
};


axios.interceptors.request.use(
    async (config) => {
        if (config.headers === undefined) {
            config.headers = {};
        }
        const token = JSON.parse(localStorage.getItem("user_data"));
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

axios.interceptors.response.use(
    async (response) => {
        try {
            await sleep(250);
            return response;
        } catch (error) {
            console.log(error);

            const promise = await Promise.reject(error);
            return promise;
        }
    },
    (error) => {
        const { data, status, config } = error.response;

        // switch (status) {
        //     case 401:
        //         localStorage.removeItem("user_data");
        //         break;
        //     case 404:
        //         console.log("İşlem bulunamadı");
        //         break;
        //     default:
        //         break;
        // }
        if (status === 401) {
            localStorage.removeItem("user_data");
            window.location.replace('/')
        }

        return error;
    }
);

//API
const apiUrl = process.env.REACT_APP_BASE_API_URL;
const sportsBookApiUrl = process.env.REACT_APP_SPORTSBOOK_API_URL
const etApiUrl = process.env.REACT_APP_API_URL

const url = (endpoint, apiUrlStr) => {
    if (apiUrlStr == "sportsBook") {
        return `${sportsBookApiUrl}${endpoint}`;
    } else if (apiUrlStr === "etEndP") {
        return `${etApiUrl}${endpoint}`
    } else {
        return `${apiUrl}${endpoint}`;
    }
};

const responseBody = (response) => {
    // return response.response.data;
    return response
};

const requests = {
    get: (url, params) => axios.get(url, { params }).then(responseBody),
    post: (url, body) => axios.post(url, body).then(responseBody),
    postNoResBody: (url, body) => axios.post(url, body),
    patch: (url, body) => axios.patch(url, body).then(responseBody),
    put: (url, body) => axios.put(url, body).then(responseBody),
    del: (url, body) => axios.delete(url, { data: body }).then(responseBody),
};

export const Auth = {
    login: (login) => requests.post(url("authmanagement/v1/login", "apiUrl"), login),
    register: (register) => requests.post(url("authmanagement/v1/signUp", "apiUrl"), register),
    confirmation: (confirmationInfo) =>
        requests.post(url("authmanagement/v1/confirmSignUp", "apiUrl"), confirmationInfo),
    checkUsername: (username) => requests.get(url(`authmanagement/v1/checkUser?attributeName=username&attributeValue=${username}`, "apiUrl")),
    checkEmail: (email) => requests.get(url(`authmanagement/v1/checkUser?attributeName=email&attributeValue=${email}`, "apiUrl")),
};

export const Services = {
    getBalance: () => requests.get(url("accountmanagement/v1/balance", "apiUrl")),
    getProfile: () => requests.get(url("accountmanagement/v1/profile", "apiUrl")),
    updateProfile: (updateProfileBody) => requests.put(url("accountmanagement/v1/profile", "apiUrl"), updateProfileBody),
    getBanners: () => requests.get(url("contentmanagement/v1/container", "apiUrl")),
    getSummary: () => requests.get(url("/v1/summary", "sportsBook")),
    getBonuses: (method) => requests.get(url(`bonusmanagement/v1/bonus?size=50&trigger=${method}`, "apiUrl")),
    getCasino: (size) => requests.get(url(`thirdpartyintegrationmanagement/v1/casinounited/games?size=${size}&is_live=false&showGameModal=false`, "apiUrl")),
    getLiveCasino: (size) => requests.get(url(`thirdpartyintegrationmanagement/v1/casinounited/games?size=${size}&is_live=true&showGameModal=false`, "apiUrl")),
    launchGame: (id, gameCategory, tableId, localGameId, localGameType, isLive, provider, isMobile) => requests.get(url(
        `thirdpartyintegrationmanagement/v2/${provider}/launch?gameCategory=${gameCategory}&tableId=${tableId}&lang=tr_TR&gameId=${localGameId}&localGameType=${localGameType}&isLive=${isLive}&platform=${isMobile ? "MOBILE" : "WEB"}`, "apiUrl")),
    launchGameV1: (id, gameCategory, tableId, localGameId, localGameType, isLive, provider, isMobile) => requests.get(url(
        `thirdpartyintegrationmanagement/v1/${provider}/launch?gameCategory=${gameCategory}&tableId=${tableId}&lang=tr_TR&gameId=${localGameId}&localGameType=${localGameType}&isLive=${isLive}&platform=${isMobile ? "MOBILE" : "WEB"}`, "apiUrl"))
}

export const Account = {
    changePassword: (values) => requests.postNoResBody(url(`accountmanagement/v1/changePassword`), { "PreviousPassword": values.previousPassword, "ProposedPassword": values.newPassword, "confirm_password": values.newPasswordRepeat }),
    changeLocale: (locale) => requests.postNoResBody(url(`accountmanagement/v1/changeLocale?locale=${locale}`), {}),
    enableMfa: () => requests.post(url(`accountmanagement/v1/enableMfa`), {}),
    verifyMfaToken: (verifyToken) => requests.post(url(`accountmanagement/v1/verifyMfaToken`), { code: verifyToken }),
    disableMfa: () => requests.post(url(`accountmanagement/v1/disableMfa`), {}),
}

// PARA YATIRMA / PARA CEKME

export const paymentWithdraw = {
    withdraw: (reqBody) => requests.post(url('accountmanagement/v1/withdraw'), reqBody)
}

export const BankList = {
    getBankList: () => requests.get(url(`paymentsintegrationmanagement/v1/guvenilirqr/banklist`)),
    getBankListPep: (reqBody) => requests.post(url(`paymentsintegrationmanagement/v1/odendibil/getbanklist`), reqBody),
    getBankListOdebitir: () => requests.get(url("paymentsintegrationmanagement/v1/odebitir/banklist")),
    getBankListFxCuzdan: () => requests.get(url('paymentsintegrationmanagement/v1/fxcuzdan/getBankList'))
}

export const WithdrawTypes = {
    odendibilWithdrawTypes: (reqBody) => requests.post(url('paymentsintegrationmanagement/v1/odendibil/getwithdrawtypes'), reqBody)
}

export const Bonuses = {
    getBonuses: (method) => requests.get(url(`bonusmanagement/v1/bonus?size=50&trigger=${method}`)),
    getBonusByPaymentMethod: (reqBody) => requests.post(url("bonusmanagement/v1/bonus/paymentmethod"), reqBody)
}

export const PaymentMethods = {
    getMethodsApp: (method) => requests.get(url(`paymentsintegrationmanagement/v1/methods/active/${method}`)),
    getMethodsBackOffice: () => requests.get(url(`paymentsintegrationmanagement/v1/methods`))
}

export const PaymentsController = {
    deposit: (reqBody) => requests.post(url('/api/paymentsintegrationmanagement/v1/fixturka/deposit'), reqBody)
}

export const MovenpayPapara = {
    deposit: (reqBody) => requests.post(url('paymentsintegrationmanagement/v1/movenpay/papara/deposit'), reqBody)
}

export const TurboYatirim = {
    deposit: (reqBody) => requests.post(url("paymentsintegrationmanagement/v1/turboyatirim/deposit"), reqBody)
}

export const SapphirePapara = {
    deposit: (reqBody) => requests.post(url("paymentsintegrationmanagement/v1/sapphirepapara/deposit"), reqBody)
}

export const GuvenilirQR = {
    deposit: (reqBody) => requests.post(url("paymentsintegrationmanagement/v1/guvenilirqr/deposit"), reqBody)
}

export const TurboHavale = {
    deposit: (reqBody) => requests.post(url("paymentsintegrationmanagement/v1/turbohavale/deposit"), reqBody)
}

export const OdendibilPep = {
    deposit: (reqBody) => requests.post(url("paymentsintegrationmanagement/v1/odendibil/pep/deposit"), reqBody)
}

export const OdendibilQr = {
    deposit: (reqBody) => requests.post(url("paymentsintegrationmanagement/v1/odendibil/cepbank/deposit"), reqBody)
}

export const MovenPayCreditCard = {
    deposit: (reqBody) => requests.post(url("paymentsintegrationmanagement/v1/movenpay/creditcard/deposit"), reqBody)
}

export const PromotionCode = {
    deposit: (bonusCode) => requests.post(url(`bonusmanagement/v1/bonuscode/${bonusCode}/activate`))
}

export const Vevopay = {
    deposit: (reqBody) => requests.post(url("paymentsintegrationmanagement/v1/vevopay/deposit"), reqBody)
}

export const FxCuzdan = {
    deposit: (reqBody) => requests.post(url("paymentsintegrationmanagement/v1/fxcuzdan/deposit"), reqBody)
}

export const BankTransfer = {
    deposit: (reqBody) => requests.post(url('paymentsintegrationmanagement/v1/bankTransfer/deposit'), reqBody)
}

export const BankTransferCepbank = {
    deposit: (reqBody) => requests.post(url('paymentsintegrationmanagement/v1/bankTransfer/cepbank/deposit'), reqBody)
}

export const Fixturka = {
    deposit: (reqBody) => requests.post(url('paymentsintegrationmanagement/v1/fixturka/deposit'), reqBody)
}

export const EtoroCryptoPayment = {
    deposit: (reqBody) => requests.post(url('paymentsintegrationmanagement/v1/cryptopayment/deposit'), reqBody)
}

export const CryptoPayment = {
    deposit: (reqBody) => requests.post(url("paymentsintegrationmanagement/v1/cryptopay/deposit"), reqBody)
}

export const HizliPapara = {
    deposit: (reqBody) => requests.post(url('paymentsintegrationmanagement/v1/hizlipapara/deposit'), reqBody)
}

export const ThorPay = {
    deposit: (reqBody) => requests.post(url('paymentsintegrationmanagement/v1/thorpay/deposit'), reqBody)
}