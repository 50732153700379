import React from "react";
import SettingsBox from "../component/SettingsBox";
import WalletBox from "../component/WalletBox";
import { useSelector } from "react-redux";
import { history } from "../../..";

import "../style.scss";

//Img Imports
import userIcon from "../../../assets/profile-content-icons/user.svg";
import langIcon from "../../../assets/profile-content-icons/lang.svg";
import passwordIcon from "../../../assets/profile-content-icons/password.svg";
import messagesIcon from "../../../assets/profile-content-icons/messages.svg";
import exitIcon from "../../../assets/profile-content-icons/exit.svg";
import depositIcon from "../../../assets/profile-content-icons/deposit.svg";
import withdrawIcon from "../../../assets/profile-content-icons/withdraw.svg";
import docIcon from "../../../assets/profile-content-icons/doc.svg";
import pastIcon from "../../../assets/profile-content-icons/past.svg";
import bonusIcon from "../../../assets/profile-content-icons/bonus.svg";
import CustomButton from "../../commons/Button";
import { selectBalance } from "../../../redux/slices/profileSlice";
import { useNavigate } from "react-router-dom";

const AccountSettings = () => {
  
  const accountSettingsItems = [
    { title: "Profil Ayarları", icon: userIcon },
    { title: "Dil Ayarları", icon: langIcon },
    { title: "Şifre Değiştir", icon: passwordIcon },
    { title: "Gelen Kutusu", icon: messagesIcon },
    { title: "Sistemden Çık", icon: exitIcon },
  ];

  const walletSettingsItems = [
    { title: "Para Yatır", icon: depositIcon },
    { title: "Para Çek", icon: withdrawIcon },
    { title: "Belge", icon: docIcon },
    { title: "Geçmiş", icon: pastIcon },
    { title: "Bonuslar", icon: bonusIcon },
  ];

  const navigate = useNavigate();
  const balance = useSelector(selectBalance);

  return (
    <div className="accountSettings">
      <div className="accountSettings--top">
        <WalletBox
          title={"Ana Cüzdan"}
          amount={`${balance?.real.value} ${balance?.real.currency == "TRY" ? "TL" : "$"}`}
          backgroundImage={
            "linear-gradient(to right, #de8d2e 0%, #fa713a 100%)"
          }
        />
        <WalletBox
          title={"Bonus Cüzdan"}
          amount={`${balance?.bonus.value} ${balance?.bonus.currency == "TRY" ? "TL" : "$"}`}
          backgroundImage={
            "linear-gradient(to right, #863cc4 0%, #1631ba 100%)"
          }
        />
        <WalletBox
          title={"Toplam Cüzdan"}
          amount={`${balance?.total.value} ${balance?.total.currency == "TRY" ? "TL" : "$"}`}
          backgroundImage={"linear-gradient(to right, #860000 0%, #f00 100%)"}
        />
      </div>
      <div className="accountSettings--mid">
        <span>Hesap Ayarları</span>
        <div className="accountSettings--options">
          {accountSettingsItems.map((accountItem) => (
            <SettingsBox title={accountItem.title} icon={accountItem.icon} key={accountItem.title} onClick={() =>  {
              if(accountItem.title === "Şifre Değiştir") {
                navigate('/change-password')
              } else if(accountItem.title === "Dil Ayarları") {
                navigate('/change-locale')
              } else if (accountItem.title === "Profil Ayarları") {
                navigate('/profile-settings')
              }
            }}/>
          ))}
        </div>
        <div className="accountSettings--divider"></div>
      </div>
      <div className="accountSettings--bot">
        <span>Cüzdan Ayarları</span>
        <div className="accountSettings--options">
          {walletSettingsItems.map((accountItem) => (
            <SettingsBox title={accountItem.title} icon={accountItem.icon} key={accountItem.title}/>
          ))}
        </div>
      </div>
      <div className="accountSettings--btns">
        <CustomButton
          title={"Ana Sayfaya Dön"}
          backgroundColor={null}
          backgroundImage={"linear-gradient(84deg, #853cc4 0%, #1731ba 100%)"}
          textColor={"#fff"}
          fontWeight={"normal"}
          width={"305px"}
          height={"45px"}
          fontSize={"14px"}
        />
        <CustomButton
          title={"Sistemde Çıkış Yap"}
          backgroundColor={null}
          backgroundImage={"linear-gradient(84deg, #860000 0%, #ff0000 100%)"}
          textColor={"#fff"}
          fontWeight={"normal"}
          width={"305px"}
          height={"45px"}
          fontSize={"14px"}
        />
      </div>
    </div>
  );
};

export default AccountSettings;
