export const phoneInputMask = (phoneNumber) => {
    let val = phoneNumber;
    val = val
        .replace(/ /gm, "")
        .replace("(", "")
        .replace(")", "")
        .replace(/\D+/g, "");

    let num = "";
    if (val.length > 3) {
        num = `(${val.substring(0, 3)}) ${val.substring(3, 6)} ${val.substring(
            6,
            8
        )} ${val.substring(8, val.length)}`;
    } else {
        num = `${val.substring(0, 3)} ${val.substring(3, 6)} ${val.substring(
            6,
            8
        )} ${val.substring(8, val.length)}`;
    }
    return num.trim();
};

export const dateInputMask = (date) => {
    let val = date;
    val = val
        .replace(/\//g, "")
    if (val?.length > 4) {
        val = `${val.substring(0, 2)}/${val.substring(2, 4)}/${val.substring(4, 8)}`;
    } else if (val?.length > 2) {
        val = `${val.substring(0, 2)}/${val.substring(2, 4)}`;
    }

    return val;
}

export const phoneInputMaskWithCode = (phoneNumber) => {
    let val = phoneNumber;
    val = val
        .replace("+90 ", "")
        .replace(/ /gm, "")
        .replace("(", "")
        .replace(")", "")
        .replace(/\D+/g, "");

    let num = "";
    if (val.length > 3) {
        num = `(${val.substring(0, 3)}) ${val.substring(3, 6)} ${val.substring(
            6,
            8
        )} ${val.substring(8, val.length)}`;
    } else {
        num = `${val.substring(0, 3)} ${val.substring(3, 6)} ${val.substring(
            6,
            8
        )} ${val.substring(8, val.length)}`;
    }
    return `${num.trim()}`;
};

export const ibanInputMask = (iban) => {
    let val = iban;

    val = val.replace(/ /gm, "").replace(/\D+/g, "");

    let num = "";

    if (val.length > 4) {
        num = `${val.substring(0, 2)} ${val.substring(2, 6)} ${val.substring(
            6,
            10
        )} ${val.substring(10, 14)} ${val.substring(14, 18)} ${val.substring(
            18,
            22
        )} ${val.substring(22, 26)} ${val.substring(26, 30)}`;
    } else {
        num = `${val.substring(0, 2)} ${val.substring(2, 6)} ${val.substring(
            6,
            10
        )} ${val.substring(10, 14)} ${val.substring(14, 18)} ${val.substring(
            18,
            22
        )} ${val.substring(22, 26)} ${val.substring(26, 30)}`;
    }
    console.log(num.length);

    return `TR${num.trim()}`;
};

export const creditCardInputMask = (number) => {
    let val = number;
    val = val
        .replace(/\//g, "")
    if (val?.length > 12) {
        val = `${val.substring(0, 4)}/${val.substring(4, 8)}/${val.substring(8, 12)}/${val.substring(12, 16)}`;
    } else if (val?.length > 8) {
        val = `${val.substring(0, 4)}/${val.substring(4, 8)}/${val.substring(8, 12)}`;
    } else if (val?.length > 4) {
        val = `${val.substring(0, 4)}/${val.substring(4, 8)}`;
    }

    return val;
}

export const dateInputMaskCreditCard = (date) => {
    let val = date;
    val = val
        .replace(/\//g, "")
    if (val?.length > 2) {
        val = `${val.substring(0, 2)}/${val.substring(2, 6)}`;
    }

    return val;
}