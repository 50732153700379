import React, { useEffect } from "react";

//Component Imports
import { Container } from "react-bootstrap";
import BetAndLiveCasSection from "./components/BetAndLiveCasSection";
import HomePageTopCards from "./components/HomePageTopCards";
import LatestEventsLine from "./components/LatestEventsLine";
import PaymentTypes from "./components/PaymentType";
import Popular3DGames from "./components/Popular3DGames";

//Style Imports
import "./styles.scss";

const Home = () => {

  return (
    <div className="homePage">
      <HomePageTopCards />
      <LatestEventsLine />
      <Popular3DGames />
      <BetAndLiveCasSection />
      {/* <PaymentTypes /> */}
    </div>
  );
};

export default Home;
