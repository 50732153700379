import React, { useEffect } from 'react';
import './style.scss'
import { useDispatch } from 'react-redux';

const Popup = ({ isOpen, onClose, modalRef, children, width = "" }) => {
    const dispatch = useDispatch()

    useEffect(() => {
        if (modalRef) {
            function handleClickOutside(event) {
                if (
                    modalRef.current &&
                    !modalRef.current?.contains(event.target)
                ) {
                    dispatch(onClose(false))
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [modalRef]);

    if (!isOpen) {
        return null;
    }
    return (
        <div className='modalEt' >
            <div className='modalEt_popup' ref={modalRef} style={width !== "" ? { width: width } : {}}>
                {children}
            </div>
        </div>
    );
};

export default Popup;