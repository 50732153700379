//Component Imports
import { Accordion, Container } from "react-bootstrap";
import BetCard from "../home/components/BetAndLiveCasSection/BetCard";
import React from "react";

//Style Imports
import "./style.scss";

//Img Imports
import teamImg1 from "../../assets/png/homePage/team1.png";
import teamImg2 from "../../assets/png/homePage/team2.png";
import searchIcon from "../../assets/casino-icons/search.svg";
import Slider from "react-slick";
import SportBookLineItem from "./components/SportBookLineItem";
import MatchesBox from "./components/MatchesBox";
import SportsOptions from "./components/SportsOptions";
import HighestProfits from "./components/HighestProfits";
import BetsBox from "./components/BetsBox";
import sportsBookImg from "../../assets/sportsbook/sportsbook.png";
import CustomButton from "../commons/Button";
import footballIcon from "../../assets/sportsbook/football.svg";
import calendarIcon from "../../assets/sportsbook/calendar.svg";
import CustomAccordion from "../commons/Accordion";

const SportsBook = () => {
  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var settingsV = {
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1330,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const accordionItem = {
    event: "Futbol, Uluslararası, Afrika Uluslar Kupası",
    eventIcon: footballIcon,
    listOfMatches: [
      {
        eventDay: "24.01.22",
        eventTime: "19:00",
        teams: "Gine - Gambiya",
        ratio1: "2.10",
        ratio2: "2.90",
        ratioX: "4.40",
        ratioUst: "1.86",
        ratioAlt: "1.94",
        ratioPlus: "+218",
      },
      {
        eventDay: "24.01.22",
        eventTime: "19:00",
        teams: "Kamerun - Komor Adaları",
        ratio1: "2.10",
        ratio2: "2.90",
        ratioX: "4.40",
        ratioUst: "1.86",
        ratioAlt: "1.94",
        ratioPlus: "+218",
      },
      {
        eventDay: "24.01.22",
        eventTime: "19:00",
        teams: "Senegal - Kape Verde",
        ratio1: "2.10",
        ratio2: "2.90",
        ratioX: "4.40",
        ratioUst: "1.86",
        ratioAlt: "1.94",
        ratioPlus: "+218",
      },
      {
        eventDay: "24.01.22",
        eventTime: "19:00",
        teams: "Fas - Malavi",
        ratio1: "2.10",
        ratio2: "2.90",
        ratioX: "4.40",
        ratioUst: "1.86",
        ratioAlt: "1.94",
        ratioPlus: "+218",
      },
    ],
  };

  return (
    <div className="sportsbook">
      <div className="sportsbook--top">
        <Container>
          <div className="sportsbook--top--eventsCards">
            {/* <Slider {...settings}>
              <BetCard team1Img={teamImg1} team2Img={teamImg2} />
              <BetCard team1Img={teamImg1} team2Img={teamImg2} />
              <BetCard team1Img={teamImg1} team2Img={teamImg2} />
              <BetCard team1Img={teamImg1} team2Img={teamImg2} />
              <BetCard team1Img={teamImg1} team2Img={teamImg2} />
              <BetCard team1Img={teamImg1} team2Img={teamImg2} />
            </Slider> */}
            <BetCard team1Img={teamImg1} team2Img={teamImg2} />
            <BetCard team1Img={teamImg1} team2Img={teamImg2} />
            <BetCard team1Img={teamImg1} team2Img={teamImg2} />
            <BetCard team1Img={teamImg1} team2Img={teamImg2} />
            <BetCard team1Img={teamImg1} team2Img={teamImg2} />
            <BetCard team1Img={teamImg1} team2Img={teamImg2} />
          </div>
        </Container>
        <div className="sportsbook--top--eventsLine">
          <Slider {...settingsV}>
            <SportBookLineItem />
            <SportBookLineItem />
            <SportBookLineItem />
            <SportBookLineItem />
            <SportBookLineItem />
            <SportBookLineItem />
            <SportBookLineItem />
            <SportBookLineItem />
            <SportBookLineItem />
            <SportBookLineItem />
            <SportBookLineItem />
          </Slider>
        </div>
      </div>
      <Container>
        <div className="sportsbook--content">
          <div className="sportsbook--content--left">
            <div className="sportsbook--content--left--searchBox">
              <input placeholder="Karşılaşma ara" />
              <img src={searchIcon} />
            </div>
            <MatchesBox />
            <SportsOptions />
          </div>
          <div className="sportsbook--content--mid">
            <div className="sportsbook--content--mid--topBtns">
              <CustomButton
                imgSrc={footballIcon}
                title={"Spor Bahisleri"}
                backgroundColor={null}
                backgroundImage={
                  "linear-gradient(84deg, #1631ba 0%, #863cc4 100%)"
                }
                textColor={"#fff"}
                fontWeight={"500"}
                width={"161px"}
                height={"50px"}
                fontSize={"12px"}
              />
              <CustomButton
                imgSrc={calendarIcon}
                title={"Günün Karşılaşmaları"}
                backgroundColor={null}
                backgroundImage={
                  "linear-gradient(84deg, #1631ba 0%, #863cc4 100%)"
                }
                textColor={"#fff"}
                fontWeight={"500"}
                width={"211px"}
                height={"50px"}
                fontSize={"12px"}
              />
            </div>
            <img src={sportsBookImg} />

            <div className="sportsbook--content--mid--accordions sportsBook-accordions">
              <CustomAccordion accordionItem={accordionItem} isTable={true} />
            </div>
          </div>

          <div className="sportsbook--content--right">
            <div className="sportsbook--content--right--topTabsBox">
              <div className="sportsbook--content--right--topTabsBox--tab sportsbookActiveTab">
                <span>Kuponum</span>
                <div>0</div>
              </div>
              <div className="sportsbook--content--right--topTabsBox--tab">
                <span>Bahislerim</span>
                <div>0</div>
              </div>
            </div>

            <BetsBox />
            <HighestProfits />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SportsBook;
