import { setProfile, setIsDisabledProfile, setUpdateInputEnabled, setMfaModal, setBalance, setMfaQrCode, setMfaSecret } from "../redux/slices/profileSlice"
import { toast } from 'react-toastify';
import { Account, Services } from "../utils/httphelper";


export const updateProfile = (values) => async (dispatch) => {
    let updateBody = {
        "UserAttributes": [
            {
                "Name": "address",
                "Value": values?.address
            },
            {
                "Name": "birthdate",
                "Value": values?.birthdate
            },
            {
                "Name": "given_name",
                "Value": values?.given_name
            },
            {
                "Name": "custom:countryCode",
                "Value": values?.countryCode
            },
            {
                "Name": "family_name",
                "Value": values?.family_name
            },
            {
                "Name": "email",
                "Value": values?.email
            },
            {
                "Name": "userName",
                "Value": values?.userName
            },
        ]
    }
    dispatch(setIsDisabledProfile(true))
    const updateProfileResult = await Services.updateProfile(updateBody)
    dispatch(setIsDisabledProfile(false))

    if (updateProfileResult.status === 200) {
        dispatch(getProfile());
        dispatch(setUpdateInputEnabled(true))
    } else {
        toast.error(updateProfileResult?.data?.message)
    }
}

export const disableMfa = (navigate) => async (dispatch) => {
    const disableMfaResult = await Account.disableMfa();

    if (disableMfaResult.status === 200) {
        navigate('/uye-paneli')
    } else {
        toast.error(disableMfaResult?.data?.message)
    }
}

export const verifyMfaToken = (mfaSecret, verifyToken,navigate) => async (dispatch) => {
    const verifyMfaTokenResult = await Account.verifyMfaToken(verifyMfaToken);

    if (verifyMfaTokenResult.status === 200) {
        dispatch(setMfaModal(false));
        navigate('/uye-paneli')
    } else {
        toast.error(verifyMfaTokenResult?.data?.message)
    }
}

export const enable2FA = (navigate) => async (dispatch) => {
    dispatch(setIsDisabledProfile(true))
    const enable2FAResult = await Account.enableMfa();
    dispatch(setIsDisabledProfile(false))

    if (enable2FAResult.status === 200) {
        dispatch(setMfaQrCode(enable2FAResult?.data?.qrcode))
        dispatch(setMfaSecret(enable2FAResult?.data?.secretCode))
        dispatch(setMfaModal(true))
    } else {
        navigate('/uye-paneli')
        toast.error(enable2FAResult?.data?.message)
    }
}

export const getBalance = () => async (dispatch) => {
    const getBalanceResult = await Services.getBalance();
    dispatch(setBalance(getBalanceResult.data))
}

export const getProfile = () => async (dispatch) => {
    const getProfileResult = await Services.getProfile()
    dispatch(setProfile(getProfileResult))
} 