import { toast } from 'react-toastify';
import { Account, Auth } from '../utils/httphelper';
import { setActiveTab, setIsAttemptFailed, setLoggedInUser, setLoginErrorMessage, setLoginModal, setRegisterModal } from '../redux/slices/authSlice';
import { setIsLoading } from '../redux/slices/paymentSlice';
import { setGenericPopupInfo } from '../redux/slices/genericsSlice';

export const loginUser = (loginUser) => async (dispatch) => {
    dispatch(setIsLoading(true))
    const loginResponse = await Auth.login(loginUser);
    dispatch(setIsLoading(false))

    if (loginResponse.status === 200) {
        dispatch(setLoggedInUser(loginResponse.data))
        window.localStorage.setItem("user_data", JSON.stringify({ "username": loginResponse.data.Username, "sessionId": loginResponse.data.SessionId }))
        dispatch(setLoginModal(false));
    } else {
        dispatch(setLoginErrorMessage(loginResponse.response.data.message));
        // toast.error(loginResponse.data.message)
    }
};

export const registerUser = (register) => async (dispatch) => {
    let registerObj = {
        Username: register.username,
        Password: register.password,
        UserAttributes: [
            { Name: "email", Value: register.email },
            { Name: "custom:countryCode", Value: register.country },
            { Name: "custom:currency", Value: register.currency },
            { Name: "locale", Value: register.language },
            { Name: "custom:city", Value: register.city },
            { Name: "custom:securityQuestion", Value: register.question },
            { Name: "custom:securityAnswer", Value: register.answer },
            { Name: "birthdate", Value: register.birthday },
            { Name: "given_name", Value: register.name },
            { Name: "family_name", Value: register.lastname },
            { Name: "phone_number", Value: register.phone },
            { Name: "gender", Value: register.gender },
            { Name: "address", Value: register.address },
            { Name: "custom:nationalId", Value: register.tckn }
        ]
    }
    dispatch(setIsLoading(true));
    const registerReponse = await Auth.register(registerObj);
    dispatch(setIsLoading(false));
    if (registerReponse?.data?.UserSub) {
        dispatch(setActiveTab(2))
    } else {
        dispatch(setGenericPopupInfo({
            title: "Hata",
            text: registerReponse.response.data.message
        }))
    }
};

export const confirmation = (confirmationInfo) => async (dispatch) => {
    dispatch(setIsLoading(true));
    const confirmationResult = await Auth.confirmation(confirmationInfo);
    dispatch(setIsLoading(false));

    if (!confirmationResult?.data?.message) {
        dispatch(setGenericPopupInfo({
            title: "Hata",
            text: confirmationResult.response.data.message
        }))
    } else {
        dispatch(setRegisterModal(false));
        dispatch(setGenericPopupInfo({
            title: "Başarılı",
            text: "Kayıt Başarılı. Giriş Yapın"
        }))
    }
}

export const changePassword = (values, navigate) => async (dispatch) => {

    const changePasswordResult = await Account.changePassword(values)

    if (changePasswordResult.status === 200) {
        navigate('/uye-paneli')
    } else {
        dispatch(setIsAttemptFailed(true))
        toast.error(changePasswordResult.response.data.message)
    }
}

export const changeLocale = (locale, navigate) => async (dispatch) => {
    const changeLocaleResult = await Account.changeLocale(locale);

    if (changeLocaleResult.status === 200) {
        navigate('/uye-paneli')
    } else {
        toast.error(changeLocaleResult.response.data.message)
    }
}