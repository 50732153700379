import React from "react";

//Components Imports
import { Container } from "react-bootstrap";
import Slider from "react-slick";

//Style Imports
import "./style.scss";

//Img Imports
import gameImg from "../../../../assets/png/homePage/game.png";

const LatestEventsLine = () => {
  var settings = {
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    arrows: false,
    responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 993,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ]
  };

  const count = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <Container>
      <div className="latestEventsLine">
        <Slider {...settings}>
          {count.map((item) => (
            <div className="latestEventsLine--item" key={item}>
              <img src={gameImg} />
              <div className="latestEventsLine--item--content">
                <p>Erkan € 45 kazandı</p>
                <p>Canlı Poker-Baccarat</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </Container>
  );
};

export default LatestEventsLine;
