import { setRegisterErrors } from "../redux/slices/register"
import { Auth } from "../utils/httphelper"

export const checkUsername = (username) => async (dispatch) => {
    const checkUsernameResult = await Auth.checkUsername(username)

    if (checkUsernameResult.data.exists) {
        dispatch(setRegisterErrors({ inputType: 'username', errorText: "Bu kullanici adı müsait değildir. Lütfen başka bir kullanıcı adı seçiniz" }))
    }
}

export const checkEmail = (email) => async (dispatch) => {
    const checkEmailResult = await Auth.checkEmail(email)

    if (checkEmailResult.data.exists) {
        dispatch(setRegisterErrors({ inputType: 'email', errorText: "Bu email kullanilmakta. Lütfen başka bir email kullanınız" }))
    }
}