import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
    selectAmount,
    selectBankList,
    selectCryptoPayType,
    selectIsLoading,
    selectPaymentMethodCurrency,
    selectPaymentStateErrors, selectPaymentWithdrawValues,
    selectSelectedPaymentMethod,
    setAmount,
    setPaymentMethodsCurrency,
    setPaymentStateErrors,
    setPaymentValues,
    setProgressIndex,
    setSelectedBankCode, setSelectedBankName,
    setSelectedCryptoPayType,
    setWithdrawModal
} from '../../../redux/slices/paymentSliceWithdraw'
import SelectComponent from '../../commons/CustomSelect'
import Input from '../../commons/CustomInput/Input'
import ButtonET from '../../commons/CustomButton'
import {dateInputMask, phoneInputMaskWithCode} from "../../../utils/phoneInputMask";
import {onlyLetterFilter, onlyNumberFilter} from "../../../utils/regex";

const WithdrawModal1 = () => {

    const dispatch = useDispatch()

    const [minMax, setMinMax] = useState({})

    const bankNameList = [
        {
            id: "akbank",
            bankName: "AKBANK T.A.Ş"
        },
        {
            id: "adabank",
            bankName: "ADABANK A.Ş."
        },
        {
            id: "kuveytturk",
            bankName: "KUVEYTTÜRK"
        },
        {
            id: "sekerbank",
            bankName: "ŞEKERBANK"
        },
        {
            id: "albarakaturk",
            bankName: "ALBARAKA TÜRK KATILIM BANKASI A.Ş"
        },
        {
            id: "anadolubank",
            bankName: "ANADOLUBANK A.Ş"
        },
        {
            id: "asyakatilim",
            bankName: "ASYA KATILIM BANKASI A.Ş"
        },
        {
            id: "bankpozitif",
            bankName: "BANKPOZİTİF KREDİ VE KALKINMA BANKASI A.Ş"
        },
        {
            id: "denizbank",
            bankName: "DENİZBANK A.Ş"
        },
        {
            id: "finansbank",
            bankName: "FINANSBANK"
        },
        {
            id: "fibabanka",
            bankName: "FİBABANKA A.Ş"
        },
        {
            id: "hsbc",
            bankName: "HSBC BANK A.Ş"
        },
        {
            id: "icbc",
            bankName: "ICBC TURKEY BANK ANONİM ŞİRKETİ"
        },
        {
            id: "ing",
            bankName: "ING BANK A.Ş"
        },
        {
            id: "kuveytturk",
            bankName: "KUVEYT TÜRK KATILIM BANKASI A.Ş"
        },
        {
            id: "odeabank",
            bankName: "ODEA BANK A.Ş"
        },
        {
            id: "sekerbank",
            bankName: "ŞEKERBANK T.A.Ş"
        },
        {
            id: "teb",
            bankName: "TÜRK EKONOMİ BANKASI A.Ş"
        },
        {
            id: "turkiyefinans",
            bankName: "TÜRKİYE FİNANS KATILIM BANKASI A.Ş"
        },
        {
            id: "garanti",
            bankName: "TÜRKİYE GARANTİ BANKASI A.Ş"
        },
        {
            id: "isbank",
            bankName: "TÜRKİYE İŞ BANKASI A.Ş"
        },
        {
            id: "vakifbank",
            bankName: "TÜRKİYE VAKIFLAR BANKASI T.A.O"
        },
        {
            id: "ykb",
            bankName: "YAPI VE KREDİ BANKASI A.Ş"
        }
    ]

    const cryptoPayTypes = [
        {type: "BTC"},
        {type: "ETH"},
        {type: "LTC"},
        {type: "XRP"},
        {type: "BCH"}
    ]

    const amount = useSelector(selectAmount)
    const paymentMethod = useSelector(selectSelectedPaymentMethod)
    const selectedPaymentMethodCurrency = useSelector(selectPaymentMethodCurrency)
    const bankList = useSelector(selectBankList)
    const isLoading = useSelector(selectIsLoading)
    const errors = useSelector(selectPaymentStateErrors)
    const cryptoPaymentType = useSelector(selectCryptoPayType)
    const values = useSelector(selectPaymentWithdrawValues)

    useEffect(() => {
        setMinMax({})
        dispatch(setPaymentMethodsCurrency("Seçiniz"))
        dispatch(setSelectedBankCode("Seçiniz"))
        dispatch(setAmount(0))
    }, [])

    const getInputsError = (type) => {
        return errors.filter(err => err.inputType === type)[0]
    }

    useEffect(() => {
        setMinMax(paymentMethod.currencies.filter(currency => currency?.currency === selectedPaymentMethodCurrency)[0]?.withdraw)
    }, [selectedPaymentMethodCurrency])

    const handleOnChange = (e, type) => {
        dispatch(setPaymentStateErrors({
            inputType: type,
            errorText: ""
        }))
        if (type === 'withdrawAmount') {
            dispatch(setPaymentValues({inputType: type, state: Number(e.target.value.replace(onlyNumberFilter, ''))}))
        } else if (type === "phoneNumber" || type === "phoneNumber1") {
            dispatch(setPaymentValues({inputType: type, state: phoneInputMaskWithCode(e.target.value)}))
        } else if (type === 'paparaAccountNumber' || type === "iban" || type === "phoneNumber" || type === "tckn" || type === "relativeTckn" || type === "bankAccountNo" || type === "branchCode") {
            dispatch(setPaymentValues({inputType: type, state: e.target.value.replace(onlyNumberFilter, '')}))
        } else if (type === "expireDate" || type === "birthdate") {
            dispatch(setPaymentValues({
                inputType: type,
                state: dateInputMask(e.target.value.replace(onlyNumberFilter, ''))
            }))
        } else if (type === "fullName") {
            dispatch(setPaymentValues({inputType: type, state: e.target.value.replace(onlyLetterFilter, '')}))
        } else {
            dispatch(setPaymentValues({inputType: type, state: e.target.value}))
        }
    }

    const handleOnBlur = (e, type) => {
        if (type === "withdrawAmount") {
            if (minMax?.min && minMax?.max) {
                if (amount < minMax?.min) {
                    dispatch(setPaymentStateErrors({
                        inputType: type,
                        errorText: "Girdiğiniz değer minimum değerin altındadır."
                    }))
                } else if (amount > minMax?.max) {
                    dispatch(setPaymentStateErrors({
                        inputType: type,
                        errorText: "Girdiğiniz değer maksimum değerin üstündedir."
                    }))
                }
                // else if ((amount < minMax?.min || amount > minMax?.max || amount % 10 !== 0)) {
                //     dispatch(setPaymentStateErrors({
                //         inputType: type,
                //         errorText: "Girdiğiniz değer 10 ve katları olmalıdır."
                //     }))
                // }
            }
        } else if (type === "iban") {
            if (e.target.value.length !== 24) {
                dispatch(setPaymentStateErrors({inputType: type, errorText: "Bu alan doldurunuz."}))
            }
        } else if (type === "expireDate" || type === "birthdate") {
            if (e.target.value.length !== 10) {
                dispatch(setPaymentStateErrors({inputType: type, errorText: "Bu alan doldurunuz."}))
            }
        } else if (type === "tckn" || type === "relativeTckn") {
            if (e.target.value.length !== 11) {
                dispatch(setPaymentStateErrors({inputType: type, errorText: "Bu alan doldurunuz."}))
            }
        } else {
            if (e.target.value === "") {
                dispatch(setPaymentStateErrors({inputType: type, errorText: "Bu alan boş bırakılamaz."}))
            }
        }

    }

    useEffect(() => {
        dispatch(setPaymentStateErrors({inputType: "withdrawAmount", errorText: ""}))
    }, [amount])

    const handleNextStep = () => {
        dispatch(setProgressIndex(1))
    }

    return (
        <>
            <div className="depositModal--mid">

                {
                    values.paymentType === "8" ?
                        <SelectComponent labelText={"Banka Adı *"} data={bankNameList} currencyType={"bankName"}
                                         selectedOption={values?.selectedBankName}
                                         setSelectedOption={(e) => dispatch(setSelectedBankName(e.target.value))}/> : <></>
                }

                {
                    values.paymentType === "3" ?
                        <SelectComponent labelText={"Banka Adı *"} data={bankList} currencyType={"bankList"}
                                         selectedOption={values?.selectedBankCode}
                                         setSelectedOption={(e) => dispatch(setSelectedBankCode(e.target.value))}/> : <></>

                }

                {
                    values.paymentType === "3" || values.paymentType === "8" ?
                        <div className='prefix-input-wrapper'>
                            <span style={{top: '43.4px'}}>TR</span>
                            <Input
                                type={"iban"}
                                maxLength={24}
                                labelText={`IBAN`}
                                placeholder={"IBAN"}
                                value={values?.iban}
                                onChange={(e) => handleOnChange(e, "iban")}
                                onBlur={(e) => handleOnBlur(e, "iban")}
                                error={getInputsError("iban").error}
                                errorText={getInputsError("iban").errorText}
                            />
                        </div>
                        : <></>
                }

                {
                    values.paymentType === "2" || values.paymentType === "9" ?
                        <Input
                            type={"paparaAccountNumber"}
                            labelText={`Papara Account Number`}
                            placeholder={'Papara Account Number'}
                            // disabled={selectedPaymentMethodCurrency === "Seçiniz"}
                            value={values.paparaAccountNumber}
                            onChange={(e) => handleOnChange(e, "paparaAccountNumber")}
                            onBlur={(e) => handleOnBlur(e, "paparaAccountNumber")}
                            error={getInputsError("paparaAccountNumber").error}
                            errorText={getInputsError("paparaAccountNumber").errorText}
                        />
                        : <></>
                }

                <SelectComponent labelText={"Para Birimi *"} data={paymentMethod?.currencies} currencyType={"currency"}
                                 selectedOption={selectedPaymentMethodCurrency}
                                 setSelectedOption={(e) => dispatch(setPaymentMethodsCurrency(e.target.value))}/>

                {
                    values?.paymentType === "11" ?
                        <>
                            <SelectComponent labelText={"Banka Adı *"} data={bankNameList} currencyType={"bankName"}
                                             selectedOption={values?.selectedBankName}
                                             setSelectedOption={(e) => dispatch(setSelectedBankName(e.target.value))}/>

                            <Input
                                type={"branchNo"}
                                labelText={`Şube Kodu`}
                                placeholder={'Şube Kodu'}
                                value={values?.branchNo}
                                onChange={(e) => handleOnChange(e, "branchNo")}
                                onBlur={(e) => handleOnBlur(e, "branchNo")}
                                error={getInputsError("branchNo").error}
                                errorText={getInputsError("branchNo").errorText}
                            />

                            <Input
                                type={"bankAccountNo"}
                                labelText={`Banka Hesap Numarası`}
                                placeholder={'Banka Hesap Numarası'}
                                value={values?.bankAccountNo}
                                onChange={(e) => handleOnChange(e, "bankAccountNo")}
                                onBlur={(e) => handleOnBlur(e, "bankAccountNo")}
                                error={getInputsError("bankAccountNo").error}
                                errorText={getInputsError("bankAccountNo").errorText}
                            />

                            <Input
                                type={"fullName"}
                                labelText={`Ad Soyad`}
                                placeholder={'Ad Soyad'}
                                value={values?.fullName}
                                onChange={(e) => handleOnChange(e, "fullName")}
                                onBlur={(e) => handleOnBlur(e, "fullName")}
                                error={getInputsError("fullName").error}
                                errorText={getInputsError("fullName").errorText}
                            />

                            <Input
                                type={"tckn"}
                                labelText={`T.C. Kimlik Numarası`}
                                placeholder={'T.C. Kimlik Numarası'}
                                value={values?.tckn}
                                onChange={(e) => handleOnChange(e, "tckn")}
                                onBlur={(e) => handleOnBlur(e, "tckn")}
                                error={getInputsError("tckn").error}
                                errorText={getInputsError("tckn").errorText}
                                maxLength={11}
                            />

                            <Input
                                type={"relativeTckn"}
                                labelText={`Yakınınızın Kimlik Numarası`}
                                placeholder={'Yakınınızın Kimlik Numarası'}
                                value={values?.relativeTckn}
                                onChange={(e) => handleOnChange(e, "relativeTckn")}
                                onBlur={(e) => handleOnBlur(e, "relativeTckn")}
                                error={getInputsError("relativeTckn").error}
                                errorText={getInputsError("relativeTckn").errorText}
                                maxLength={11}
                            />

                            <Input
                                type={"expireDate"}
                                labelText={`Kimlik Veriliş veya Son Geçerlilik Tarihi`}
                                placeholder={"Kimlik Veriliş veya Son Geçerlilik Tarihi"}
                                value={values?.expireDate}
                                onChange={(e) => handleOnChange(e, "expireDate")}
                                onBlur={(e) => handleOnBlur(e, "expireDate")}
                                error={getInputsError("expireDate").error}
                                errorText={getInputsError("expireDate").errorText}
                            />

                            <div className='prefix-input-wrapper cepbank-prefix'>
                                <span style={{fontSize: '14px', top: '45px'}}>+90</span>
                                <Input
                                    type={"phoneNumber"}
                                    labelText={`Telefon Numarası`}
                                    placeholder={"Telefon Numarası"}
                                    value={values?.cepBankPhoneNumber}
                                    onChange={(e) => handleOnChange(e, "phoneNumber")}
                                    onBlur={(e) => handleOnBlur(e, "phoneNumber")}
                                    error={getInputsError("phoneNumber").error}
                                    errorText={getInputsError("phoneNumber").errorText}
                                    maxLength={15}
                                />
                            </div>

                            <div className='prefix-input-wrapper'>
                                <span style={{
                                    top: '43.4px',
                                    color: `${getInputsError("iban").error ? "red" : "black"}`
                                }}>TR</span>
                                <Input
                                    type={"iban"}
                                    maxLength={24}
                                    labelText={`IBAN`}
                                    placeholder={"IBAN"}
                                    value={values?.iban}
                                    onChange={(e) => handleOnChange(e, "iban")}
                                    onBlur={(e) => handleOnBlur(e, "iban")}
                                    error={getInputsError("iban").error}
                                    errorText={getInputsError("iban").errorText}
                                />
                            </div>


                        </>
                        : <></>
                }

                {
                    values.paymentType === "5" || values.paymentType === "7" || values?.payment === "10" ?
                        <Input
                            type={"accountNo"}
                            labelText={`Hesap No`}
                            placeholder={'Hesap No'}
                            // disabled={selectedPaymentMethodCurrency === "Seçiniz"}
                            value={values?.accountNo}
                            onChange={(e) => handleOnChange(e, "accountNo")}
                            onBlur={(e) => handleOnBlur(e, "accountNo")}
                            error={getInputsError("accountNo").error}
                            errorText={getInputsError("accountNo").errorText}
                        />
                        : <></>
                }

                <Input
                    type={"withdrawAmount"}
                    labelText={`Miktar * ${minMax?.min && minMax?.max ? `( Min: ${minMax?.min} - Max: ${minMax?.max} )` : ""}`}
                    placeholder={
                        minMax?.min && minMax?.max ?
                            `${minMax?.min ? minMax?.min : 0} - ${minMax?.max ? minMax?.max : 0}` :
                            `Tutarı Giriniz`
                    }
                    disabled={selectedPaymentMethodCurrency === "Seçiniz"}
                    value={amount}
                    onChange={(e) => handleOnChange(e, "withdrawAmount")}
                    onBlur={(e) => handleOnBlur(e, "withdrawAmount")}
                    error={getInputsError("withdrawAmount").error}
                    errorText={getInputsError("withdrawAmount").errorText}
                />

                {
                    values.paymentType === "6" ?
                        <Input
                            type={"friendID"}
                            labelText={`Arkadaş ID`}
                            placeholder={'Arkadaş ID'}
                            disabled={selectedPaymentMethodCurrency === "Seçiniz"}
                            value={values?.friendID}
                            onChange={(e) => handleOnChange(e, "friendID")}
                            onBlur={(e) => handleOnBlur(e, "friendID")}
                            error={getInputsError("friendID").error}
                            errorText={getInputsError("friendID").errorText}
                        />
                        : <></>
                }

                {
                    values.paymentType === "1" ?
                        <SelectComponent labelText={"Kripto Tipi *"} data={cryptoPayTypes}
                                         currencyType={"cryptoPayTypes"} selectedOption={cryptoPaymentType}
                                         setSelectedOption={(e) => dispatch(setSelectedCryptoPayType(e.target.value))}/> : <></>
                }

                {
                    values.paymentType === "1" ?
                        <Input
                            type={"walletAddress"}
                            labelText={`Cüzdan Adresi`}
                            placeholder={'Cüzdan Adresi'}
                            disabled={selectedPaymentMethodCurrency === "Seçiniz"}
                            value={values?.walletAddress}
                            onChange={(e) => handleOnChange(e, "walletAddress")}
                            onBlur={(e) => handleOnBlur(e, "walletAddress")}
                            error={getInputsError("walletAddress").error}
                            errorText={getInputsError("walletAddress").errorText}
                        />
                        : <></>
                }

                {
                    values.paymentType === "4" ?
                        <Input
                            type={"payfixWalletId"}
                            labelText={`Payfix Cüzdan ID`}
                            placeholder={'Payfix Cüzdan ID'}
                            disabled={selectedPaymentMethodCurrency === "Seçiniz"}
                            value={values?.payfixWalletId}
                            onChange={(e) => handleOnChange(e, "payfixWalletId")}
                            onBlur={(e) => handleOnBlur(e, "payfixWalletId")}
                            error={getInputsError("payfixWalletId").error}
                            errorText={getInputsError("payfixWalletId").errorText}
                        />
                        : <></>
                }
            </div>

            <div className="depositModal--btns">
                <ButtonET
                    title="İptal Et ve Geri Dön" disabled={isLoading}
                    onClick={() => dispatch(setWithdrawModal(false))}
                />
                <ButtonET
                    btnType='secondaryBtn'
                    title="İlerle ve Ödeme Yöntemine Git"
                    isLoadingIndicator={isLoading}
                    disabled={
                        selectedPaymentMethodCurrency === "Seçiniz" || (amount === 0 || amount === "") ||
                        (amount < minMax?.min || amount > minMax?.max)
                        || (values.paymentType === "1" && values?.walletAddress === "")
                        || ((values.paymentType === "2" || values.paymentType === "9") && values.paparaAccountNumber.length === 0)
                        || ((values.paymentType === "3" || values.paymentType === "8") && values?.iban.length !== 24 && values?.selectedBankCode === "Seçiniz")
                        || (values.paymentType === "4" && values?.payfixWalletId === "")
                        || ((values.paymentType === "5" || values.paymentType === "7" || values?.paymentType === "10") && values?.accountNo === "")
                        || (values.paymentType === "6" && values?.friendID === "")
                        || (values.paymentType === "11"
                            // && values?.bankAccountNo === ""
                            // && values?.selectedBankName === "Seçiniz"
                            // && values?.branchNo === ""
                            // && values?.fullName === ""
                            // && values?.iban?.length !== 24
                            // && values?.expireDate?.length !== 10
                            // && values?.cepBankPhoneNumber?.length !== 15
                            // && values?.relativeTckn?.length !== 24
                            // && values?.tckn?.length !== 24
                        )
                    }
                    onClick={() => handleNextStep()}
                />
            </div>
        </>
    )
}

export default WithdrawModal1