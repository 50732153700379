import {useDispatch, useSelector} from "react-redux";
import {
    selectAmount, selectCryptoPayType,
    selectIsLoading, selectPaymentMethodCurrency, selectPaymentWithdrawValues, selectSelectedPaymentMethod,
    setProgressIndex
} from "../../../redux/slices/paymentSliceWithdraw";
import ButtonET from "../../commons/CustomButton";
import React from "react";
import {
    bankTransferWithdraw,
    cryptoPayWithdraw,
    etoroCryptoPaymentWithdraw, fixturkaWithdraw, fxCuzdanWithdraw, mftWithdraw, odendibilWithdraw,
    paparaWithdraw, sapphirePaparaWithdraw, sendToFriendWithdraw, turboHavaleWithdraw,
    vevopayWithdraw
} from "../../../services/paymentWithdraw";
import {selectProfile} from "../../../redux/slices/profileSlice";
import {selectLoggedInUser} from "../../../redux/slices/authSlice";

const WithdrawModal2 = () => {

    const dispatch = useDispatch();
    const amount = useSelector(selectAmount);
    const isLoading = useSelector(selectIsLoading)
    const selectedPaymentMethodCurrency = useSelector(selectPaymentMethodCurrency)
    const cryptoPaymentType = useSelector(selectCryptoPayType)
    const loggedInUser = useSelector(selectLoggedInUser)
    const selectedPaymentMethod = useSelector(selectSelectedPaymentMethod)
    const sessionId = JSON.parse(window.localStorage.getItem("user_data"))?.sessionId
    const locale = useSelector(selectProfile)?.data?.UserAttributes?.filter(att => att?.Name === "locale")[0]?.Value;
    const values = useSelector(selectPaymentWithdrawValues)


    const handleWithdraw = () => {
        switch (selectedPaymentMethod?.method) {
            case "EtoroCryptopayment":   // * BITTI
                dispatch(etoroCryptoPaymentWithdraw({
                    address: values?.walletAddress,
                    amount: {value: amount, currency: selectedPaymentMethodCurrency},
                    cryptoCurrencyType: cryptoPaymentType,
                    language: locale,
                    note: new Date().toISOString(),
                    type: "CryptoPayment",
                    username: loggedInUser?.Username
                }))
                return
            case "MovenPay-Papara":     // * BITTI
                dispatch(paparaWithdraw({
                    accountNumber: values?.accountNo,
                    amount: {value: amount, currency: selectedPaymentMethodCurrency},
                    language: locale,
                    note: new Date().toISOString(),
                    sessionId: sessionId,
                    type: selectedPaymentMethod?.method,
                    username: loggedInUser?.Username
                }))
                return
            case "CryptoPay":   // * BITTI
                dispatch(cryptoPayWithdraw({
                    amount: {value: amount, currency: selectedPaymentMethodCurrency},
                    language: locale,
                    note: new Date().toISOString(),
                    receivedCurrency: cryptoPaymentType,
                    type: selectedPaymentMethod?.method,
                    username: loggedInUser?.Username,
                    walletAddress: values?.walletAddress
                }))
                return
            case "Vevopay":     // * BITTI
                dispatch(vevopayWithdraw({
                    amount: {value: amount, currency: selectedPaymentMethodCurrency},
                    iban: values?.paparaAccountNumber,
                    paymentType: "papara",
                    processType: "papara",
                    type: selectedPaymentMethod?.method,
                }))
                return
            case "FxCuzdan":    // * BITTI
                dispatch(fxCuzdanWithdraw({
                    amount: {value: amount, currency: selectedPaymentMethodCurrency},
                    bank_id: values?.selectedBankCode,
                    iban: values?.iban,
                    type: selectedPaymentMethod?.method,
                }))
                return
            case "Fixturka":    // * BITTI
                dispatch(fixturkaWithdraw({
                    amount: {value: amount, currency: selectedPaymentMethodCurrency},
                    enduser_remote_id: values?.payfixWalletId,
                    language: locale,
                    note: new Date().toISOString(),
                    paymentCode: "fix",
                    type: selectedPaymentMethod?.method,
                    username: loggedInUser?.Username,
                }))
                return
            case "Odendibil":   // * BITTI
                dispatch(odendibilWithdraw({
                    accountNumber: values?.accountNo,
                    amount: {value: amount, currency: selectedPaymentMethodCurrency},
                    language: locale,
                    note: new Date().toISOString(),
                    sessionId: sessionId,
                    type: selectedPaymentMethod?.method,
                    username: loggedInUser?.Username,
                    withdrawType: "qr"
                }))
                return
            case "SendToFriend":    // * BITTI
                dispatch(sendToFriendWithdraw({
                    amount: {value: amount, currency: selectedPaymentMethodCurrency},
                    friendId: values?.friendID,
                    language: locale,
                    type: selectedPaymentMethod?.method,
                }))
                return
            case "MFT":     // * BITTI
                dispatch(mftWithdraw({
                    accountNumber: Number(values?.accountNo),
                    amount: {value: amount, currency: selectedPaymentMethodCurrency},
                    language: locale,
                    note: new Date().toISOString(),
                    sessionId: sessionId,
                    type: selectedPaymentMethod?.method,
                    username: loggedInUser?.Username,
                }))
                return
            case "TurboHavale":     // * BITTI
                dispatch(turboHavaleWithdraw({
                    amount: {value: amount, currency: selectedPaymentMethodCurrency},
                    bank: values?.selectedBankName,
                    type: selectedPaymentMethod?.method,
                    walletno: values?.iban
                }))
                return
            case "SapphirePapara":      // * BITTI
                dispatch(sapphirePaparaWithdraw({
                    amount: {value: amount, currency: selectedPaymentMethodCurrency},
                    language: locale,
                    note: new Date().toISOString(),
                    papara_number: values?.paparaAccountNumber,
                    type: selectedPaymentMethod?.method,
                    username: loggedInUser?.Username
                }))
                return
            case "TurboYatirim":    // * BITTI
                dispatch(turboHavaleWithdraw({
                    amount: {value: amount, currency: selectedPaymentMethodCurrency},
                    paymentType: "papara",
                    processType: "papara",
                    type: selectedPaymentMethod?.method,
                }))
                return
            case "BankTransfer":    // * BITTI
                dispatch(bankTransferWithdraw({
                    accountNumber: values?.bankAccountNo,
                    amount: {value: amount, currency: selectedPaymentMethodCurrency},
                    bankName: values?.selectedBankName,
                    branchCode: values?.branchNo,
                    fullName: values?.fullName,
                    iban: values?.iban,
                    idExpirationDate: values?.expireDate?.replace(/\//g, "."),
                    language: locale,
                    phoneNumber: values?.cepBankPhoneNumber,
                    senderSsn: values?.relativeTckn,
                    ssn: values?.tckn,
                    type: "BankTransfer"
                }))
                return
            default:
                return
        }
    }

    return (

        <>
            <div className="depositModal--mid">
                {`${amount} miktarını ${values?.walletAddress} hesabına çekmek istediğinizden emin misiniz?`}
            </div>

            <div className="depositModal--btns">
                <ButtonET
                    title="İptal Et ve Geri Dön" disabled={isLoading}
                    onClick={() => dispatch(setProgressIndex(0))}
                />
                <ButtonET
                    btnType='secondaryBtn'
                    title="İlerle ve Ödeme Yöntemine Git"
                    isLoadingIndicator={isLoading}
                    onClick={() => handleWithdraw()}
                />
            </div>
        </>

    )
}

export default WithdrawModal2;