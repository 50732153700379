import React from "react";

const AccordionType3 = () => {
  return (
    <div className="accordionType3">
      <div className="accordionType3--line">
        <div className="accordionType3--line--section">
          <span>1.07</span>
          <span>1 - 0</span>
        </div>
        <div className="accordionType3--line--section">
          <span>-2.5</span>
          <span>1 - 1</span>
        </div>
        <div className="accordionType3--line--section">
          <span>-2.5</span>
          <span>0 - 1</span>
        </div>
      </div>
      <div className="accordionType3--line">
        <div className="accordionType3--line--section">
          <span>1.07</span>
          <span>1 - 0</span>
        </div>
        <div className="accordionType3--line--section">
          <span>-2.5</span>
          <span>1 - 1</span>
        </div>
        <div className="accordionType3--line--section">
          <span>-2.5</span>
          <span>0 - 1</span>
        </div>
      </div>
      <div className="accordionType3--line">
        <div className="accordionType3--line--section">
          <span>1.07</span>
          <span>1 - 0</span>
        </div>
        <div className="accordionType3--line--section">
          <span>-2.5</span>
          <span>1 - 1</span>
        </div>
        <div className="accordionType3--line--section">
          <span>-2.5</span>
          <span>0 - 1</span>
        </div>
      </div>
    </div>
  );
};

export default AccordionType3;
