import { Container } from "react-bootstrap";
import CustomButton from "../commons/Button";
import "./style.scss";
import React from "react";

//Img Imports
import allIcon from "../../assets/casino-icons/allgames.svg";
import homeTeamIcon from "../../assets/sportsbook/homeTeam.svg";
import todaysMatchIcon from "../../assets/sportsbook/todaysMatch.svg";
import matchResults from "../../assets/sportsbook/matchResults.svg";
import BetsBox from "../sportsbook/components/BetsBox";
import HighestProfits from "../sportsbook/components/HighestProfits";
import TopOptionsBar from "./components/TopOptionsBar";
import SportsAndMatches from "./components/SportsAndMatches";

const AllMatches = () => {
  return (
    <Container>
      <div className="allMatches">
        <div className="allMatches--left">
          <div className="allMatches--left--topBtns">
            <div>
              <CustomButton
                title={"Tekli Maç Detayı"}
                backgroundColor={"#333645"}
                backgroundImage={null}
                height={"50px"}
                textColor={"#fff"}
                fontWeight={"normal"}
                width={"174px"}
                fontSize={"14px"}
              />
              <CustomButton
                imgSrc={allIcon}
                title={"Tüm Maçlar"}
                backgroundColor={"#333645"}
                backgroundImage={null}
                height={"50px"}
                textColor={"#fff"}
                fontWeight={"normal"}
                width={"144px"}
                fontSize={"14px"}
              />
            </div>
            <div>
              <CustomButton
                title={"Ev Sahibi"}
                imgSrc={homeTeamIcon}
                backgroundColor={"#333645"}
                backgroundImage={null}
                height={"50px"}
                textColor={"#fff"}
                fontWeight={"normal"}
                width={"124px"}
                fontSize={"14px"}
              />
              <CustomButton
                title={"Bugünün Maçları"}
                imgSrc={todaysMatchIcon}
                backgroundColor={"#333645"}
                backgroundImage={null}
                height={"50px"}
                textColor={"#fff"}
                fontWeight={"normal"}
                width={"174px"}
                fontSize={"14px"}
              />
              <CustomButton
                title={"Maç Sonuçları"}
                imgSrc={matchResults}
                backgroundColor={"#333645"}
                backgroundImage={null}
                height={"50px"}
                textColor={"#fff"}
                fontWeight={"normal"}
                width={"161px"}
                fontSize={"14px"}
              />
            </div>
          </div>
          <TopOptionsBar />
          <SportsAndMatches />
        </div>
        <div className="allMatches--right">
          <div className="allMatches--right--topTabsBox">
            <div className="allMatches--right--topTabsBox--tab activeTab">
              <span>Kuponum</span>
              <div className="allMatches--right--topTabsBox--tab--count">0</div>
            </div>
            <div className="allMatches--right--topTabsBox--tab">
              <span>Bahislerim</span>
              <div>0</div>
            </div>
          </div>
          <BetsBox />
          <HighestProfits />
        </div>
      </div>
    </Container>
  );
};

export default AllMatches;
