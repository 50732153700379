import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
    name: "",
    lastname: "",
    username: "",
    email: "",
    password: "",
    passwordRepeat: "",
    tckn: "",
    phone: "",
    city: "",
    phoneCode: "+90",
    usernameVerification: "",
    codeVerification: "",
    currencyRegister: "Seçiniz",
    country: "Seçiniz",
    language: "Seçiniz",
    gender: "Seçiniz",
    question: "Seçiniz",
    address: "",
    answer: "",
    day: '01',
    month: '01',
    year: '1970',
    errors: [
        { inputType: 'name', error: false, errorText: "" },
        { inputType: 'lastname', error: false, errorText: "" },
        { inputType: 'username', error: false, errorText: "" },
        { inputType: 'email', error: false, errorText: "" },
        { inputType: 'password', error: false, errorText: "" },
        { inputType: 'passwordRepeat', error: false, errorText: "" },
        { inputType: 'tckn', error: false, errorText: "" },
        { inputType: 'phone', error: false, errorText: "" },
        { inputType: 'city', error: false, errorText: "" },
        { inputType: 'usernameVerification', error: false, errorText: "" },
        { inputType: 'codeUserVerification', error: false, errorText: "" },
    ]
}

export const registerSlice = createSlice({
    name: 'register',
    initialState,
    reducers: {
        setStatesEmpty: () => initialState,
        setRegisterErrors: (state, action) => {
            const { inputType, errorText } = action.payload;
            const errorToUpdate = state.errors.find(
                (error) => error.inputType === inputType
            );
            if (errorToUpdate) {
                errorToUpdate.error = errorText ? true : false;
                errorToUpdate.errorText = errorText;
            }
        },
        setPhoneCode: (state, action) => {
            state.phoneCode = action.payload;
        },
        setCurrency: (state, action) => {
            state.currencyRegister = action.payload;
        },
        setCountry: (state, action) => {
            state.country = action.payload;
        },
        setLanguage: (state, action) => {
            state.language = action.payload;
        },
        setGender: (state, action) => {
            state.gender = action.payload;
        },
        setQuestion: (state, action) => {
            state.question = action.payload;
        },
        setAddress: (state, action) => {
            state.address = action.payload;
        },
        setAnswer: (state, action) => {
            state.answer = action.payload;
        },
        setDay: (state, action) => {
            state.day = action.payload;
        },
        setMonth: (state, action) => {
            state.month = action.payload;
        },
        setYear: (state, action) => {
            state.year = action.payload;
        },
        setValuesRegister: (state, action) => {
            switch (action.payload.inputType) {
                case "name":
                    state.name = action.payload.state
                    break;
                case "lastname":
                    state.lastname = action.payload.state
                    break;
                case "username":
                    state.username = action.payload.state
                    break;
                case "email":
                    state.email = action.payload.state
                    break;
                case "password":
                    state.password = action.payload.state
                    break;
                case "passwordRepeat":
                    state.passwordRepeat = action.payload.state
                    break;
                case "tckn":
                    state.tckn = action.payload.state
                    break;
                case "phone":
                    state.phone = action.payload.state
                    break;
                case "city":
                    state.city = action.payload.state
                    break;
                case "usernameVerification":
                    state.usernameVerification = action.payload.state
                    break;
                case "codeUserVerification":
                    state.codeVerification = action.payload.state
                    break;
                default:
                    break;
            }
        }
    }
});

//Actions 
export const { setRegisterErrors, setPhoneCode, setStatesEmpty, setValuesRegister, setAddress, setAnswer, setCountry, setCurrency, setDay, setGender, setLanguage, setMonth, setQuestion, setYear } = registerSlice.actions;

///Selectors
export const selectName = (state) => state.register.name;
export const selectLastname = (state) => state.register.lastname;
export const selectUsername = (state) => state.register.username;
export const selectEmail = (state) => state.register.email;
export const selectPassword = (state) => state.register.password;
export const selectPasswordRepeat = (state) => state.register.passwordRepeat;
export const selectTckn = (state) => state.register.tckn;
export const selectPhone = (state) => state.register.phone;
export const selectErrorsRegister = (state) => state.register.errors;
export const selectPhoneCode = (state) => state.register.phoneCode;
export const selectCity = (state) => state.register.city;
export const selectUsernameVerification = (state) => state.register.usernameVerification;
export const selectCodeVerification = (state) => state.register.codeVerification;
export const selectCurrecyRegister = (state) => state.register.currencyRegister;
export const selectCountry = (state) => state.register.country;
export const selectLanguage = (state) => state.register.language;
export const selectGender = (state) => state.register.gender;
export const selectQuestion = (state) => state.register.question;
export const selectAddress = (state) => state.register.address;
export const selectAnswer = (state) => state.register.answer;
export const selectDay = (state) => state.register.day;
export const selectMonth = (state) => state.register.month;
export const selectYear = (state) => state.register.year;

export default registerSlice.reducer;