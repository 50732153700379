import React from 'react'

//Components Imports
import { Container } from 'react-bootstrap';

//Style Imports
import './style.scss'

//Img Imports
import topCardsLeftImg from "../../../../assets/png/homePage/topCardsLeftImg.png";
import topCardsRightImg from "../../../../assets/png/homePage/topCardsRightImg.png";

const HomePageTopCards = () => (
    <Container>
      <div className="topCards">
        <img src={topCardsLeftImg} />
        <div className="topCards--right">
          <img src={topCardsRightImg} />
          <img src={topCardsRightImg} />
        </div>
      </div>
    </Container>
  );

export default HomePageTopCards