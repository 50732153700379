import React, { useRef, useState, useEffect } from "react";
import "../style.scss";
import DepositModal from "../DepositModal/DepositModal";

//Redux Imports
import { useDispatch, useSelector } from "react-redux";
import { resetValues, selectBonuses, selectDepositErrorPopup, selectDepositErrorText, selectDepositModal, selectIsLoading, selectIsPageLoading, selectPaymentMethods, setBankList, setDepositErrorPopup, setDepositErrorText, setDepositModal, setEtoroCryptoPaymentType, setIsBankTransfer, setIsBankTransferCepBank, setIsCryptoPay, setIsPaparaHizli, setIsPromotionCode, setIsThorPay, setProgressIndex, setPromotionCodeStatus, setSelectedPaymentMethod, setWithdrawModal } from "../../../redux/slices/paymentSlice";
import { selectLoggedInUser } from "../../../redux/slices/authSlice";
import { getBankList, getBankListForOdebitir, getBankListForPep, getBonuses, getMethodsApp } from "../../../services/payment";
import CustomButton from "../../commons/Button";
import Popup from "../../commons/ModalET";
import LoadingIndicator from "../../commons/LoadingIndicator";
import DepositBox from "../../commons/DepositBox/DepositBox";

const WalletDeposit = () => {

    const etoroCryptoPaymentList = [
        { method: "EtoroCryptopayment", cryptoCurrencyType: "ETH", imgSrc: '/assets/etoroETH.svg' },
        { method: "EtoroCryptopayment", cryptoCurrencyType: "BTC", imgSrc: '/assets/etoroBTC.svg' },
        { method: "EtoroCryptopayment", cryptoCurrencyType: "TRX", imgSrc: '/assets/etoroTRX.svg' },
        { method: "EtoroCryptopayment", cryptoCurrencyType: "USDT.TRC20", imgSrc: '/assets/etoroUSDTTRC20.svg' },
    ]

    const dispatch = useDispatch();
    const [selectedMethod, setSelectedMethod] = useState()
    const [cryptoPaymentType, setCryptoPaymentType] = useState("")
    const depositModalStatus = useSelector(selectDepositModal);
    const isPageLoading = useSelector(selectIsPageLoading)
    const loggedInUser = useSelector(selectLoggedInUser);
    const paymentMethods = useSelector(selectPaymentMethods);
    const bonuses = useSelector(selectBonuses);
    const isLoading = useSelector(selectIsLoading);
    let sessionId = JSON.parse(window.localStorage.getItem("user_data"))?.sessionId

    const depositErrorPopupRef = useRef(null);
    const depositErrorText = useSelector(selectDepositErrorText)
    const depositErrorPopup = useSelector(selectDepositErrorPopup)

    const handleShowDepositModal = (paymentMethod, cryptoPaymentType = null) => {
        dispatch(setIsBankTransfer(false))
        dispatch(setIsBankTransferCepBank(false))
        dispatch(setBankList([]))
        dispatch(setProgressIndex(0))
        dispatch(setSelectedPaymentMethod(paymentMethod))
        setSelectedMethod(paymentMethod)
        if (cryptoPaymentType) {
            setCryptoPaymentType(cryptoPaymentType)
        } else {
            setCryptoPaymentType("")
        }
        dispatch(resetValues())

        switch (paymentMethod?.method) {
            case "GuvenilirQR":
                return dispatch(getBankList());
            case "Odendibil-pep":
                return dispatch(getBankListForPep({ paymentType: "pep", sessionId }));
            case "Odendibil-qr":
                return dispatch(getBankListForPep({ paymentType: "qr", sessionId }));
            case "Odebitir":
                return dispatch(getBankListForOdebitir());
            case "Thorpay":
                dispatch(setIsThorPay(true))
                dispatch(setDepositModal(true));
                return
            case "CryptoPay":
                dispatch(setIsCryptoPay(true))
                dispatch(setDepositModal(true));
                return
            case "HizliPapara":
                dispatch(setIsPaparaHizli(true))
                dispatch(setDepositModal(true));
                return
            case "EtoroCryptopayment":
                dispatch(setEtoroCryptoPaymentType(cryptoPaymentType))
                dispatch(setDepositModal(true));
                return
            case "BankTransfer":
                dispatch(setIsBankTransfer(true))
                dispatch(setDepositModal(true));
                return
            case "BankTransferCepbank":
                dispatch(setIsBankTransferCepBank(true))
                dispatch(setDepositModal(true));
                return
            case "PromotionalCode":
                dispatch(setDepositModal(!depositModalStatus));
                dispatch(setProgressIndex(1))
                dispatch(setIsPromotionCode(true))
                window.scroll({ top: 0, left: 0, behavior: "smooth" })
                return
            default:
                dispatch(setDepositModal(!depositModalStatus));
                window.scroll({ top: 0, left: 0, behavior: "smooth" })
                break;
        }
    };

    useEffect(() => {
        dispatch(getMethodsApp("Deposit"));
        dispatch(getBonuses("Deposit"))
        dispatch(setIsPromotionCode(false))
        dispatch(setPromotionCodeStatus("0"))
        dispatch(setDepositErrorPopup(false))
        dispatch(setDepositErrorText(""))
    }, [])

    const paymentMethodIconFinder = (method) => {
        switch (method) {
            case "CryptoPayment":
                return "/assets/USDT.svg"
            case "ETH":
                return "/assets/etoroETH.svg"
            case "BTC":
                return "/assets/etoroBTC.svg"
            case "TRX":
                return "/assets/etoroTRX.svg"
            case "USDT.TRC20":
                return "/assets/etoroUSDTTRC20.svg"
            case "MovenPay-Papara":
                return "/assets/movenpayPapara.svg"
            case "HizliPapara":
                return "/assets/paparaFastTR.svg"
            case "CryptoPay":
                return "/assets/cryptopay.svg"
            case "BankTransferCepbank":
                return "/assets/CEPBANK.svg"
            case "Vevopay":
                return "/assets/vevopay.svg"
            case "BankTransfer":
                return "/assets/bank_transfer.svg"
            case "BackofficeDeposit":
                return "/assets/123.svg"
            case "FxCuzdan":
                return "/assets/fxcuzdan.png"
            case "MovenPay-CreditCard":
                return "/assets/kredikarti-hizli.svg"
            case "PromotionalCode":
                return "/assets/pcode-tr.svg		"
            case "Thorpay":
                return "/assets/kredikartiTr.svg"
            case "Fixturka":
                return "/assets/payfix.png"
            case "Odebitir":
                return "/assets/odebitir.png"
            case "Odendibil-qr":
                return "/assets/QR.svg"
            case "MFT":
                return "/assets/mft.png"
            case "Odendibil-pep":
                return "/assets/PEP.svg"
            case "TurboHavale":
                return "/assets/turbohavale.png"
            case "GuvenilirQR":
                return "/assets/guvenilirqr.svg"
            case "SapphirePapara":
                return "/assets/sapphirepapara.png"
            case "TurboYatirim":
                return "/assets/paybol.svg"
            default:
                break;
        }
    }

    const promotionCheck = (method) => {
        let promotionText = ""

        bonuses?.forEach(element => {
            if (element?.paymentMethods?.filter(mthd => mthd === method)[0]) {
                promotionText += `?%${element?.instruction?.percentage} Bonus`
            }
        });

        return promotionText.trimStart()
    }

    return (
        <>
            <DepositModal modalRef={depositErrorPopupRef} isOpen={depositModalStatus} onClose={() => dispatch(setDepositModal(false))} logo={paymentMethodIconFinder(cryptoPaymentType ? cryptoPaymentType : selectedMethod?.method)} />

            <div className="depositOptionsWrapper">
                <div className="depositOptionsWrapper--header">
                    <div className="depositOptionsWrapper--header--titles">
                        <span>Para Yatır</span>
                        <span>{`Hoşgeldin, ${loggedInUser?.Username}!`}</span>
                    </div>
                </div>
                {
                    isPageLoading ? <LoadingIndicator /> :
                        paymentMethods?.map(paymentMethod => {
                            if (paymentMethod?.method === "EtoroCryptopayment") {
                                return etoroCryptoPaymentList?.map(etoroPayment => {
                                    return <DepositBox
                                        isLoading={isLoading}
                                        key={etoroPayment.cryptoCurrencyType}
                                        method={paymentMethod?.method}
                                        logoSrc={etoroPayment?.imgSrc}
                                        min={paymentMethod?.currencies[0]?.deposit?.min}
                                        max={paymentMethod?.currencies[0]?.deposit?.max}
                                        promotionText={promotionCheck(paymentMethod?.method)}
                                        handleShowModal={() => handleShowDepositModal(paymentMethod, etoroPayment.cryptoCurrencyType)}
                                    />
                                })
                            }
                            else {
                                if (paymentMethod?.isActive) {
                                    return <DepositBox
                                        isLoading={isLoading}
                                        key={paymentMethod?.method}
                                        method={paymentMethod?.method}
                                        logoSrc={paymentMethodIconFinder(paymentMethod?.method)}
                                        min={paymentMethod?.currencies[0]?.deposit?.min}
                                        max={paymentMethod?.currencies[0]?.deposit?.max}
                                        promotionText={promotionCheck(paymentMethod?.method)}
                                        handleShowModal={() => handleShowDepositModal(paymentMethod)}
                                    />
                                }
                            }
                        })
                }
            </div>

            {/* DEPOSIT ERROR POPUP */}
            <Popup isOpen={depositErrorPopup} onClose={setDepositErrorPopup} modalRef={depositErrorPopupRef}>
                <div className="deposit-error-popup">
                    <div className="deposit-error-popup__title">HATA</div>
                    <div className="deposit-error-popup__description">{depositErrorText}</div>
                    <div className="deposit-error-popup__btn">
                        <CustomButton
                            title="Tamam"
                            backgroundColor={null}
                            textColor={"#fff"}
                            backgroundImage={
                                "linear-gradient(84deg, #863cc4 0%, #1631ba 100%)"
                            }
                            fontWeight={"normal"}
                            fontSize={"14px"}
                            width={"100%"}
                            isLoadingIndicator={isLoading}
                            onClick={() => dispatch(setDepositErrorPopup(false))}
                        />
                    </div>
                </div>
            </Popup>

        </>
    );
};

export default WalletDeposit;
