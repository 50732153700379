import React from "react";
import "./style.scss";

const AccordionType1 = () => {
  return (
    <div className="accordionType1">
      <div className="accordionType1--line">
        <div className="accordionType1--line--section">
          <span>1.07</span>
          <span>Aston Villa</span>
        </div>
        <div className="accordionType1--line--section">
          <span>1.07</span>
          <span>X</span>
        </div>
        <div className="accordionType1--line--section">
          <span>1.07</span>
          <span>Leeds United</span>
        </div>
      </div>
    </div>
  );
};

export default AccordionType1;
