import React from 'react';
import "./style.scss";

import walletIcon from "../../../assets/profil/wallet-whiye.svg";

const WalletBox = ({ title, backgroundImage, amount }) => {
  return (
    <div className="walletbox" style={{backgroundImage: backgroundImage}}>
      <img src={walletIcon}/>
      <div className='walletbox--title-amount'>
        <span>{title}</span>
        <span>{amount}</span>
      </div>
    </div>
  )
}

export default WalletBox