//React Imports
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import CasinoOptionBox from "./CasinoOptionBox";
import InfiniteScroll from "react-infinite-scroller";
import fullScreenIcon from '../../assets/icons/fullscreen.png';

//Style Imports
import "./style.scss";

//Component Imports

//Img Imports
import searchIcon from "../../assets/casino-icons/search.svg";
import playtechIcon from "../../assets/casino-icons/providerPlaytech2.svg";
import allIcon from "../../assets/casino-icons/allgames.svg";
import GameCard from "./CasinoGameCard";
import { useDispatch, useSelector } from "react-redux";
import { selectGameUrl, selectIframeModal, selectLiveCasino, setGameUrl, setIframeModal } from '../../redux/slices/gamesSlice';
import { getCasino, getLiveCasino } from "../../services/games";

const providersList = [
  { imgSrc: allIcon, title: "Tümü" },
  { imgSrc: playtechIcon, title: "Playtech" },
  { imgSrc: playtechIcon, title: "Playtech" },
  { imgSrc: playtechIcon, title: "Playtech" },
  { imgSrc: playtechIcon, title: "Playtech" },
  { imgSrc: playtechIcon, title: "Playtech" },
  { imgSrc: playtechIcon, title: "Playtech" },
  { imgSrc: playtechIcon, title: "Playtech" },
  { imgSrc: playtechIcon, title: "Playtech" },
];

const Casino = () => {

  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  const casino = useSelector(selectLiveCasino);
  const gameUrl = useSelector(selectGameUrl);
  const iframeModal = useSelector(selectIframeModal);

  const handleClose = () => dispatch(setIframeModal(false));

  useEffect(() => {
    dispatch(setGameUrl(""))
    dispatch(setIframeModal(false))
    dispatch(getLiveCasino(30));
    dispatch(getCasino(30));
  }, []);

  const fullScreen = () => {
    // check if fullscreen mode is available
    if (document.fullscreenEnabled || document.webkitFullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled) {

      // which element will be fullscreen
      // Do fullscreen
      if (this.iframe.requestFullscreen) {
        this.iframe.requestFullscreen();
      } else if (this.iframe.webkitRequestFullscreen) {
        this.iframe.webkitRequestFullscreen();
      } else if (this.iframe.mozRequestFullScreen) {
        this.iframe.mozRequestFullScreen();
      } else if (this.iframe.msRequestFullscreen) {
        this.iframe.msRequestFullscreen();
      }
    }
  }

  return (
    <div>
      <div className="casino-top">
        <Container>
          <div className="casino-top--wrapper">
            <CasinoOptionBox />
          </div>
        </Container>
      </div>
      <Container>
        <div className="casino-provider-wrapper">
          <div className="casino-provider-wrapper--providers">
            {providersList.map((provider, index) => (
              <>
                <div className="providerBoxWrapper" key={provider.title}>
                  <div
                    className={`casino-provider-wrapper--providers--providerBox${index == selectedTab ? "--active" : ""
                      }`}
                    onClick={() => setSelectedTab(index)}
                  >
                    <img src={provider.imgSrc} />
                    <span>{provider.title}</span>
                  </div>
                  <div className="divider"></div>
                </div>
              </>
            ))}
          </div>

          <div className="casino-provider-wrapper--searchbox input-area">
            <input placeholder="Oyun veya sağlayıcı arayın" />
            <img src={searchIcon} />
          </div>
        </div>
      </Container>

      {/* <Modal show={iframeModal} onHide={handleClose} centered size="xl" className="iframeWrapper">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            
          </Modal.Body>
        </Modal> */}

      {
        iframeModal &&
        <div className="casino-game-wrapper">
          <span className="close-icon"
          // onClick={this.back}
          >
            X
          </span>
          <span className="fullscreen-icon" onClick={fullScreen}>
            <img src={fullScreenIcon} alt='full screen' />
          </span>
          {/* <Loading loading={loading || localLoading} /> */}
          {gameUrl &&
            <iframe id="game-frame" allowFullScreen
              // onLoad={this.handleOnLoad}
              // onError={this.onErrorLoad}
              className="game-window"
              allow="xr-spatial-tracking"
              src={gameUrl} />}
        </div>
      }

      <Container>
        {casino?.games && (
          <InfiniteScroll
            pageStart={0}
            loadMore={() => dispatch(getLiveCasino(casino.games?.length + 15))}
            hasMore={true || false}
            loader={
              <div className="loader" key={0}>
                Loading ...
              </div>
            }
          >
            <div className="games-wrapper">
              {casino?.games?.map((casinoGame) => (
                <GameCard id={casinoGame.id} key={casinoGame.id} gameIcon={casinoGame.game_icon_url} provider={casinoGame.provider} remote_name={casinoGame.remote_name}
                  gameCategory={casinoGame.remote_game_category} tableId={casinoGame.table_id} localGameId={casinoGame.game_id} localGameType={casinoGame.local_game_type} isLive={casinoGame.is_live} item={casinoGame}
                />
              ))}
            </div>


          </InfiniteScroll>
        )}
      </Container>


    </div>
  );
};

export default Casino;
