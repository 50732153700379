import React from "react";
import "./style.scss";
import arrowRight from "../../../assets/depositImgs/arrowRight.svg";

const DepositBox = ({ logoSrc, min, max, promotionText, handleShowModal, method, isLoading }) => {
  return (
    <div className="depositOptionBox">
      <div className="depositOptionBox--left">
        {method}
        <div className="depositOptionBox--left--img">
          <img src={logoSrc} alt={logoSrc} />
        </div>
        <div className="depositOptionBox--left--minMax">
          <div className="depositOptionBox--left--minMax--cont">
            <span>{`${min} ₺`}</span>
            <span style={{ marginLeft: '10px', fontSize: '15px' }}>{`MIN`}</span>
          </div>
          <div className="depositOptionBox--left--minMax--cont">
            <span>{`${max} ₺`}</span>
            <span style={{ marginLeft: '10px', fontSize: '15px' }}>{`MAX`}</span>
          </div>
        </div>
      </div>
      <div className="depositOptionBox--right">
        <div className="depositOptionBox--right--promotion">
          {
            promotionText?.split("?")?.map(text => {
              if (text !== "") {
                return <span>{text}</span>
              }
            })
          }
          <span>{`Ücretsiz - Anlık`}</span>
        </div>
        <div
          className="depositOptionBox--right--selectBox"
          onClick={() => handleShowModal()}
          style={isLoading ? { pointerEvents: "none", opacity: '0.5' } : {}}
        >
          <span>Para Yatır</span>
          <img src={arrowRight} alt="arrowRight" />
        </div>
      </div>
    </div>
  );
};

export default DepositBox;
