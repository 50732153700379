import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectAmount, selectBankTransferValues, selectBonusForSinglePaymentMethod, selectCryptoPayType, selectEtoroCryptoPaymentType, selectIsLoading, selectIsPromotionCode, selectIsThorPay, selectSelectedBankCode, selectSelectedBankName, selectSelectedPaymentMethod, setProgressIndex } from '../../../redux/slices/paymentSlice'
import { bankTransfer, bankTransferCepBank, cryptoPayment, etoroCryptoPayment, fixturka, fxCuzdan, guvenilirQr, hizliPapara, movenpayCreditCard, movenpayPaparaDeposit, odendibilPep, odendibilQR, promotionCodeActivate, sapphirePapara, turboHavale, turboYatirimDeposit, vevoPay } from '../../../services/payment'
import ButtonET from '../../commons/CustomButton'
import Input from '../../commons/CustomInput/Input'
import { onlyNumberFilter } from '../../../utils/regex'
import { selectProfile } from '../../../redux/slices/profileSlice'
import { selectPaymentMethodCurrency } from '../../../redux/slices/paymentSlice'

const DepositModal2 = () => {

    const dispatch = useDispatch()
    const [bonusId, setBonusId] = useState("")
    const [promotionCode, setPromotionCode] = useState("");

    const paymentMethod = useSelector(selectSelectedPaymentMethod)
    const selectedPaymentBonus = useSelector(selectBonusForSinglePaymentMethod);
    const amount = useSelector(selectAmount)
    const bankCode = useSelector(selectSelectedBankCode)
    const sessionId = JSON.parse(window.localStorage.getItem("user_data"))?.sessionId
    const isPromotionCode = useSelector(selectIsPromotionCode);
    const isLoading = useSelector(selectIsLoading)
    const values = useSelector(selectBankTransferValues)
    const locale = useSelector(selectProfile)?.data?.UserAttributes?.filter(att => att?.Name === "locale")[0]?.Value;
    const firstName = useSelector(selectProfile)?.data?.UserAttributes?.filter(att => att?.Name === "given_name")[0]?.Value;
    const lastName = useSelector(selectProfile)?.data?.UserAttributes?.filter(att => att?.Name === "family_name")[0]?.Value;
    const etoroCryptoPaymentType = useSelector(selectEtoroCryptoPaymentType)
    const selectedCurrency = useSelector(selectPaymentMethodCurrency)
    const bankName = useSelector(selectSelectedBankName);
    const cryptoType = useSelector(selectCryptoPayType)
    const isThorPay = useSelector(selectIsThorPay)


    const handleDepositByPayment = () => {
        if (isThorPay) {
            dispatch(setProgressIndex(3))
        } else {
            switch (paymentMethod.method) {
                case "CryptoPayment":
                    return "/assets/USDT.svg"
                case "EtoroCryptopayment":
                    return dispatch(etoroCryptoPayment({
                        amount: String(amount),
                        cryptoCurrencyType: etoroCryptoPaymentType,
                        currency: selectedCurrency,
                        language: locale,
                        bonusId: bonusId !== "NO" ? bonusId : "",
                        claimsBonus: bonusId !== "NO" ? true : false,
                        sessionId
                    }))
                case "MovenPay-Papara":
                    return dispatch(movenpayPaparaDeposit(bonusId !== "NO" ? { currentUrl: "etorobet.wgplatform.com", bonusId, claimsBonus: true } : { currentUrl: "etorobet.wgplatform.com" }))
                case "HizliPapara":
                    return dispatch(hizliPapara({
                        bonusId: bonusId,
                        claimsBonus: bonusId !== "NO" ? true : false,
                        language: locale,
                        sessionId
                    }))
                case "CryptoPay":
                    return dispatch(cryptoPayment({
                        bonusId: bonusId,
                        claimsBonus: bonusId !== "NO" ? true : false,
                        language: locale,
                        payCurrency: cryptoType,
                        priceAmount: String(amount),
                        priceCurrency: selectedCurrency,
                        sessionId
                    }))
                case "BankTransferCepbank":
                    return dispatch(bankTransferCepBank({
                        amount: Number(values.amount),
                        bankName: bankName,
                        bonusId: bonusId !== "NO" ? bonusId : "",
                        claimsBonus: bonusId !== "NO" ? true : false,
                        currency: values?.currency,
                        fullName: values?.fullName,
                        language: locale,
                        ssn: values?.tckn,
                        recipientBirthDate: values?.birthdate.replace(/\//g, "."),
                        recipientMobile: values?.cepBankPhoneNumber1,
                        recipientSSN: values?.citizenNo,
                        reference: values?.reference,
                        senderMobile: values?.cepBankPhoneNumber,
                        idExpirationDate: values?.expireDate.replace(/\//g, "."),
                    }))
                case "Vevopay":
                    return dispatch(vevoPay({ amount, paymentType: "papara", processType: "papara", iban: "", bonusId: bonusId, claimsBonus: bonusId !== "NO" ? true : false }))
                case "BankTransfer":
                    return dispatch(bankTransfer({
                        amount: Number(values?.amount),
                        bonusId: bonusId !== "NO" ? bonusId : "",
                        claimsBonus: bonusId !== "NO" ? true : false,
                        currency: values?.currency,
                        accountNumber: values?.accountNo,
                        branchCode: values?.branchNo,
                        fullName: values?.fullName,
                        iban: values?.iban,
                        ssn: values?.tckn,
                        language: locale,
                        bankName: bankName
                    }))
                case "BackofficeDeposit":
                    return "/assets/123.svg"
                case "FxCuzdan":
                    return dispatch(fxCuzdan({ amount }))
                case "MovenPay-CreditCard":
                    return dispatch(movenpayCreditCard({ currentUrl: "etorobet.wgplatform.com" }))
                case "PromotionalCode":
                    return dispatch(promotionCodeActivate(promotionCode))
                case "Thorpay":
                    return "/assets/kredikartiTr.svg"
                case "Fixturka":
                    return dispatch(fixturka({ amount: Number(amount), fullName: `${firstName} ${lastName}`, language: locale }))
                case "Odebitir":
                    return "/assets/odebitir.png"
                case "Odendibil-qr":
                    return dispatch(odendibilQR({ amount, bankId: Number(bankCode), paymentType: "qr", sessionId }))
                case "MFT":
                    return "/assets/mft.png"
                case "Odendibil-pep":
                    return dispatch(odendibilPep({ amount, sessionId }))
                case "TurboHavale":
                    return dispatch(turboHavale({ amount, paymentType: "havale", processType: "havale", returnUrl: "https://etorobet.wgplatform.com/profile/deposit" }))
                case "GuvenilirQR":
                    return dispatch(guvenilirQr({ amount, bank_code: bankCode, url_redirect: "https://etorobet.wgplatform.com/profile/deposit" }))
                case "SapphirePapara":
                    return dispatch(sapphirePapara({ amount }))
                case "TurboYatirim":
                    return dispatch(turboYatirimDeposit({ amount, paymentType: "paybol", processType: 'paybol', returnUrl: "https://localhost:3000/uye-paneli" }))
                default:
                    break;
            }
        }
    }

    return (
        <>

            {!isPromotionCode && selectedPaymentBonus?.length ? selectedPaymentBonus?.map(bonus => (
                <span className={`depositModal--wantBonus ${bonusId === bonus?.id && "wantBonusActive"}`} onClick={() => setBonusId(bonus?.id)}>{bonus?.name}</span>
            )) : <></>
            }

            {
                !isPromotionCode ?
                    <span className={`depositModal--wantBonus ${bonusId === "NO" && "wantBonusActive"}`} onClick={() => setBonusId('NO')}>Bonus istemiyorum</span>
                    : <></>
            }

            {
                isPromotionCode ?
                    <Input
                        type={"promotionCode"}
                        labelText={`Promosyon Kodu`}
                        placeholder={"Promosyon Kodu"}
                        value={promotionCode}
                        onChange={(e) => setPromotionCode(e.target.value.replace(onlyNumberFilter, ''))}
                    />
                    : <></>
            }


            <div className="depositModal--btns">
                <ButtonET
                    title="İptal Et ve Geri Dön" disabled={isLoading}
                    onClick={() => dispatch(setProgressIndex(0))}
                />
                <ButtonET
                    btnType='secondaryBtn'
                    title="İlerle ve Ödeme Yöntemine Git"
                    isLoadingIndicator={isLoading}
                    disabled={(isPromotionCode && promotionCode.length < 6) || (!isPromotionCode && selectedPaymentBonus?.length && !bonusId)}
                    onClick={() => handleDepositByPayment()}
                />
            </div>
        </>
    )
}

export default DepositModal2