import React from 'react';
import { Spinner } from 'react-bootstrap';
import './style.scss';


const ButtonET = ({ title, isLoadingIndicator, disabled, btnType = "primaryBtn", onClick }) => {
    return (
        <button className={`buttonET ${isLoadingIndicator ? "loadingIndicatorETBtn" : ""} ${btnType} ${disabled ? "buttonET-disabled" : ""}`} disabled={disabled} onClick={onClick} >

            <span>{title}</span>

            {
                isLoadingIndicator ?
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner> : <></>
            }

        </button>
    )
}

export default ButtonET;