import React from "react";
import { Form } from "react-bootstrap";
import "./style.scss";

import CloseEyeIcon from '../../../assets/icons/open_eye.svg';
import OpenEyeIcon from '../../../assets/icons/close_eye.svg';

const InputBoxArea = ({
  error,
  value,
  handleChange,
  touched,
  formName,
  placeholder,
  title,
  type,
  as,
  disabled,
  showPasswordIcons,
  showPasswordIconState,
  setShowIconState
}) => {

  return (
    <div className="inputBoxArea">
      <span>{title}</span>
      <Form.Group>
        <Form.Control
          as={as}
          type={type}
          name={formName}
          value={value}
          onChange={handleChange}
          isValid={touched && !error}
          isInvalid={value && error}
          placeholder={placeholder}
          disabled={disabled}
        />

        {
          showPasswordIcons && <img className={`inputBox-password-show-icon ${error && error !== "* Şifre Girin" && "error-show-icon"}`} src={!showPasswordIconState ? CloseEyeIcon : OpenEyeIcon} onClick={() => setShowIconState(!showPasswordIconState)} />
        }

        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </Form.Group>
    </div>
  );
};

export default InputBoxArea;
