import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectActiveTab, setRegisterModal } from '../../redux/slices/authSlice'
import RegisterStep1 from './RegisterStep1'
import RegisterStep2 from './RegisterStep2'
import RegisterStep3 from './RegisterStep3'
import CloseIcon from '../../assets/icons/close_icon_black.svg'
import { setStatesEmpty } from '../../redux/slices/register'

const RegisterModal = () => {

    const activeTab = useSelector(selectActiveTab)
    const dispatch = useDispatch();

    return (
        <div style={{ position: 'relative' }}>
            <img className='modalEt-closeIcon' src={CloseIcon} alt='closeIcon' onClick={() => {
                dispatch(setStatesEmpty())
                dispatch(setRegisterModal(false))
            }}/>
            {
                activeTab === 0 ?
                    <RegisterStep1 />
                    : activeTab === 1 ? <RegisterStep2 />
                        : activeTab === 2 ? <RegisterStep3 />
                            : <></>
            }
        </div>
    )
}

export default RegisterModal