import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import { forwardRef, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import CustomModal from ".";
import CustomButton from "../Button";
import InputBoxArea from "./InputBoxArea";
import * as yup from "yup";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../../services/auth";
import { selectIsLoading } from "../../../redux/slices/paymentSlice";
import { selectAuthInputErrors, selectLoginErrorMessage, selectLoginModal, selectLoginPassword, selectLoginUserName, setAuthErrors, setLoginErrorMessage, setLoginModal, setValuesAuth } from "../../../redux/slices/authSlice";
import Input from "../CustomInput/Input";
import Popup from "../ModalET";

const LoginModal = () => {
  const dispatch = useDispatch();
  const loginModalRef = useRef(null);
  const isLoading = useSelector(selectIsLoading)
  const loginErrorMessage = useSelector(selectLoginErrorMessage);
  const errors = useSelector(selectAuthInputErrors);

  const username = useSelector(selectLoginUserName)
  const password = useSelector(selectLoginPassword)
  const loginModalStatus = useSelector(selectLoginModal);

  const handleOnClick = (values) => {
    dispatch(loginUser(values))
  }

  useEffect(() => {
    dispatch(setLoginErrorMessage(""))
    dispatch(setValuesAuth({ inputType: "username", state: '' }))
    dispatch(setValuesAuth({ inputType: "password", state: '' }))
  }, [])

  const handleOnChange = (e, type) => {
    dispatch(setValuesAuth({ inputType: type, state: e.target.value }))
  }

  const handleOnBlur = (e, type) => {
    // dispatch(setAuthErrors({ inputType: type, errorText: "Error Text Ornegi" }))
  }

  const handleOnKeyDown = (e, type) => {
    var keycode = e.keyCode || e.which;
    if (keycode === 13) {
      if (username.length >= 6 && password.length >= 6) {
        dispatch(loginUser({
          Username: username,
          Password: password,
        }))
      }
    }
  }

  const getInputsError = (type) => {
    return errors.filter(err => err.inputType === type)[0]
  }

  return (
    <Popup modalRef={loginModalRef} isOpen={loginModalStatus} onClose={() => dispatch(setLoginModal(false))}>
      {
        loginErrorMessage === "" ?
          <div className="loginModal1">

            <Input
              type={"username"}
              labelText={"Kullanıcı Adı"}
              placeholder={"Kullanıcı Adınızı Girin"}
              value={username}
              onChange={(e) => handleOnChange(e, "username")}
              onBlur={(e) => handleOnBlur(e, "username")}
              onKeyDown={handleOnKeyDown}
              error={getInputsError("username").error}
              errorText={getInputsError("username").errorText}
            />
            <Input
              isPassword={true}
              type={"password"}
              labelText={"Şifreniz"}
              placeholder={"Şifre Girin"}
              value={password}
              onChange={(e) => handleOnChange(e, "password")}
              onBlur={(e) => handleOnBlur(e, "password")}
              onKeyDown={handleOnKeyDown}
              error={getInputsError("password").error}
              errorText={getInputsError("password").errorText}
            />

            <div className="loginModal--btn">
              <CustomButton
                title="Giriş Yap"
                backgroundColor={null}
                textColor={"#fff"}
                backgroundImage={
                  "linear-gradient(84deg, #863cc4 0%, #1631ba 100%)"
                }
                fontWeight={"normal"}
                disabled={username && password && !isLoading ? false : true}
                fontSize={"14px"}
                width={"100%"}
                isLoadingIndicator={isLoading}
                onClick={() => handleOnClick({ Username: username, Password: password })}
              />
            </div>

            <div className="loginModal--forgetpassword">
              Şifrenizi mi unuttunuz?
            </div>
            <div className="loginModal--signin">
              <span>Henüz bir hesabın yok mu?</span>
              <span>Hemen Üye Ol!</span>
            </div>

          </div>
          :
          <div className="loginModal loginErrorModal">
            <div className="loginModal1--title">HATA</div>
            <div>{loginErrorMessage}</div>
            <div className="loginModal--btn" style={{ marginTop: '30px', marginBottom: "0" }}>
              <CustomButton
                title="Tamam"
                backgroundColor={null}
                textColor={"#fff"}
                backgroundImage={
                  "linear-gradient(84deg, #863cc4 0%, #1631ba 100%)"
                }
                fontWeight={"normal"}
                fontSize={"14px"}
                width={"100%"}
                isLoadingIndicator={isLoading}
                onClick={() => dispatch(setLoginErrorMessage(""))}
              />
            </div>
          </div>
      }
    </Popup>
  );
};

export default LoginModal;
