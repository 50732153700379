import React from "react";

import "../../style.scss";

const MatchBetRatio = () => {
  return (
    <div className="matchBetRadio">
      <div className="matchBetRadio--teamAndMatchInfo">
        <span className="matchBetRadio--teamAndMatchInfo--matchTime--green">49'</span>
        <div className="matchBetRadio--teamAndMatchInfo--teamNames">
          <span>Seongnam Iihwa</span>
          <span>Soul</span>
        </div>
        <span className="matchBetRadio--teamAndMatchInfo--score">0:0</span>
      </div>
      <div className="matchBetRadio--betRatios">
        <div className="matchBetRadio--betRatios--ratioColumn">
          <span className="betRatioBox">2.10</span>
          <span className="betRatioBox">2.10</span>
          <span className="betRatioBox">2.10</span>
        </div>
        <div className="matchBetRadio--betRatios--ratioColumn">
          <span className="betRatioBox">2.10</span>
          <span className="betRatioBox">2.10</span>
          <span className="betRatioBox">2.10</span>
        </div>
        <div className="matchBetRadio--betRatios--ratioColumn">
          <span className="betRatioBox">2.10</span>
          <span className="betRatioBox">2.10</span>
          <span className="betRatioBox">2.10</span>
        </div>
        <div className="matchBetRadio--betRatios--ratioColumn">
          <span className="betRatioBox">2.10</span>
          <span className="betRatioBox">2.10</span>
          <span className="betRatioBox">2.10</span>
        </div>
        <div className="matchBetRadio--betRatios--ratioColumn">
          <span className="betRatioBox">+24</span>
        </div>
      </div>
    </div>
  );
};

export default MatchBetRatio;
