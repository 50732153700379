import React from "react";

const AccordionType2 = () => {
  return (
    <div className="accordionType2">
      <div className="accordionType2--line">
        <div className="accordionType2--line--section">
          <div>
            <span>1.07</span>
            <span>-2.5</span>
          </div>
          <span className="accordionType2--line--section--team">Aston Villa</span>
        </div>
        <div className="accordionType2--line--section">
          <span className="accordionType2--line--section--team">Leeds United</span>
          <div>
            <span>-2.5</span>
            <span>1.07</span>
          </div>
        </div>
      </div>
      <div className="accordionType2--line">
        <div className="accordionType2--line--section">
          <div>
            <span>1.07</span>
            <span>-2.5</span>
          </div>
          <span className="accordionType2--line--section--team">Aston Villa</span>
        </div>
        <div className="accordionType2--line--section">
          <span className="accordionType2--line--section--team">Leeds United</span>
          <div>
            <span>-2.5</span>
            <span>1.07</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccordionType2;
