import React, { memo, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import PaymentTypes from "../home/components/PaymentType";
import { useDispatch, useSelector } from "react-redux";
import { selectLoggedInUser, setLoggedInUser } from "../../redux/slices/authSlice";

const Layout = (props) => {
  const dispatch = useDispatch();
  const loggedInUser = useSelector(selectLoggedInUser);

  useEffect(() => {

    let user = JSON.parse(window.localStorage.getItem("user_data"));

    dispatch(
      setLoggedInUser({
        Username: user?.username,
        SessionId: user?.sessionId,
        Authenticated: user?.sessionId ? true : false,
      })
    );
  }, []);


  return (
    <div>
      <Header />
      {props.children}
      <PaymentTypes />
      <Footer />
    </div>
  );
};

export default memo(Layout);
