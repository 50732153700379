import React from "react";
import "../style.scss";

//Img Imports
import futbolIcon from "../../../assets/sportsbook/football.svg";
import basketbolIcon from "../../../assets/sportsbook/basketbol.svg";
import tenisIcon from "../../../assets/sportsbook/tenis.svg";
import buzhokeyiIcon from "../../../assets/sportsbook/buzhokeyi.svg";
import voleybolIcon from "../../../assets/sportsbook/voleybol.svg";
import hentbolIcon from "../../../assets/sportsbook/hentbol.svg";
import dartIcon from "../../../assets/sportsbook/dart.svg";
import kriketIcon from "../../../assets/sportsbook/kriket.svg";
import florbolIcon from "../../../assets/sportsbook/florbol.svg";
import futsalIcon from "../../../assets/sportsbook/futsal.svg";
import amerikanFIcon from "../../../assets/sportsbook/amerikanF.svg";
import ragbiIcon from "../../../assets/sportsbook/ragbi.svg";
import pingpongIcon from "../../../assets/sportsbook/pingpong.svg";

const SportsOptions = () => {
  const sports = [
    { title: "Futbol", icon: futbolIcon },
    { title: "Basketbol", icon: basketbolIcon },
    { title: "Tenis", icon: tenisIcon },
    { title: "Buz Hokeyi", icon: buzhokeyiIcon },
    { title: "Voleybol", icon: voleybolIcon },
    { title: "Hentbol", icon: hentbolIcon },
    { title: "Dart", icon: dartIcon },
    { title: "Kriket", icon: kriketIcon },
    { title: "Florbol", icon: florbolIcon },
    { title: "Futsal", icon: futsalIcon },
    { title: "Amerikan Futbolu", icon: amerikanFIcon },
    { title: "Avustralya Futbolu", icon: futbolIcon },
    { title: "Ragbi", icon: ragbiIcon },
    { title: "Masa Tenisi", icon: pingpongIcon },
  ];

  return (
    <div className="sportsbook--content--left--sportsOptions">
      <div className="sportsbook--content--left--sportsOptions--title">
        Tüm Sporlar
      </div>
      <div className="tab-title-underLine"></div>
      
      {sports.map((sport) => (
        <div key={sport.title} className="sportsbook--content--left--sportsOptions--option">
          <div>
            <img src={sport.icon} />
            <span>{sport.title}</span>
          </div>
          <span>{`(814)`}</span>
        </div>
      ))}
    </div>
  );
};

export default SportsOptions;
