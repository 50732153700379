import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import "./style.scss";
import { useNavigate } from "react-router-dom";

const TabItem = ({
  title,
  isActiveTab,
  tabIcon,
  activeIcon,
  isAccordion,
  otherTabs,
  setShow
}) => {

  const [activeSubMenu, setActiveSubMenu] = useState(0);
  const navigate = useNavigate();

  return (
    <div className="tabItemAccordionWrapper">
      {isAccordion ? (
        <Accordion defaultActiveKey={`${isActiveTab ? "0" : "-1"}`}>
          <Accordion.Item eventKey={"0"}>
            <Accordion.Header>
              <div className={`tabItem ${isActiveTab ? "active" : ""}`}>
                <div
                  className={`${tabIcon ? "tabItem--icon" : "tabItem--dot"}`}
                >
                  {tabIcon && <img src={isActiveTab ? activeIcon : tabIcon} />}
                </div>
                <div className="tabItem--title">{title}</div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              {
                otherTabs && otherTabs.map((tab, index) => (
                  <div key={tab.title} className={`tabItem ${isActiveTab && index == activeSubMenu ? "active" : ""}`} onClick={() => {
                    setActiveSubMenu(index)
                    setShow && setShow()
                    if (tab.title === "Şifre Değiştir") {
                      navigate('/change-password')
                    } else if (tab.title === "Profil Ayarları") {
                      navigate('/profile-settings')
                    }
                  }}>
                    <div className="tabItem--dot"></div>
                    <div className="tabItem--title">{tab.title}</div>
                  </div>
                ))
              }
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ) : (
        <div className={`tabItem ${isActiveTab ? "active" : ""}`} onClick={() => {
          if (title === "Cüzdan Ayarları") {
            navigate('/uye-paneli')
          }
        }}>
          <div className={`${tabIcon ? "tabItem--icon" : "tabItem--dot"}`} >
            {tabIcon && <img src={isActiveTab ? activeIcon : tabIcon} />}
          </div>
          <div className="tabItem--title">{title}</div>
        </div>
      )}
    </div>
  );
};

export default TabItem;
