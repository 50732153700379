import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectAmount, selectBankList, selectCryptoPayType, selectIsBankTransfer, selectIsBankTransferCepBank, selectIsCryptoPay, selectIsLoading, selectIsPaparaHizli, selectPaymentMethodCurrency, selectPaymentStateErrors, selectSelectedBankCode, selectSelectedPaymentMethod, setAmount, setDepositModal, setPaymentMethodsCurrency, setPaymentStateErrors, setPaymentValues, setSelectedBankCode, setSelectedBankName } from '../../../redux/slices/paymentSlice'
import { getBonusByPaymentMethod } from '../../../services/payment'
import { selectLoggedInUser } from '../../../redux/slices/authSlice'
import ButtonET from '../../commons/CustomButton'
import Input from "../../commons/CustomInput/Input";
import SelectComponent from '../../commons/CustomSelect'
import { setSelectedCryptoPayType } from '../../../redux/slices/paymentSlice'

const DepositModal1 = () => {

    const dispatch = useDispatch()

    const [minMax, setMinMax] = useState({})

    const bankNameList = [
        {
            id: "akbank",
            bankName: "AKBANK T.A.Ş"
        },
        {
            id: "adabank",
            bankName: "ADABANK A.Ş."
        },
        {
            id: "kuveytturk",
            bankName: "KUVEYTTÜRK"
        },
        {
            id: "sekerbank",
            bankName: "ŞEKERBANK"
        },
        {
            id: "albarakaturk",
            bankName: "ALBARAKA TÜRK KATILIM BANKASI A.Ş"
        },
        {
            id: "anadolubank",
            bankName: "ANADOLUBANK A.Ş"
        },
        {
            id: "asyakatilim",
            bankName: "ASYA KATILIM BANKASI A.Ş"
        },
        {
            id: "bankpozitif",
            bankName: "BANKPOZİTİF KREDİ VE KALKINMA BANKASI A.Ş"
        },
        {
            id: "denizbank",
            bankName: "DENİZBANK A.Ş"
        },
        {
            id: "finansbank",
            bankName: "FINANSBANK"
        },
        {
            id: "fibabanka",
            bankName: "FİBABANKA A.Ş"
        },
        {
            id: "hsbc",
            bankName: "HSBC BANK A.Ş"
        },
        {
            id: "icbc",
            bankName: "ICBC TURKEY BANK ANONİM ŞİRKETİ"
        },
        {
            id: "ing",
            bankName: "ING BANK A.Ş"
        },
        {
            id: "kuveytturk",
            bankName: "KUVEYT TÜRK KATILIM BANKASI A.Ş"
        },
        {
            id: "odeabank",
            bankName: "ODEA BANK A.Ş"
        },
        {
            id: "sekerbank",
            bankName: "ŞEKERBANK T.A.Ş"
        },
        {
            id: "teb",
            bankName: "TÜRK EKONOMİ BANKASI A.Ş"
        },
        {
            id: "turkiyefinans",
            bankName: "TÜRKİYE FİNANS KATILIM BANKASI A.Ş"
        },
        {
            id: "garanti",
            bankName: "TÜRKİYE GARANTİ BANKASI A.Ş"
        },
        {
            id: "isbank",
            bankName: "TÜRKİYE İŞ BANKASI A.Ş"
        },
        {
            id: "vakifbank",
            bankName: "TÜRKİYE VAKIFLAR BANKASI T.A.O"
        },
        {
            id: "ykb",
            bankName: "YAPI VE KREDİ BANKASI A.Ş"
        }
    ]

    const cryptoPayTypes = [
        { type: "BTC" },
        { type: "ETH" },
        { type: "LTC" },
        { type: "XRP" },
        { type: "BCH" }
    ]

    const amount = useSelector(selectAmount)
    const loggedInUser = useSelector(selectLoggedInUser)
    const paymentMethod = useSelector(selectSelectedPaymentMethod)
    const selectedPaymentMethodCurrency = useSelector(selectPaymentMethodCurrency)
    const bankList = useSelector(selectBankList)
    const isLoading = useSelector(selectIsLoading)
    const isCryptoPay = useSelector(selectIsCryptoPay)
    const errors = useSelector(selectPaymentStateErrors)
    const selectedBankCode = useSelector(selectSelectedBankCode)
    const bankTransfer = useSelector(selectIsBankTransfer);
    const bankTransferCepbank = useSelector(selectIsBankTransferCepBank);
    const cryptoPaymentType = useSelector(selectCryptoPayType)
    const isPaparaHizli = useSelector(selectIsPaparaHizli)

    useEffect(() => {
        setMinMax({})
        dispatch(setPaymentMethodsCurrency("Seçiniz"))
        dispatch(setSelectedBankCode("Seçiniz"))
        dispatch(setAmount(0))
    }, [])

    const getInputsError = (type) => {
        return errors.filter(err => err.inputType === type)[0]
    }

    useEffect(() => {
        setMinMax(paymentMethod.currencies.filter(currency => currency?.currency === selectedPaymentMethodCurrency)[0]?.deposit)
    }, [selectedPaymentMethodCurrency])

    const handleGetBonusByPaymentMethod = () => {
            dispatch(getBonusByPaymentMethod(amount, selectedPaymentMethodCurrency, paymentMethod.method, loggedInUser?.Username))
    }

    const handleOnChange = (e, type) => {
        dispatch(setPaymentValues({ inputType: type, state: Number(e.target.value.replace(/[^\d]/g, '')) }))
    }

    const handleOnBlur = (e, type) => {
        if (minMax?.min && minMax?.max) {
            if (amount < minMax?.min) {
                dispatch(setPaymentStateErrors({ inputType: type, errorText: "Girdiğiniz değer minimum değerin altındadır." }))
            } else if (amount > minMax?.max) {
                dispatch(setPaymentStateErrors({ inputType: type, errorText: "Girdiğiniz değer maksimum değerin üstündedir." }))
            } else if (bankList?.length && (amount < minMax?.min || amount > minMax?.max || amount % 10 !== 0)) {
                dispatch(setPaymentStateErrors({ inputType: type, errorText: "Girdiğiniz değer 10 ve katları olmalıdır." }))
            }
        }
    }

    useEffect(() => {
        dispatch(setPaymentStateErrors({ inputType: "depositAmount", errorText: "" }))
    }, [amount])

    return (
        <>
            <div className="depositModal--mid">

                {
                    bankList?.length ?
                        <SelectComponent labelText={"Banka Adı *"} data={bankList} currencyType={"bankList"} selectedOption={selectedBankCode} setSelectedOption={(e) => dispatch(setSelectedBankCode(e.target.value))} /> : <></>
                }

                {
                    bankTransfer || bankTransferCepbank ?
                        <SelectComponent labelText={"Banka Adı *"} data={bankNameList} currencyType={"bankName"} selectedOption={selectedBankCode} setSelectedOption={(e) => dispatch(setSelectedBankName(e.target.value))} /> : <></>
                }

                <SelectComponent labelText={"Para Birimi *"} data={paymentMethod?.currencies} currencyType={"currency"} selectedOption={selectedPaymentMethodCurrency} setSelectedOption={(e) => dispatch(setPaymentMethodsCurrency(e.target.value))} />

                {
                    !isPaparaHizli ? <Input
                        type={"depositAmount"}
                        labelText={`Miktar * ${minMax?.min && minMax?.max ? `( Min: ${minMax?.min} - Max: ${minMax?.max} )` : ""}`}
                        placeholder={
                            minMax?.min && minMax?.max ?
                                `${minMax?.min ? minMax?.min : 0} - ${minMax?.max ? minMax?.max : 0}` :
                                `Tutarı Giriniz`
                        }
                        disabled={selectedPaymentMethodCurrency === "Seçiniz"}
                        value={amount}
                        onChange={(e) => handleOnChange(e, "depositAmount")}
                        onBlur={(e) => handleOnBlur(e, "depositAmount")}
                        error={getInputsError("depositAmount").error}
                        errorText={getInputsError("depositAmount").errorText}
                    /> : <></>
                }
            </div>

            {
                isCryptoPay ?
                    <SelectComponent labelText={"Kripto Tipi *"} data={cryptoPayTypes} currencyType={"cryptoPayTypes"} selectedOption={cryptoPaymentType} setSelectedOption={(e) => dispatch(setSelectedCryptoPayType(e.target.value))} /> : <></>
            }

            <div className="depositModal--btns">
                <ButtonET
                    title="İptal Et ve Geri Dön" disabled={isLoading}
                    onClick={() => dispatch(setDepositModal(false))}
                />
                <ButtonET
                    btnType='secondaryBtn'
                    title="İlerle ve Ödeme Yöntemine Git"
                    isLoadingIndicator={isLoading}
                    disabled={
                        !isPaparaHizli ? (getInputsError("depositAmount").error ||
                            (bankList?.length === 0 && (amount < minMax?.min || amount > minMax?.max)) ||
                            (bankList?.length && (amount < minMax?.min || amount > minMax?.max || amount % 10 !== 0)) ||
                            amount === 0 ||
                            !amount ||
                            selectedPaymentMethodCurrency === "Seçiniz" ||
                            (!isCryptoPay && (bankList?.length && selectedBankCode === "Seçiniz")) ||
                            (isCryptoPay && cryptoPaymentType === "Seçiniz")) : selectedPaymentMethodCurrency === "Seçiniz"
                    }
                    onClick={() => handleGetBonusByPaymentMethod()}
                />
            </div>
        </>
    )
}

export default DepositModal1;