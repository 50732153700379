import React, {  } from 'react'
import ButtonET from '../commons/CustomButton'
import SelectComponent from '../commons/CustomSelect'
import Input from '../commons/CustomInput/Input'
import { useDispatch, useSelector } from 'react-redux'
import { selectActiveTab, setActiveTab } from '../../redux/slices/authSlice'
import { selectAddress, selectAnswer, selectCity, selectCountry, selectCurrecyRegister, selectDay, selectEmail, selectErrorsRegister, selectGender, selectLanguage, selectLastname, selectMonth, selectName, selectPassword, selectPasswordRepeat, selectPhone, selectPhoneCode, selectQuestion, selectTckn, selectUsername, selectYear, setAddress, setAnswer, setCountry, setCurrency, setGender, setLanguage, setQuestion, setRegisterErrors, setValuesRegister } from '../../redux/slices/register'
import './style.scss'
import { registerUser } from '../../services/auth'
import { onlyLetterFilter } from '../../utils/regex'
import { selectIsLoading } from '../../redux/slices/paymentSlice'
import { setDay, setMonth, setYear } from 'date-fns'

const RegisterStep2 = () => {
  const dispatch = useDispatch();

  let dayList = []
  let monthList = []
  let yearList = []

  const currency = useSelector(selectCurrecyRegister)
  const country = useSelector(selectCountry)
  const language = useSelector(selectLanguage)
  const gender = useSelector(selectGender)
  const question = useSelector(selectQuestion)
  const address = useSelector(selectAddress)
  const answer = useSelector(selectAnswer)
  const day = useSelector(selectDay)
  const month = useSelector(selectMonth)
  const year = useSelector(selectYear)

  const date = new Date()

  const name = useSelector(selectName)
  const lastname = useSelector(selectLastname)
  const username = useSelector(selectUsername)
  const email = useSelector(selectEmail)
  const password = useSelector(selectPassword)
  const passwordRepeat = useSelector(selectPasswordRepeat)
  const tckn = useSelector(selectTckn)
  const phone = useSelector(selectPhone)
  const phoneCode = useSelector(selectPhoneCode)
  const city = useSelector(selectCity);
  const isLoading = useSelector(selectIsLoading)

  const activeTab = useSelector(selectActiveTab)
  const errors = useSelector(selectErrorsRegister)

  for (let i = 1; i <= 31; i++) {
    dayList.push(<option value={String(i).length === 1 ? `0${i}` : String(i)}>{String(i).length === 1 ? `0${i}` : String(i)}</option>);
  }

  for (let i = 1; i <= 12; i++) {
    monthList.push(<option value={String(i).length === 1 ? `0${i}` : String(i)}>{String(i).length === 1 ? `0${i}` : String(i)}</option>);
  }

  for (let i = 1950; i <= date.getFullYear() - 18; i++) {
    yearList.push(<option value={i}>{i}</option>);
  }

  const handleRegisterClick = (values) => {
    dispatch(registerUser({
      username,
      password,
      email,
      country,
      currency,
      language,
      city,
      question,
      answer,
      birthday: `${day}/${month}/${year}`,
      name,
      lastname,
      phone: `${phoneCode}${phone}`,
      gender,
      address,
      tckn
    }));
  };

  const getInputsError = (type) => {
    return errors?.filter(err => err.inputType === type)[0]
  }

  const currencyList = [
    { id: 0, code: 'TRY' },
    { id: 1, code: 'EUR' },
    { id: 2, code: 'USD' }
  ]
  const countryList = [
    { id: 0, code: 'Türkiye' },
    { id: 1, code: 'Hollanda' },
    { id: 2, code: 'Amerika' }
  ]

  const languageList = [
    { id: 0, code: 'İngilizce', val: "EN" },
    { id: 1, code: 'Türkçe', val: "TR" }
  ]

  const genderList = [
    { id: 0, code: 'Erkek', },
    { id: 1, code: 'Kadın' }
  ]

  const questionsList = [
    { id: 0, code: 'İlk aşkınızın adı neydi ?' },
    { id: 1, code: 'Çocukluk lakabınız nedir ?' },
    { id: 2, code: 'En sevdiğiniz çocukluk arkadaşınızın adı nedir ?' },
    { id: 3, code: 'En sevdiğiniz film nedir ?' },
    { id: 4, code: 'İlk işiniz hangi şehirdeydi ?' }
  ]

  const handleOnChange = (e, type) => {
    dispatch(setRegisterErrors({ inputType: type, errorText: "" }))
    if (type === 'city') {
      dispatch(setValuesRegister({ inputType: type, state: e.target.value.replace(onlyLetterFilter, '') }))
    }
  }

  const handleOnBlur = (e, type) => {
    if (type === "city") {
      if (e.target.value === "") {
        dispatch(setRegisterErrors({ inputType: type, errorText: "Lütfen şehrinizi giriniz" }))
      }
    }
  }

  const checkIfAllInputValid = () => {
    if (
      currency !== "Seçiniz" &&
      country !== "Seçiniz" &&
      language !== "Seçiniz" &&
      gender !== "Seçiniz" &&
      city !== "" && address !== "" && answer !== "" && question !== "Seçiniz"
    ) {
      return false
    } else {
      return true
    }
  }

  return (
    <div className='register-popup'>

      <div className='register-popup__title'>
        <span>Üye Ol</span>
        <div>
          <span>Kişisel Bilgiler</span>
          <span className={"activeRegisterTab"}>Diğer Bilgiler</span>
        </div>
      </div>

      <div className='register-popup__input-wrapper'>
        <SelectComponent selectedOption={currency} setSelectedOption={(e) => dispatch(setCurrency(e.target.value))} data={currencyList} currencyType={'currencyRegister'} labelText={"Para Birimi *"} />
        <SelectComponent selectedOption={country} setSelectedOption={(e) => dispatch(setCountry(e.target.value))} data={countryList} currencyType={'country'} labelText={"Ülke *"} />
      </div>

      <div className='register-popup__input-wrapper'>
        <Input
          labelText={"Şehir *"}
          placeholder={"Şehir"}
          type={"city"}
          error={getInputsError("city").error}
          errorText={getInputsError("city").errorText}
          onChange={(e) => handleOnChange(e, "city")}
          onBlur={(e) => handleOnBlur(e, "city")}
          disabled={false}
          value={city}
        />
        <SelectComponent selectedOption={language} setSelectedOption={(e) => dispatch(setLanguage(e.target.value))} data={languageList} currencyType={'lang'} labelText={"Dil *"} />
      </div>

      <div className='register-popup__input-wrapper'>
        <SelectComponent selectedOption={gender} setSelectedOption={(e) => dispatch(setGender(e.target.value))} data={genderList} currencyType={'gender'} labelText={"Cinsiyet *"} />
        <div className='register-calender'>
          <SelectComponent selectedOption={day} setSelectedOption={(e) => dispatch(setDay(e.target.value))} data={dayList} currencyType={'day'} labelText={"Gün *"} />
          <SelectComponent selectedOption={month} setSelectedOption={(e) => dispatch(setMonth(e.target.value))} data={monthList} currencyType={'month'} labelText={"Ay *"} />
          <SelectComponent selectedOption={year} setSelectedOption={(e) => dispatch(setYear(e.target.value))} data={yearList} currencyType={'year'} labelText={"Yıl *"} />
        </div>
      </div>

      <div className='register-popup__textarea-wrapper'>
        <div className={`input-label`}>Adresiniz</div>
        <textarea placeholder="Adresiniz" value={address} rows={2} onChange={(e) => dispatch(setAddress(e.target.value))} />
      </div>

      <div className='register-popup__input-wrapper registerFWSelector'>
        <SelectComponent selectedOption={question} setSelectedOption={(e) => dispatch(setQuestion(e.target.value))} data={questionsList} currencyType={'question'} labelText={"Güvenlik Sorusu *"} />
      </div>

      <div className='register-popup__textarea-wrapper'>
        <div className={`input-label`}>Güvenlik Sorusu Cevabı *</div>
        <textarea placeholder="Cevabınızı girin" rows={2} value={answer} onChange={(e) => dispatch(setAnswer(e.target.value))} />
      </div>

      <div className='register-popup__btn-wrapper'>
        <ButtonET
          title="Geri"
          btnType='secondaryBtn'
          onClick={() => dispatch(setActiveTab(0))}
        />
        <ButtonET
          title="Kaydı Tamamla"
          isLoadingIndicator={isLoading}
          onClick={() => handleRegisterClick()}
          disabled={checkIfAllInputValid()}
        />
      </div>
    </div>
  )
}

export default RegisterStep2