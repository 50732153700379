//React Imports
import { useState } from "react";
import React from "react";

//Style Imports
import "./styles.scss";

//Component Imports
import TopHeader from "./TopHeader";
import NavTabs from "./NavTabs";
import Header from "./Header";
import LoginModal from "../../commons/CustomModal/LoginModal";
import { Offcanvas } from "react-bootstrap";

//Redux Imports
import { useDispatch, useSelector } from "react-redux";

//Img Imports
import logo from "../../../assets/png/logo.png";
import walletIcon from "../../../assets/profil/walletWhite.svg";
import CustomButton from "../../commons/Button";
import tvIcon from "../../../assets/icons/tv.svg";
import trFlag from "../../../assets/icons/tr_flag_1.svg";
import deposit from "../../../assets/mobileMenuIcons/depositIcon.svg";
import withdraw from "../../../assets/mobileMenuIcons/withdraw.svg";
import documentIcon from "../../../assets/mobileMenuIcons/document.svg";
import past from "../../../assets/mobileMenuIcons/past.svg";
import bonus from "../../../assets/mobileMenuIcons/bonus.svg";
import user from "../../../assets/mobileMenuIcons/user.svg";
import lang from "../../../assets/mobileMenuIcons/lang.svg";
import changePass from "../../../assets/mobileMenuIcons/changePass.svg";
import notifications from "../../../assets/mobileMenuIcons/notifications.svg";
import exit from "../../../assets/mobileMenuIcons/exit.svg";

import { Link } from "react-router-dom";
import { selectLoggedInUser, selectLoginModal, selectRegisterModal, setLoginModal, setRegisterModal } from "../../../redux/slices/authSlice";
import { selectMobileMenu, setMobileMenu } from "../../../redux/slices/headerSlice";
import { selectUserMenu, setUserMenu } from "../../../redux/slices/profileSlice";
import Popup from "../../commons/ModalET";
import RegisterModal from "../../Register";
import { setStatesEmpty } from "../../../redux/slices/register";

const HeaderWrapper = () => {

  const dispatch = useDispatch();
  const [mobileMenuBtn, setMobileMenuBtn] = useState(false);
  const loginModalStatus = useSelector(selectLoginModal);
  const registerModalStatus = useSelector(selectRegisterModal);
  const mobileMenuStatus = useSelector(selectMobileMenu);
  const userMenuStatus = useSelector(selectUserMenu);
  const loggedInUser = useSelector(selectLoggedInUser);

  const [settingsTab, setSettingsTab] = useState(0);

  const handleLoginButtonClick = (isOpen) => {
    dispatch(setUserMenu(false));
    dispatch(setLoginModal(isOpen));
  };

  const handleRegisterButtonClick = (isOpen) => {
    dispatch(setUserMenu(false));
    dispatch(setStatesEmpty())
    dispatch(setRegisterModal(isOpen));
  };

  const handleMobileMenuClick = (isOpen) => {
    dispatch(setMobileMenu(isOpen));
  };

  const handleUserMenuClick = (isOpen) => {
    dispatch(setUserMenu(isOpen));
  };

  const accountSettingsList = [
    { img: user, title: "Profil Ayarları" },
    { img: lang, title: "Dil Ayarları" },
    { img: changePass, title: "Şifre Değiştir" },
    { img: notifications, title: "Gelen Kutusu" },
    { img: exit, title: "Sistemden Çık" },
  ];

  const walletSettingsList = [
    { img: deposit, title: "Para Yatır" },
    { img: withdraw, title: "Para Çek" },
    { img: documentIcon, title: "Belge" },
    { img: past, title: "Geçmiş" },
    { img: bonus, title: "Bonuslar" },
  ];

  return (
    <>
      {loginModalStatus ? (
        <LoginModal setShowModal={handleLoginButtonClick} />
      ) : (
        <></>
      )}

      <Popup width={"750px"} isOpen={registerModalStatus} onClose={() => dispatch(setRegisterModal(false))}>
        <RegisterModal />
      </Popup>

      <TopHeader />

      <Header
        show={mobileMenuStatus}
        setShow={handleMobileMenuClick}
        userMenu={userMenuStatus}
        setShowUserMenu={handleUserMenuClick}
        showLoginModal={loginModalStatus}
        setShowLoginModal={handleLoginButtonClick}
        showRegisterModal={registerModalStatus}
        setShowRegisterModal={handleRegisterButtonClick}
      />

      <NavTabs />

      <Offcanvas
        // show={mobileMenuStatus}
        show={false}
        onHide={() => handleMobileMenuClick(false)}
        className="mobile-menu-modal"
      >
        {/* <Offcanvas.Header closeButton> */}
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <Link to="/">
              <img
                src={logo}
                style={{ width: "202px", height: "43px" }}
                alt="etorobet"
                onClick={() => handleMobileMenuClick(false)}
              />
            </Link>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="mobile-menu mb-active">
            <NavTabs handleMobileMenuClick={handleMobileMenuClick} />
            <div className="mobile-menu--bottom">
              <CustomButton
                textColor="#fff"
                imgSrc={tvIcon}
                backgroundColor={"#853cc4"}
                backgroundImage={null}
                title="EtoroTV"
                fontWeight="bold"
                fontSize="12px"
                stateToSet={registerModalStatus}
                setState={handleRegisterButtonClick}
                width={"200px"}
                height={"41px"}
              />
              {!loggedInUser?.Username && (
                <>
                  <CustomButton
                    textColor="#3434bc"
                    backgroundColor="#fff"
                    backgroundImage={null}
                    title="Giriş Yap"
                    fontWeight="bold"
                    fontSize="12px"
                    stateToSet={loginModalStatus}
                    setState={handleLoginButtonClick}
                    width={"200px"}
                    height={"41px"}
                  />
                  <CustomButton
                    textColor="#fff"
                    backgroundColor={null}
                    backgroundImage="linear-gradient(248deg, #fe6641 100%, #ffa11b -2%)"
                    title="Kayıt Ol"
                    fontWeight="bold"
                    fontSize="12px"
                    stateToSet={registerModalStatus}
                    setState={handleRegisterButtonClick}
                    width={"200px"}
                    height={"41px"}
                  />
                </>
              )}
            </div>
            <div className="mobile-menu--lang">
              <img src={trFlag} />
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={userMenuStatus}
        onHide={() => handleUserMenuClick(false)}
        className="mobile-user-modal userModal"
      >
        <Offcanvas.Body>
          {loggedInUser?.Username ? (
            <div className="mobile-user-modal--body">
              <span className="mobile-user-modal--body--welcome">
                Hoşgeldin, {loggedInUser?.Username}!
              </span>
              <div className="mobile-user-modal--body--walletCards">
                <div className="mobile-user-modal--body--walletCards--walletCard">
                  <img src={walletIcon} />
                  <span className="mobile-user-modal--body--walletCards--walletCard--title">
                    Ana Cüzdan
                  </span>
                  <span>132,24</span>
                </div>
                <div className="mobile-user-modal--body--walletCards--walletCard">
                  <img src={walletIcon} />
                  <span className="mobile-user-modal--body--walletCards--walletCard--title">
                    Bonus Cüzdanı
                  </span>
                  <span>132,24</span>
                </div>
                <div className="mobile-user-modal--body--walletCards--walletCard">
                  <img src={walletIcon} />
                  <span className="mobile-user-modal--body--walletCards--walletCard--title">
                    Çekilebilir
                  </span>
                  <span>132,24</span>
                </div>
              </div>
              <div className="mobile-user-modal--body--settingsBox">
                <span
                  className={`mobile-user-modal--body--settingsBox--settingBox ${settingsTab == 0 && "activeSettingsBox"
                    }`}
                  onClick={() => setSettingsTab(0)}
                >
                  Hesap Ayarları
                </span>
                <span
                  className={`mobile-user-modal--body--settingsBox--settingBox ${settingsTab == 1 && "activeSettingsBox"
                    }`}
                  onClick={() => setSettingsTab(1)}
                >
                  Cüzdan Ayarları
                </span>
              </div>
              {settingsTab == 0 ? (
                <div className="mobile-user-modal--body--accountSettings">
                  {accountSettingsList.map((listItem) => (
                    <div className="mobile-user-modal--body--optionsBox">
                      <img src={listItem.img} />
                      <span>{listItem.title}123</span>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="mobile-user-modal--body--walletSettings">
                  {walletSettingsList.map((listItem) => (
                    <div className="mobile-user-modal--body--optionsBox">
                      <img src={listItem.img} />
                      <span>{listItem.title}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <div className="mobile-user-modal--body--btns">
              <CustomButton
                textColor="#fff"
                backgroundColor={null}
                backgroundImage={
                  "linear-gradient(84deg, #863cc4 0%, #1631ba 100%)"
                }
                title="Giriş Yap"
                fontWeight="bold"
                fontSize="12px"
                stateToSet={loginModalStatus}
                setState={handleLoginButtonClick}
                width={"49%"}
                height={"41px"}
              />
              <CustomButton
                textColor="#fff"
                backgroundColor={null}
                backgroundImage="linear-gradient(248deg, #fe6641 100%, #ffa11b -2%)"
                title="Kayıt Ol"
                fontWeight="bold"
                fontSize="12px"
                stateToSet={registerModalStatus}
                setState={handleRegisterButtonClick}
                width={"49%"}
                height={"41px"}
              />
            </div>
          )}
          <div className="mobile-user-modal--body--btns">
            <CustomButton
              textColor="#fff"
              backgroundColor={null}
              backgroundImage={
                "linear-gradient(84deg, #863cc4 0%, #1631ba 100%)"
              }
              title="Giriş Yap"
              fontWeight="bold"
              fontSize="12px"
              stateToSet={loginModalStatus}
              setState={handleLoginButtonClick}
              width={"49%"}
              height={"41px"}
            />
            <CustomButton
              textColor="#fff"
              backgroundColor={null}
              backgroundImage="linear-gradient(248deg, #fe6641 100%, #ffa11b -2%)"
              title="Kayıt Ol"
              fontWeight="bold"
              fontSize="12px"
              stateToSet={registerModalStatus}
              setState={handleRegisterButtonClick}
              width={"49%"}
              height={"41px"}
            />
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default HeaderWrapper;
