import React from "react";
import CustomButton from "../../../commons/Button";

//Style Imports
import "./style.scss";

//Component Imports

//Img Imports
import team1 from "../../../../assets/png/homePage/team1.png";
import team2 from "../../../../assets/png/homePage/team2.png";
import BetCard from "./BetCard";

const BetCards = () => {
  return (
    <div className="betCards betCardsCont">
      <BetCard team1Img={team1} team2Img={team2} />
      <BetCard team1Img={team1} team2Img={team2} />
      <BetCard team1Img={team1} team2Img={team2} />
    </div>
  );
};

export default BetCards;
