import React from "react";
import "../../style.scss";

export const TypeFootball = () => {
  return (
    <>
      <div className="sportHeader--right--column">
        <span>Genel Bahis</span>
      </div>
      <div className="sportHeader--right--column">
        <span>Sonraki Gol</span>
      </div>
      <div className="sportHeader--right--column">
        <span>Handikap</span>
      </div>
      <div className="sportHeader--right--column">
        <span>Üst Alt</span>
      </div>
    </>
  );
};

export const TypeTennis = () => {
  return (
    <>
      <div className="sportHeader--right--column">
        <span>Beraberlikte İade</span>
      </div>
      <div className="sportHeader--right--column">
        <span>Ayarla</span>
      </div>
      <div className="sportHeader--right--column">
        <span>Handikap</span>
      </div>
      <div className="sportHeader--right--column">
        <span>Toplam</span>
      </div>
    </>
  );
};

export const TypeBasketball = () => {
  return (
    <>
      <div className="sportHeader--right--column">
        <span>Genel Bahis</span>
      </div>
      <div className="sportHeader--right--column">
        <span>Handikap</span>
      </div>
      <div className="sportHeader--right--column">
        <span>Toplam</span>
      </div>
    </>
  );
};
