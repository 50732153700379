import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

//Style Imports
import "./style.scss";

//Component Imports
import { faChevronLeft, faChevronRight, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GameCard from "../../../commons/GameCard";
import BetCards from "./BetCards";
import {Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { getSummary } from "../../../../services/games";
import { selectSummary } from "../../../../redux/slices/gamesSlice";

const BetAndLiveCasSection = () => {

  const count = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const dispatch = useDispatch();

  const summary = useSelector(selectSummary);

  useEffect(() => {
    dispatch(getSummary());
  },[])

  return (
    <Container>
      <div className="betAndLiveCasSection">
        <div className="betAndLiveCasSection--leftSide">
          <div className="betAndLiveCasSection--leftSide--titles">
            <p className="tab-title">Spor Bahisleri</p>
            <div className="showAll showAllBtnIcons">
              <FontAwesomeIcon icon={faChevronLeft} />
              <p className="showAllBtn"><Link to="/sportsbook" onClick={() => window != undefined && window.scrollTo(0, 0)}>Tümünü Gör</Link></p>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </div>
          <div className="tab-title-underLine"></div>
          <BetCards />
        </div>

        <div className="betAndLiveCasSection--rightSide">
          <div className="betAndLiveCasSection--rightSide--titles">
            <p className="tab-title">Popüler Canlı Casino Oyunları</p>
            <div className="showAll showAllBtnIcons">
              <FontAwesomeIcon icon={faChevronLeft} />
              <p className="showAllBtn">Tümünü Gör</p>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </div>
          <div className="tab-title-underLine"></div>
          <div className="gameCardsCont">
            {
                count.map(i => (
                    <GameCard key={i + 7}/>
                ))
            }
        </div>
        </div>
      </div>
    </Container>
  );
};

export default BetAndLiveCasSection;
