import {
    setBankList,
    setIsLoading, setPaymentType,
    setWithdrawErrorPopup,
    setWithdrawErrorText,
    setWithdrawModal
} from "../redux/slices/paymentSliceWithdraw"
import {BankList, paymentWithdraw, WithdrawTypes} from "../utils/httphelper"

export const etoroCryptoPaymentWithdraw = (reqBody) => async (dispatch) => {
    dispatch(setIsLoading(true))
    const etoroCryptoPaymentResult = await paymentWithdraw.withdraw(reqBody);
    dispatch(setIsLoading(false))

    if (etoroCryptoPaymentResult.status === 200) {

    } else {
        dispatch(setWithdrawErrorText(etoroCryptoPaymentResult?.response?.data?.message))
        dispatch(setWithdrawErrorPopup(true))
    }
}

export const getBankListForFxCuzdan = () => async (dispatch) => {
    dispatch(setIsLoading(true))
    let getBankListForFxCuzdanResult = await BankList.getBankListFxCuzdan()
    dispatch(setIsLoading(false))

    if (getBankListForFxCuzdanResult.status === 200) {
        const newList = [];

        for (const [key, value] of Object.entries(getBankListForFxCuzdanResult.data)) {
            newList.push({
                code: key,
                name: value,
            })
        }

        dispatch(setBankList(newList))
        dispatch(setPaymentType('3'))
        dispatch(setWithdrawModal(true))
    }
}

export const paparaWithdraw = (reqBody) => async (dispatch) => {

}

export const cryptoPayWithdraw = (reqBody) => async (dispatch) => {

}

export const vevopayWithdraw = (reqBody) => async (dispatch) => {

}

export const fxCuzdanWithdraw = (reqBody) => async (dispatch) => {

}

export const fixturkaWithdraw = (reqBody) => async (dispatch) => {

}

export const odendibilWithdraw = (reqBody) => async (dispatch) => {

}

export const sendToFriendWithdraw = (reqBody) => async (dispatch) => {

}

export const mftWithdraw = (reqBody) => async (dispatch) => {

}

export const turboHavaleWithdraw = (reqBody) => async (dispatch) => {

}

export const sapphirePaparaWithdraw = (reqBody) => async (dispatch) => {

}

export const bankTransferWithdraw = (reqBody) => async (dispatch) => {

}


export const getOdendibilWithdrawType = (reqBody) => async (dispatch) => {
    dispatch(setIsLoading(true))
    let odendibilWithdrawTypeResult = await WithdrawTypes.odendibilWithdrawTypes()
    dispatch(setIsLoading(false))

    if (odendibilWithdrawTypeResult?.data?.result?.status) {
    }
}