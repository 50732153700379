import React from "react";
import "../style.scss";

import favoriteIcon from "../../../assets/sportsbook/favorite.svg";
import favoritedIcon from "../../../assets/sportsbook/favorited.svg";
import FavoriteBoxItem from "./FavoriteBoxItem";

const FavoritesBox = () => {
  const count = [1, 2, 3,4];

  return (
    <div className="sportsbook-live-favorites">
      <div className="sportsbook-live-favorites--title">
        <img src={favoriteIcon} />
        <span>Favorilerim</span>
      </div>

      <div className="tab-title-underLine"></div>

      <div className="sportsbook-live-favorites--items">
        {count.map((c) => (
          <FavoriteBoxItem key={c+4}/>
        ))}
      </div>
    </div>
  );
};

export default FavoritesBox;
