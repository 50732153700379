import React from "react";
import "../../style.scss";

const MatchBetRatioHeader = () => {
  return (
    <div className="matchBetRadioHeader">
      <div className="matchBetRadioHeader--teamAndMatchInfo">
          <span>Hindistan, Delhi Ligi, Kadınlar</span>
          <span>Skor</span>
      </div>
      <div className="matchBetRadioHeader--betRatios">
        <div className="matchBetRadioHeader--betRatios--ratioColumn">
            <span>1</span>
            <span>X</span>
            <span>2</span>
        </div>
        <div className="matchBetRadioHeader--betRatios--ratioColumn">
            <span>1</span>
            <span>X</span>
            <span>2</span>
        </div>
        <div className="matchBetRadioHeader--betRatios--ratioColumn">
            <span></span>
            <span>1</span>
            <span>2</span>
        </div>
        <div className="matchBetRadioHeader--betRatios--ratioColumn">
            <span></span>
            <span>+</span>
            <span>-</span>
        </div>
        <div className="matchBetRadioHeader--betRatios--ratioColumn">
            <span>+</span>
        </div>
      </div>
    </div>
  );
};

export default MatchBetRatioHeader;
