import React, { useEffect, useState } from 'react'
import Input from '../commons/CustomInput/Input'
import './style.scss'
import ButtonET from '../commons/CustomButton'
import { useDispatch, useSelector } from 'react-redux'
import { selectEmail, selectErrorsRegister, selectLastname, selectName, selectPassword, selectPasswordRepeat, selectPhone, selectPhoneCode, selectTckn, selectUsername, setPhoneCode, setRegisterErrors, setStatesEmpty, setValuesRegister } from '../../redux/slices/register'
import { emailRegex, onlyLetterFilter, onlyNumberFilter, usernameRegex } from '../../utils/regex'
import { checkEmail, checkUsername } from '../../services/register'
import SelectComponent from '../commons/CustomSelect'
import { selectActiveTab, setActiveTab } from '../../redux/slices/authSlice'
import { registerUser } from '../../services/auth'

const RegisterStep1 = () => {

    const dispatch = useDispatch();

    // const [phoneCode, setPhoneCode] = useState("+90")

    const name = useSelector(selectName)
    const lastname = useSelector(selectLastname)
    const username = useSelector(selectUsername)
    const email = useSelector(selectEmail)
    const password = useSelector(selectPassword)
    const passwordRepeat = useSelector(selectPasswordRepeat)
    const tckn = useSelector(selectTckn)
    const phone = useSelector(selectPhone)
    const errors = useSelector(selectErrorsRegister)
    const phoneCode = useSelector(selectPhoneCode)
    const activeTab = useSelector(selectActiveTab)

    const getInputsError = (type) => {
        return errors?.filter(err => err.inputType === type)[0]
    }

    const handleOnChange = (e, type) => {
        dispatch(setRegisterErrors({ inputType: type, errorText: "" }))
        if (type === "name" || type === "lastname") {
            dispatch(setValuesRegister({ inputType: type, state: e.target.value.replace(onlyLetterFilter, '') }))
        } else if (type === "phone" || type === "tckn" || type === "phone") {
            dispatch(setValuesRegister({ inputType: type, state: e.target.value.replace(onlyNumberFilter, '') }))
        } else if (type === "username") {
            dispatch(setValuesRegister({ inputType: type, state: e.target.value }))
            if ((usernameRegex).test(e.target.value) && e.target.value !== "") {
                dispatch(checkUsername(e.target.value))
            }
        } else if (type === "email") {
            dispatch(setValuesRegister({ inputType: type, state: e.target.value }))
            if ((emailRegex).test(e.target.value) && e.target.value !== "") {
                dispatch(checkEmail(e.target.value))
            }
        } else {
            dispatch(setValuesRegister({ inputType: type, state: e.target.value }))
        }
    }

    const handleOnBlur = (e, type) => {
        if (type === "password") {
            dispatch(setValuesRegister({ inputType: type, state: e.target.value }))
            if (e.target.value.length < 6) {
                dispatch(setRegisterErrors({ inputType: type, errorText: "En az 6 karakter giriniz" }))
            }
        } else if (type === "passwordRepeat") {
            if (e.target.value.length < 6) {
                dispatch(setRegisterErrors({ inputType: type, errorText: "En az 6 karakter giriniz" }))
            } else if (e.target.value !== password) {
                dispatch(setRegisterErrors({ inputType: type, errorText: "Girdiğiniz iki şifre aynı olmalı!" }))
            }
        } else if (type === "name" || type === "lastname") {
            if (e.target.value.length < 1) {
                dispatch(setRegisterErrors({ inputType: type, errorText: "En az 6 karakter giriniz" }))
            }
        } else if (type === "email") {
            if (!(emailRegex).test(e.target.value)) {
                dispatch(setRegisterErrors({ inputType: type, errorText: "Girdiğiniz e-posta geçerli değil" }))
            }
        } else if (type === "username") {
            if (!(usernameRegex).test(e.target.value)) {
                dispatch(setRegisterErrors({ inputType: type, errorText: "Sadece a-z, A-Z, 0-9 ve semboller: '.' ve '-' kullanılabilir." }))
            }
        } else if (type === "phone") {
            if (e.target.value.length !== 10) {
                dispatch(setRegisterErrors({ inputType: type, errorText: "Lütfen telefon numaranızı giriniz" }))
            }
        }
    }

    const handleOnKeyDown = (e, type) => { }

    const phoneCodes = [
        { id: 0, code: "+90" },
        { id: 1, code: "+1" },
        { id: 2, code: "+2" },
        { id: 3, code: "+3" },
    ]

    const checkIfAllInputValid = () => {
        if (
            password === passwordRepeat &&
            (!getInputsError("name").error && name !== "") &&
            (!getInputsError("lastname").error && lastname !== "") &&
            (!getInputsError("username").error && username !== "") &&
            (!getInputsError("email").error && email !== "") &&
            (!getInputsError("password").error || password.length === 6) &&
            (!getInputsError("passwordRepeat").error && passwordRepeat.length === 6) &&
            (!getInputsError("phone").error && phone.length === 10)
        ) {
            return false
        } else {
            return true
        }
    }

    useEffect(() => {
        dispatch(setPhoneCode("+90"))
    },[])

    return (
        <div className='register-popup'>

            <div className='register-popup__title'>
                <span>Üye Ol</span>
                <div>
                    <span className={'activeRegisterTab'}>Kişisel Bilgiler</span>
                    <span>Diğer Bilgiler</span>
                </div>
            </div>

            <div className='register-popup__input-wrapper'>
                <Input
                    labelText={"Adınız *"}
                    placeholder={"Adınızı Giriniz"}
                    type={"name"}
                    error={getInputsError("name").error}
                    errorText={getInputsError("name").errorText}
                    onChange={(e) => handleOnChange(e, "name")}
                    onBlur={(e) => handleOnBlur(e, "name")}
                    onKeyDown={(e) => handleOnKeyDown(e, "name")}
                    disabled={false}
                    value={name}
                />
                <Input
                    labelText={"Soyadınız *"}
                    placeholder={"Soyadınızı Giriniz"}
                    type={"lastname"}
                    error={getInputsError("lastname").error}
                    errorText={getInputsError("lastname").errorText}
                    onChange={(e) => handleOnChange(e, "lastname")}
                    onBlur={(e) => handleOnBlur(e, "lastname")}
                    onKeyDown={(e) => handleOnKeyDown(e, "lastname")}
                    disabled={false}
                    value={lastname}
                />
            </div>

            <div className='register-popup__input-wrapper'>
                <Input
                    labelText={"Kullanıcı Adınız *"}
                    placeholder={"Kullanıcı Adınızı Girin"}
                    type={"username"}
                    error={getInputsError("username").error}
                    errorText={getInputsError("username").errorText}
                    onChange={(e) => handleOnChange(e, "username")}
                    onBlur={(e) => handleOnBlur(e, "username")}
                    onKeyDown={(e) => handleOnKeyDown(e, "username")}
                    disabled={false}
                    value={username}
                />
                <Input
                    labelText={"E Mail Adresiniz *"}
                    placeholder={"eposta@alanadiniz.com"}
                    type={"email"}
                    error={getInputsError("email").error}
                    errorText={getInputsError("email").errorText}
                    onChange={(e) => handleOnChange(e, "email")}
                    onBlur={(e) => handleOnBlur(e, "email")}
                    onKeyDown={(e) => handleOnKeyDown(e, "email")}
                    disabled={false}
                    value={email}
                />
            </div>

            <div className='register-popup__input-wrapper'>
                <Input
                    labelText={"Şifreniz *"}
                    placeholder={"Şifreniz"}
                    type={"password"}
                    error={getInputsError("password").error}
                    errorText={getInputsError("password").errorText}
                    onChange={(e) => handleOnChange(e, "password")}
                    onBlur={(e) => handleOnBlur(e, "password")}
                    onKeyDown={(e) => handleOnKeyDown(e, "password")}
                    isPassword
                    disabled={false}
                    value={password}
                />
                <Input
                    labelText={"Şifrenizi Tekrar Girin *"}
                    placeholder={"Şifreniz"}
                    type={"passwordRepeat"}
                    error={getInputsError("passwordRepeat").error}
                    errorText={getInputsError("passwordRepeat").errorText}
                    onChange={(e) => handleOnChange(e, "passwordRepeat")}
                    onBlur={(e) => handleOnBlur(e, "passwordRepeat")}
                    onKeyDown={(e) => handleOnKeyDown(e, "passwordRepeat")}
                    isPassword
                    disabled={false}
                    value={passwordRepeat}
                />
            </div>

            <div className='register-popup__input-wrapper'>
                <Input
                    labelText={"Kimlik Numaranız"}
                    placeholder={"Kimlik Numaranızı Girin"}
                    type={"tckn"}
                    error={getInputsError("tckn").error}
                    errorText={getInputsError("tckn").errorText}
                    onChange={(e) => handleOnChange(e, "tckn")}
                    onBlur={(e) => handleOnBlur(e, "tckn")}
                    onKeyDown={(e) => handleOnKeyDown(e, "tckn")}
                    disabled={false}
                    value={tckn}
                // maxLength={11}
                />
                <div className='phoneInputWrapper'>
                    <SelectComponent selectedOption={phoneCode} setSelectedOption={(e) => dispatch(setPhoneCode(e.target.value))} data={phoneCodes} currencyType={'phone'} labelText />
                    <Input
                        labelText={"Telefon Numarası *"}
                        placeholder={"Phone"}
                        type={"phone"}
                        error={getInputsError("phone").error}
                        errorText={getInputsError("phone").errorText}
                        onChange={(e) => handleOnChange(e, "phone")}
                        onBlur={(e) => handleOnBlur(e, "phone")}
                        onKeyDown={(e) => handleOnKeyDown(e, "phone")}
                        disabled={false}
                        value={phone}
                        maxLength={10}
                    />
                </div>
            </div>

            <div className='register-popup__btn-wrapper'>
                <ButtonET
                    title="İçeriği Temizle"
                    btnType='secondaryBtn'
                    onClick={() => dispatch(setStatesEmpty())}
                />
                <ButtonET
                    title="Kayda Devam Et"
                    onClick={() => dispatch(setActiveTab(1))}
                disabled={checkIfAllInputValid()}
                />
            </div>
        </div>
    )
}

export default RegisterStep1