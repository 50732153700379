//React Imports
import React, { useState } from "react";

//Component Imports
import Slider from "react-slick";

//Style Imports
import "./style.scss";

//Img Imports
import allGamesIcon from "../../assets/casino-icons/allgames.svg";
import favoriteGamesIcon from "../../assets/casino-icons/favoritegames.svg";
import etorotableIcon from "../../assets/casino-icons/etorotable.svg";
import popularGamesIcon from "../../assets/casino-icons/popular.svg";
import showsIcon from "../../assets/casino-icons/shows.svg";
import rouletteIcon from "../../assets/casino-icons/roulette.svg";
import blackjackIcon from "../../assets/casino-icons/blackjack.svg";
import pokerIcon from "../../assets/casino-icons/poker.svg";

const casinoOptions = [
  { imgSrc: allGamesIcon, title: "Tüm Oyunlar" },
  { imgSrc: favoriteGamesIcon, title: "Favori Oyunlarım" },
  { imgSrc: etorotableIcon, title: "Etorobet Masaları" },
  { imgSrc: popularGamesIcon, title: "Popüler Oyunlar" },
  { imgSrc: showsIcon, title: "Gösteri Oyunları" },
  { imgSrc: rouletteIcon, title: "Rulet" },
  { imgSrc: blackjackIcon, title: "Blackjack" },
  { imgSrc: pokerIcon, title: "Poker" },
  { imgSrc: popularGamesIcon, title: "Popüler Oyunlar" },
];

const CasinoOptionBox = () => {
  const settings = {
    className: "slider variable-width",
    dots: false,
    draggable: false,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 435,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [selectedOption, setSelectedOption] = useState(0);

  return (
    <Slider {...settings}>
      {casinoOptions.map((option, index) => (
        <div
        key={option.title}
          className={`casino-option-box ${
            selectedOption == index && "selected-option"
          }`}
          onClick={() => setSelectedOption(index)}
        >
          <img src={option.imgSrc} />
          <span>{option.title}</span>
        </div>
      ))}
    </Slider>
  );
};

export default CasinoOptionBox;
