//Component Imports
import { Container } from "react-bootstrap";
import CustomButton from "../../commons/Button";
import React from "react";
import { Link } from "react-router-dom";

//SVG Imports
import logo from "../../../assets/png/logo.png";
import userIcon from "../../../assets/icons/userIcon.svg";
import menuIcon from "../../../assets/icons/menuIcon.svg";

//Style Imports
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { selectLoggedInUser, selectLoginModal } from '../../../redux/slices/authSlice';

const Header = ({
  show,
  setShow,
  showLoginModal,
  setShowLoginModal,
  showRegisterModal,
  setShowRegisterModal,
  userMenuStatus,
  setShowUserMenu,
}) => {
  const dispatch = useDispatch();
  const loggedInUser = useSelector(selectLoggedInUser);
  const loginModalStatus = useSelector(selectLoginModal);

  return (
    <>
      <div className="header headerMid">
        <Container>
          <div className="headerMid__wrapper">
            <div className="headerMid__wrapper--mobileIcons">
              <img src={menuIcon} onClick={() => setShow(!show)} />
            </div>

            <Link to="/">
              <img
                src={logo}
                style={{ width: "202px", height: "43px" }}
                alt="etorobet"
              />
            </Link>

            <div className="headerMid__wrapper--right">
              {loggedInUser?.Authenticated ? (
                <Link to="/uye-paneli" style={{color: "#fff"}}>{loggedInUser?.Username}</Link>
              ) : (
                <>
                  <CustomButton
                    textColor="#3434bc"
                    backgroundColor="#fff"
                    backgroundImage={null}
                    title="Giriş Yap"
                    fontWeight="bold"
                    fontSize="12px"
                    stateToSet={loginModalStatus}
                    setState={setShowLoginModal}
                  />
                  <CustomButton
                    textColor="#fff"
                    backgroundColor={null}
                    backgroundImage="linear-gradient(248deg, #fe6641 100%, #ffa11b -2%)"
                    title="Kayıt Ol"
                    fontWeight="bold"
                    fontSize="12px"
                    stateToSet={showRegisterModal}
                    setState={setShowRegisterModal}
                  />
                </>
              )}
            </div>

            <div className="headerMid__wrapper--mobileIcons">
              <img
                src={userIcon}
                onClick={() => {
                  setShowUserMenu(!userMenuStatus);
                }}
              />
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
