import React, { useState } from "react";
import "../style.scss";

//Img Imports
import allIcon from "../../../assets/casino-icons/allgames.svg";
import footballIcon from "../../../assets/sportsbook/football.svg";
import tenisIcon from "../../../assets/sportsbook/tenis.svg";
import basketballIcon from "../../../assets/sportsbook/basketbol.svg";
import voleybalIcon from "../../../assets/sportsbook/voleybol.svg";

const TopOptionsBar = () => {
  const [selectedOptions, setSelectedOption] = useState(0);
  const options = [
    { title: "Hepsi", icon: allIcon },
    { title: "Futbol", icon: footballIcon },
    { title: "Tenis", icon: tenisIcon },
    { title: "Basketbol", icon: basketballIcon },
    { title: "Voleybol", icon: voleybalIcon },
  ];

  return (
    <div className="allMatches--left--topOptionsBar">
      {options.map((option,index) => (
        <div key={option.title} className={`allMatches--left--topOptionsBar--item ${index == 2 && "activeOption"}`}>
            <img src={option.icon}/>
            <span>{option.title}</span>
            <div className="optionBorder"></div>
        </div>
      ))}
    </div>
  );
};

export default TopOptionsBar;
