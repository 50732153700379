import React from 'react'
import "../style.scss";

import championsLeagueLogo from "../../../assets/png/ChampionsLeague.png";

const MatchesBoxItem = () => {
  return (
    <div className="sportsbook--content--left--matchesBox--matchesBoxItem">
      <img src={championsLeagueLogo}/>
    </div>
  )
}

export default MatchesBoxItem