import { Route, Routes } from "react-router-dom";
import React, { useRef } from "react";
import Home from "./components/home";
import Profile from './components/profile'
import ProfileAyarlari from './components/profile/profileComponents/profileAyarlari/profileAyarlari';
import ChangePassword from './components/profile/profileComponents/changePassword/changePassword';
import ChangeLocale from "./components/profile/profileComponents/changeLocale/changeLocale";
import Casino from "./components/casino";
import AboutUs from './components/about-us'
import SportsBook from './components/sportsbook'
import SportsBookLive from "./components/sportsbookLive";
import AllMatches from './components/allMatches'
import Page404 from './components/page404'
import Private from "./utils/private";
import { useDispatch, useSelector } from "react-redux";
import { selectGenericPopup, selectGenericPopupText, selectGenericPopupTitle, setGenericPopup } from "./redux/slices/genericsSlice";
import Popup from "./components/commons/ModalET";
import ButtonET from "./components/commons/CustomButton";

const Router = () => {

    const dispatch = useDispatch();
    const genericPopup = useSelector(selectGenericPopup)
    const genericPopupText = useSelector(selectGenericPopupText)
    const genericPopupTitle = useSelector(selectGenericPopupTitle)
    const genericPopupRef = useRef(null);

    return (
        <>

            <Popup modalRef={genericPopupRef} isOpen={genericPopup} onClose={() => dispatch(setGenericPopup(false))}>
                <div className="loginModal loginErrorModal">
                    <div className="loginModal1--title">{genericPopupTitle}</div>
                    <div>{genericPopupText}</div>
                    <div className="loginModal--btn" style={{ marginTop: '30px', marginBottom: "0" }}>
                        <ButtonET
                            title="Kapat"
                            btnType='primaryBtn'
                            onClick={() => dispatch(setGenericPopup(false))}
                        />
                    </div>
                </div>
            </Popup>

            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/uye-paneli" element={<Private Component={Profile} />} />
                <Route path="/uye-paneli/wallet" element={<Private Component={Profile} />} />
                <Route path="/uye-paneli/wallet/deposit" element={<Private Component={Profile} />} />
                <Route path="/uye-paneli/wallet/withdraw" element={<Private Component={Profile} />} />
                <Route path="/profile-settings" element={<ProfileAyarlari />} />
                <Route path="/change-password" element={<ChangePassword />} />
                <Route path="/change-locale" element={<ChangeLocale />} />
                <Route path="/casino" element={<Casino />} />
                <Route path="/hakkimizda" element={<AboutUs />} />
                <Route path="/sportsbook" element={<SportsBook />} />
                <Route path="/sportsbook-live" element={<SportsBookLive />} />
                <Route path="/tum-maclar" element={<AllMatches />} />
                <Route path="*" element={<Page404 />} />
            </Routes>
        </>
    )
}

export default (Router);