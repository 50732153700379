import "./style.scss";
import React from "react";

const NavItem = ({title, img, iconName, handleMobileMenuClick}) => {
  return (
    <div className="navItemWrapper" onClick={() => handleMobileMenuClick(false)}>
      <div className="navItem">
        <span className={`navItemIcons navItemIcons--icon-${iconName}`}></span>
        <span>{title}</span>
      </div>
      <div className="navItem-line"></div>
    </div>
  );
};

export default NavItem;
