import React, { useState } from "react";

//Style IMports
import "./style.scss";

//Component Imports
import TabItem from "./TabItem";

const PageSideBar = ({
  tabs,
  isActiveTab,
  setActiveTab,
  setShow,
}) => {
  return (
    <div className="tabs">
      {tabs.map((tab, index) => (
        <div
        key={tab.title}
          onClick={() => {
            tab.title != "Hesap Ayarları" && setShow && setShow(false);
            setActiveTab(index);
          }}
        >
          <TabItem
            title={tab.title}
            isAccordion={tab.title == "Hesap Ayarları" ? true : false}
            setShow={setShow}
            tabIcon={tab.tabIcon}
            activeIcon={tab.activeIcon}
            otherTabs={tab.otherTabs}
            isActiveTab={index == isActiveTab ? true : false}
          />
        </div>
      ))}
    </div>
  );
};

export default PageSideBar;
