import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
    genericPopup: '',
    genericPopupText: '',
    genericPopupTitle: ''
}

export const genericsSlice = createSlice({
    name: 'generics',
    initialState,
    reducers: {
        resetGenericPopup: () => initialState,
        setGenericPopup: (state, action) => {
            state.genericPopup = action.payload
        },
        setGenericPopupText: (state, action) => {
            state.genericPopupText = action.payload
        },
        setGenericPopupTitle: (state, action) => {
            state.genericPopupTitle = action.payload
        },
        setGenericPopupInfo: (state, action) => {
            state.genericPopup = true
            state.genericPopupText = action.payload.text
            state.genericPopupTitle = action.payload.title
        },
    }
});

//Actions 
export const { setGenericPopup, setGenericPopupText, setGenericPopupTitle, setGenericPopupInfo, resetGenericPopup } = genericsSlice.actions;

///Selectors
export const selectGenericPopup = (state) => state.generics.genericPopup;
export const selectGenericPopupText = (state) => state.generics.genericPopupText;
export const selectGenericPopupTitle = (state) => state.generics.genericPopupTitle;

export default genericsSlice.reducer;