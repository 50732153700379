import React from 'react';

import "../style.scss";
import favoritedIcon from "../../../assets/sportsbook/favorited.svg";

const FavoriteBoxItem = () => {
  return (
    <div className='sportsbook-live-favoriteItem'>
        <div className='sportsbook-live-favoriteItem--iconBox'>
            <img src={favoritedIcon}/>
        </div>
        <div className='sportsbook-live-favoriteItem--matchInfo'>
            <span>Fransa</span>
            <span>Türkiye</span>
            <span>45' Devre Arası</span>
        </div>
        <div className='sportsbook-live-favoriteItem--scores'>
            <div className='sportsbook-live-favoriteItem--scores--scoreBox'>
                <span>1</span>
            </div>
            <div className='sportsbook-live-favoriteItem--scores--scoreBox'>
                <span>3</span>
            </div>
        </div>
    </div>
  )
}

export default FavoriteBoxItem