import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authSlice from "../slices/authSlice";
import profileSlice from "../slices/profileSlice";
import paymentSlice from "../slices/paymentSlice";
import paymentSliceWithdraw from "../slices/paymentSliceWithdraw";
import gamesSlice from "../slices/gamesSlice";
import headerSlice from "../slices/headerSlice";
import registerSlice from "../slices/register";
import genericsSlice from "../slices/genericsSlice";

const appReducer = combineReducers({
    auth: authSlice,
    payment: paymentSlice,
    profile: profileSlice,
    games: gamesSlice,
    header: headerSlice,
    register: registerSlice,
    generics: genericsSlice,
    paymentWithdraw: paymentSliceWithdraw
})

const rootReducer = (state, action) => {
    return appReducer(state, action);
};

export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        })
})