import React from "react";
import CustomButton from "../../../commons/Button";

//Style Imports
import "./style.scss";

const BetCard = ({ team1Img, team2Img }) => {
  return (
    <div className="betCard">
      <div className="betCard--top">
        <div className="betCard--top--left">
          <span>Futbol, İspanya, La Liga</span>
          <span>13.01.2022 Perşembe, 22:45</span>
        </div>
        <CustomButton
          title={"Oyna"}
          backgroundColor={null}
          textColor={"#fff"}
          backgroundImage={"linear-gradient(84deg, #1631ba 0%, #863cc4 100%)"}
          fontWeight={"500"}
          width={"78.9px"}
          height={"34.2px"}
          fontSize={"12px"}
        />
      </div>
      <div className="betCard--mid">
        <img src={team1Img} />
        <div className="betCard--mid--teams-info">
          <span>Atletico Madrid</span>
          <span></span>
          <span>Athletic Bilbao</span>
        </div>
        <img src={team2Img} />
      </div>
      <div className="betCard--bot">
        <div className="betCard--bot--bet-ratio-box">
          <span>1</span>
          <span></span>
          <span>2.08</span>
        </div>
        <div className="betCard--bot--bet-ratio-box">
          <span>0</span>
          <span></span>
          <span>2.08</span>
        </div>
        <div className="betCard--bot--bet-ratio-box">
          <span>2</span>
          <span></span>
          <span>2.08</span>
        </div>
      </div>
    </div>
  );
};

export default BetCard;
