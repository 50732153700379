import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Page404 = () => {
  return (
    <Container>
        <div>404</div>
        <span>Return <Link to="/">Home Page</Link></span>
    </Container>
  )
}

export default Page404