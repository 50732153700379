import { setBanners, setBonuses, setCasino, setGameUrl, setIframeModal, setLiveCasino, setSummary } from "../redux/slices/gamesSlice";
import { Bonuses, Services } from '../utils/httphelper'

export const getBanners = () => async (dispatch) => {
    const getBannersResult = await Services.getBanners()

    dispatch(setBanners(getBannersResult))
}
export const getSummary = () => async (dispatch) => {
    const getSummaryResult = await Services.getSummary()

    dispatch(setSummary(getSummaryResult))
}
// export const getBonuses = () => async (dispatch) => {
//     const getBonusesResult = await Bonuses.getBonuses()
//     // console.log('getBonusesResult',getBonusesResult)
//     dispatch(setBonuses(getBonusesResult))
// }
export const getCasino = (size) => async (dispatch) => {
    const getCasinoResult = await Services.getCasino(size);

    dispatch(setCasino(getCasinoResult.data))
}
export const getLiveCasino = (size) => async (dispatch) => {
    const getLiveCasinoResult = await Services.getLiveCasino(size)

    dispatch(setLiveCasino(getLiveCasinoResult.data))
}

export const launchGame = (id, gameCategory, tableId, localGameId, localGameType, isLive, provider, whichVersion, isMobile) => async (dispatch) => {
    if (whichVersion === "v1") {
        const launchGameResult = await Services.launchGameV1(id, gameCategory, tableId, localGameId, localGameType, isLive, provider, isMobile);
        if (launchGameResult?.data?.url) {
            dispatch(setGameUrl(launchGameResult?.data?.url))
            dispatch(setIframeModal(true))
        }
    } else {
        const launchGameResult = await Services.launchGame(id, gameCategory, tableId, localGameId, localGameType, isLive, provider, isMobile);
        console.log('launchGameResult', launchGameResult)
        if (launchGameResult?.data?.url) {
            dispatch(setGameUrl(launchGameResult?.data?.url))
            dispatch(setIframeModal(true))
        }
    }
}