import React, { useState } from 'react'
import ButtonET from '../commons/CustomButton'
import { useDispatch, useSelector } from 'react-redux'
import { selectCodeVerification, selectErrorsRegister, selectUsernameVerification, setRegisterErrors, setStatesEmpty, setValuesRegister } from '../../redux/slices/register'
import './style.scss'
import Input from '../commons/CustomInput/Input'
import { onlyNumberFilter } from '../../utils/regex'
import { confirmation } from '../../services/auth'
import { setActiveTab } from '../../redux/slices/authSlice'
import { selectIsLoading } from '../../redux/slices/paymentSlice'

const RegisterStep2 = () => {
    const dispatch = useDispatch();

    const username = useSelector(selectUsernameVerification)
    const code = useSelector(selectCodeVerification)
    const errors = useSelector(selectErrorsRegister)
    const isLoading = useSelector(selectIsLoading)

    const handleOnChange = (e, type) => {
        dispatch(setRegisterErrors({ inputType: type, errorText: "" }))
        if (type === "codeUserVerification") {
            dispatch(setValuesRegister({ inputType: type, state: e.target.value.replace(onlyNumberFilter, '') }))
        } else {
            dispatch(setValuesRegister({ inputType: type, state: e.target.value }))
        }
    }

    const handleOnBlur = (e, type) => {
        if (type === "usernameVerification" && e.target.value.length < 6) {
            dispatch(setRegisterErrors({ inputType: type, errorText: "Lütfen kullanıcı adı giriniz" }))
        } else if (type === "codeUserVerification" && e.target.value.length === 0) {
            dispatch(setRegisterErrors({ inputType: type, errorText: "Lütfen doğrulama kodu giriniz" }))
        }
    }
    const handleOnKeyDown = (e, type) => {
        if (username.length === 6 && code !== "") {
            handleConfirmationClick()
        }
    }

    const getInputsError = (type) => {
        return errors?.filter(err => err.inputType === type)[0]
    }

    const handleConfirmationClick = () => {
        dispatch(confirmation({ Username: username, ConfirmationCode: code }));
    };

    const checkIfAllInputValid = () => {
        if (username.length >= 6 && code !== "") {
            return false
        } else {
            return true
        }
    }

    return (
        <div className='register-popup'>

            <div className='register-popup__title'>
                <span>Hesabını Doğrula</span>
            </div>

            <div className='register-popup__input-wrapper'>
                <Input
                    labelText={"Adınız *"}
                    placeholder={"Adınızı Giriniz"}
                    type={"usernameVerification"}
                    error={getInputsError("usernameVerification").error}
                    errorText={getInputsError("usernameVerification").errorText}
                    onChange={(e) => handleOnChange(e, "usernameVerification")}
                    onBlur={(e) => handleOnBlur(e, "usernameVerification")}
                    onKeyDown={(e) => handleOnKeyDown(e, "usernameVerification")}
                    disabled={false}
                    value={username}
                />
                <Input
                    labelText={"Doğrulama Kodu *"}
                    placeholder={"Doğrulama Kodu Giriniz"}
                    type={"codeUserVerification"}
                    error={getInputsError("codeUserVerification").error}
                    errorText={getInputsError("codeUserVerification").errorText}
                    onChange={(e) => handleOnChange(e, "codeUserVerification")}
                    onBlur={(e) => handleOnBlur(e, "codeUserVerification")}
                    onKeyDown={(e) => handleOnKeyDown(e, "codeUserVerification")}
                    disabled={false}
                    value={code}
                />
            </div>

            <div className='register-popup__btn-wrapper'>
                <ButtonET
                    title="Geri"
                    btnType='secondaryBtn'
                    onClick={() => dispatch(setActiveTab(1))}
                />
                <ButtonET
                    title="Kaydı Tamamla"
                    isLoadingIndicator={isLoading}
                    onClick={() => handleConfirmationClick()}
                    disabled={checkIfAllInputValid()}
                />
            </div>
        </div>
    )
}

export default RegisterStep2