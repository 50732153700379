import React from "react";
import { Container } from "react-bootstrap";

import "./style.scss";

//Img Imports
import searchIcon from "../../assets/casino-icons/search.svg";
import BetsBox from "../sportsbook/components/BetsBox";
import HighestProfits from "../sportsbook/components/HighestProfits";
import FavoritesBox from "./components/FavoritesBox";
import LiveBetsBox from "./components/LiveBetsBox";
import CustomButton from "../commons/Button";
import allMatchesIcon from "../../assets/casino-icons/allgames.svg";
import CustomAccordion from "../commons/Accordion";
import footballIcon from "../../assets/sportsbook/football.svg";

const SportsBookLive = () => {
  return (
    <Container>
      <div className="sportsBook-live">
        <div className="sportsBook-live--left">
          <div className="sportsBook-live--left--searchBox">
            <input placeholder="Karşılaşma ara" />
            <img src={searchIcon} />
          </div>
          <FavoritesBox />
          <LiveBetsBox />
        </div>

        <div className="sportsBook-live--mid">
          <div className="sportsBook-live--mid--topBtns">
            <CustomButton
              title={"Tekli Maç Detayı"}
              backgroundColor={null}
              textColor={"#fff"}
              backgroundImage={"linear-gradient(74deg, #863cc4 0%, #1631ba 100%)"}
              fontWeight={"normal"}
              fontSize={"14px"}
              width={"174px"}
              height={"50px"}
            />
            <CustomButton
              title={"Tüm Maçlar"}
              backgroundColor={"#333645"}
              textColor={"#fff"}
              backgroundImage={null}
              fontWeight={"normal"}
              fontSize={"14px"}
              width={"174px"}
              height={"50px"}
              imgSrc={allMatchesIcon}
            />
          </div>
          <div className="sportsBook-accordions">
            <CustomAccordion isMatchInfo accordionItem={{ event: "Futbol, İngiltere, Premier Lig", eventIcon: footballIcon }} />
          </div>
          <div className="sportsBook-live--mid--sortOptions">
            <CustomButton
              title={"Tüm Bahisler"}
              backgroundColor={"#333645"}
              textColor={"#fff"}
              backgroundImage={"linear-gradient(74deg, #863cc4 0%, #1631ba 100%)"}
              fontWeight={"normal"}
              fontSize={"14px"}
              width={"150px"}
              height={"40px"}
            />
            <CustomButton
              title={"Gol Bahisleri"}
              backgroundColor={"#333645"}
              textColor={"#666874"}
              backgroundImage={null}
              fontWeight={"normal"}
              fontSize={"14px"}
              width={"150px"}
              height={"40px"}
            />
            <CustomButton
              title={"Sonuç"}
              backgroundColor={"#333645"}
              textColor={"#666874"}
              backgroundImage={null}
              fontWeight={"normal"}
              fontSize={"14px"}
              width={"150px"}
              height={"40px"}
            />
            <CustomButton
              title={"Handikap"}
              backgroundColor={"#333645"}
              textColor={"#666874"}
              backgroundImage={null}
              fontWeight={"normal"}
              fontSize={"14px"}
              width={"150px"}
              height={"40px"}
            />
          </div>

          <div className="sportsBook-live--mid--optionsAccordions">
            <div className="sportsBook-accordions sportsBook-accordions-black">
              <CustomAccordion accordionType="1" accordionItem={{ event: "Maç Sonucu" }} />
            </div>
            <div className="sportsBook-accordions sportsBook-accordions-black">
              <CustomAccordion accordionType="2" accordionItem={{ event: "Handikap" }} />
            </div>
            <div className="sportsBook-accordions sportsBook-accordions-black">
              <CustomAccordion accordionType="3" accordionItem={{ event: "Maç Sonucu" }} />
            </div>
            <div className="sportsBook-accordions sportsBook-accordions-black">
              <CustomAccordion accordionType="4" accordionItem={{ event: "Ev Sahibi Takım Alt/Üst" }} />
            </div>
            <div className="sportsBook-accordions sportsBook-accordions-black">
              <CustomAccordion accordionType="4" accordionItem={{ event: "Deplasman Takım Alt/Üst" }} />
            </div>
          </div>

        </div>

        <div className="sportsBook-live--right">
          <div className="sportsBook-live--right--topTabsBox">
            <div className="sportsBook-live--right--topTabsBox--tab sportsBookActiveTab">
              <span>Kuponum</span>
              <div>0</div>
            </div>
            <div className="sportsBook-live--right--topTabsBox--tab">
              <span>Bahislerim</span>
              <div>0</div>
            </div>
          </div>
          <BetsBox />
          <HighestProfits />
        </div>
      </div>
    </Container>
  );
};

export default SportsBookLive;
