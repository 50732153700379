//Component Imports
import { Container } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";

//SVG Imports
import liveSupportIcon from "../../../assets/icons/liveSupport.svg";
import tvIcon from "../../../assets/icons/tv.svg";
import trFlag1 from "../../../assets/icons/tr_flag_1.svg";

//Style Imports
import './styles.scss';

const TopHeader = () => {

  return (
    <div className="header headerTop">
      <Container>
        <div className="headerTop__wrapper">
          <div className="headerTop__wrapper__left">
            <div className="headerBtn"><Link to="/hakkimizda">Hakkımızda</Link></div>
            <div className="headerBtn">Bayilik</div>
            <div className="headerBtn">Nasıl Yatırım Yapılır ?</div>
          </div>

          <div className="headerTop__wrapper__right">
            <div className="headerBtn">
              {" "}
              <img src={liveSupportIcon} alt="Canlı Destek" /> Canlı Destek{" "}
            </div>
            <div className="headerBtn">
              {" "}
              <img src={tvIcon} alt="EtoroTV" /> EtoroTV{" "}
            </div>
            <div className="headerBtn">
              {" "}
              <img src={trFlag1} alt="flag"/>{" "}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TopHeader;
