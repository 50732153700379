import React from "react";

import "../style.scss";
import SportHeader from "./MatchItem/SportHeader";

import footballIcon from "../../../assets/sportsbook/football.svg";
import basketballIcon from "../../../assets/sportsbook/basketbol.svg";
import tenisIcon from "../../../assets/sportsbook/tenis.svg";
import MatchItem from "./MatchItem";

const SportsAndMatches = () => {
  return (
    <div className="allMatches--left--sportsAndMatches">
      <MatchItem
        icon={footballIcon}
        title={"Futbol"}
        columnType={"ColumnFootBall"}
      />

      <MatchItem
        icon={tenisIcon}
        title={"Tenis"}
        columnType={"ColumnTennis"}
      />

      <MatchItem
        icon={basketballIcon}
        title={"Basketbol"}
        columnType={"ColumnBasketBall"}
      />
    </div>
  );
};

export default SportsAndMatches;
