import React from 'react';
import './style.scss'
import Popup from '../../commons/ModalET';
import WithdrawModal1 from './WithdrawModal1';
import {useSelector} from "react-redux";
import {selectProgressIndex} from "../../../redux/slices/paymentSliceWithdraw";
import WithdrawModal2 from "./WithdrawModal2";

const WithdrawModal = ({ isOpen, onClose, logo, title, modalRef }) => {

    const progressIndex = useSelector(selectProgressIndex);

    return (
        <Popup modalRef={modalRef} isOpen={isOpen} onClose={onClose}>
            <div className="withdrawModal">
                <div className="withdrawModal--top">
                    <span className="withdrawModal--top--title">Para Yatır</span>
                    <img src={logo} alt={'icon'} style={{ maxWidth: '130px' }} />
                </div>

                {
                    progressIndex === 0 ? <WithdrawModal1 /> :
                    progressIndex === 1 ? <WithdrawModal2 /> : <></>
                }

            </div>
        </Popup>
    )
}

export default WithdrawModal