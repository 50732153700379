import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    paymentMethods: null,
    withdrawModal: false,
    bonuses: [],
    progressIndex: 0,
    amount: 0,
    selectedPaymentMethod: null,
    paymentMethodCurrency: "Seçiniz",
    isLoading: false,
    bonusForSinglePaymentMethod: null,
    redirectBtnUrlForPayment: "",
    bankList: [],
    selectedBankCode: "Seçiniz",
    selectedBankName: "Seçiniz",
    cryptoPayType: "Seçiniz",
    isPromotionCode: false,
    promotionCodeStatus: "0",
    withdrawErrorText: "",
    walletAddress: "",
    withdrawErrorPopup: false,
    isBankTransfer: false,
    isThorPay: false,
    isCryptoPay: false,
    isPaparaHizli: false,
    isBankTransferCepBank: false,
    branchNo: "",
    accountNo: "",
    tckn: "",
    fullName: "",
    iban: "",
    cepBankPhoneNumber: "",
    cepBankPhoneNumber2: "",
    reference: "",
    expireDate: "",
    birthdate: "",
    citizenNo: "",
    etoroCryptoPaymentType: "",
    payfixWalletId: "",
    cartNo: "",
    cvv: "",
    isPageLoading: false,
    creditCardDate: "",
    paparaAccountNumber: "",
    friendID: "",
    paymentType: '0',
    bankAccountNo: "",
    relativeTckn: "",
    errors: [
        {inputType: 'withdrawAmount', error: false, errorText: ""},
        {inputType: 'branchNo', error: false, errorText: ""},
        {inputType: 'accountNo', error: false, errorText: ""},
        {inputType: 'tckn', error: false, errorText: ""},
        {inputType: 'fullName', error: false, errorText: ""},
        {inputType: 'iban', error: false, errorText: ""},
        {inputType: 'phoneNumber', error: false, errorText: ""},
        {inputType: 'phoneNumber1', error: false, errorText: ""},
        {inputType: 'reference', error: false, errorText: ""},
        {inputType: 'expireDate', error: false, errorText: ""},
        {inputType: 'birthdate', error: false, errorText: ""},
        {inputType: 'citizenNo', error: false, errorText: ""},
        {inputType: 'cartNo', error: false, errorText: ""},
        {inputType: 'creditCardDate', error: false, errorText: ""},
        {inputType: 'walletAddress', error: false, errorText: ""},
        {inputType: 'cvv', error: false, errorText: ""},
        {inputType: 'paparaAccountNumber', error: false, errorText: ""},
        {inputType: 'payfixWalletId', error: false, errorText: ""},
        {inputType: 'friendID', error: false, errorText: ""},
        {inputType: 'bankAccountNo', error: false, errorText: ""},
        {inputType: 'relativeTckn', error: false, errorText: ""},
    ]
};

export const paymentSliceWithdraw = createSlice({
    name: "paymentWithdraw",
    initialState,
    reducers: {
        setPaymentMethodsWithdraw: (state, action) => {
            state.paymentMethods = action.payload;
        },
        setIsBankTransfer: (state, action) => {
            state.isBankTransfer = action.payload;
        },
        setPaymentType: (state, action) => {
            state.paymentType = action.payload;
        },
        setIsPageLoading: (state, action) => {
            state.isPageLoading = action.payload;
        },
        setIsThorPay: (state, action) => {
            state.isThorPay = action.payload;
        },
        setIsCryptoPay: (state, action) => {
            state.isCryptoPay = action.payload;
        },
        setIsFxCuzdan: (state, action) => {
            state.isFxCuzdan = action.payload;
        },
        setIsVevoPay: (state, action) => {
            state.isVevoPay = action.payload;
        },
        setIsPaparaHizli: (state, action) => {
            state.isPaparaHizli = action.payload;
        },
        setIsQr: (state, action) => {
            state.isQR = action.payload;
        },
        setIsBankTransferCepBank: (state, action) => {
            state.isBankTransferCepBank = action.payload;
        },
        setWithdrawErrorPopup: (state, action) => {
            state.withdrawErrorPopup = action.payload;
        },
        setWithdrawErrorText: (state, action) => {
            state.withdrawErrorText = action.payload;
        },
        setProgressIndex: (state, action) => {
            state.progressIndex = action.payload;
        },
        setAmount: (state, action) => {
            state.amount = action.payload;
        },
        setSelectedPaymentMethod: (state, action) => {
            state.selectedPaymentMethod = action.payload;
        },
        setWithdrawModal: (state, action) => {
            state.withdrawModal = action.payload;
        },
        setBonusesWithdraw: (state, action) => {
            state.bonuses = action.payload;
        },
        setPaymentMethodsCurrency: (state, action) => {
            state.paymentMethodCurrency = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setBonusForSinglePaymentMethod: (state, action) => {
            state.bonusForSinglePaymentMethod = action.payload;
        },
        setRedirectBtnUrlForPayment: (state, action) => {
            state.redirectBtnUrlForPayment = action.payload;
        },
        setBankList: (state, action) => {
            state.bankList = action.payload;
        },
        setSelectedBankCode: (state, action) => {
            state.selectedBankCode = action.payload;
        },
        setSelectedBankName: (state, action) => {
            state.selectedBankName = action.payload;
        },
        setSelectedCryptoPayType: (state, action) => {
            state.cryptoPayType = action.payload;
        },
        setIsPromotionCode: (state, action) => {
            state.isPromotionCode = action.payload;
        },
        setPromotionCodeStatus: (state, action) => {
            state.promotionCodeStatus = action.payload;
        },
        setEtoroCryptoPaymentType: (state, action) => {
            state.etoroCryptoPaymentType = action.payload;
        },
        setIsFixTurka: (state, action) => {
            state.isFixTurka = action.payload;
        },
        setPaymentStateErrors: (state, action) => {
            const {inputType, errorText} = action.payload;
            const errorToUpdate = state.errors.find(
                (error) => error.inputType === inputType
            );
            if (errorToUpdate) {
                errorToUpdate.error = errorText ? true : false;
                errorToUpdate.errorText = errorText;
            }
        },
        setPaymentValues: (state, action) => {
            switch (action.payload.inputType) {
                case "withdrawAmount":
                    state.amount = action.payload.state
                    break;
                case "branchNo":
                    state.branchNo = action.payload.state
                    break;
                case "accountNo":
                    state.accountNo = action.payload.state
                    break;
                case "tckn":
                    state.tckn = action.payload.state
                    break;
                case "fullName":
                    state.fullName = action.payload.state
                    break;
                case "iban":
                    state.iban = action.payload.state
                    break;
                case "phoneNumber":
                    state.cepBankPhoneNumber = action.payload.state
                    break;
                case "phoneNumber1":
                    state.cepBankPhoneNumber2 = action.payload.state
                    break;
                case "reference":
                    state.reference = action.payload.state
                    break;
                case "expireDate":
                    state.expireDate = action.payload.state
                    break;
                case "birthdate":
                    state.birthdate = action.payload.state
                    break;
                case "citizenNo":
                    state.citizenNo = action.payload.state
                    break;
                case "cartNo":
                    state.cartNo = action.payload.state
                    break;
                case "creditCardDate":
                    state.creditCardDate = action.payload.state
                    break;
                case "cvv":
                    state.cvv = action.payload.state
                    break;
                case "walletAddress":
                    state.walletAddress = action.payload.state
                    break;
                case "paparaAccountNumber":
                    state.paparaAccountNumber = action.payload.state
                    break;
                case "payfixWalletId":
                    state.payfixWalletId = action.payload.state
                    break;
                case "friendID":
                    state.friendID = action.payload.state
                    break;
                case "bankAccountNo":
                    state.bankAccountNo = action.payload.state
                    break;
                case "relativeTckn":
                    state.relativeTckn = action.payload.state
                    break;
                default:
                    break;
            }
        },
        resetValues: (state) => {
            state.bonuses = []
            state.progressIndex = 0
            state.amount = 0
            state.isLoading = false
            state.isVevoPay = false
            state.bonusForSinglePaymentMethod = null
            state.redirectBtnUrlForPayment = ""
            state.bankList = []
            state.selectedBankCode = "Seçiniz"
            state.selectedBankName = "Seçiniz"
            state.cryptoPayType = "Seçiniz"
            state.isCryptoPay = false
            state.isPromotionCode = false
            state.promotionCodeStatus = "0"
            state.withdrawErrorText = ""
            state.withdrawErrorPopup = false
            state.isBankTransfer = false
            state.isPaparaHizli = false
            state.isFxCuzdan = false
            state.isFixTurka = false
            state.isQR = false
            state.branchNo = ""
            state.accountNo = ""
            state.tckn = ""
            state.fullName = ""
            state.iban = ""
            state.cepBankPhoneNumber = ""
            state.cepBankPhoneNumber2 = ""
            state.reference = ""
            state.expireDate = ""
            state.birthdate = ""
            state.citizenNo = ""
            state.walletAddress = ""
            state.etoroCryptoPaymentType = ""
            state.cartNo = ""
            state.creditCardDate = ""
            state.cvv = ""
            state.paymentType = '0'
            state.bankAccountNo = ""
            state.relativeTckn = ""
            state.errors = [
                {inputType: 'withdrawAmount', error: false, errorText: ""},
                {inputType: 'branchNo', error: false, errorText: ""},
                {inputType: 'accountNo', error: false, errorText: ""},
                {inputType: 'tckn', error: false, errorText: ""},
                {inputType: 'fullName', error: false, errorText: ""},
                {inputType: 'iban', error: false, errorText: ""},
                {inputType: 'phoneNumber', error: false, errorText: ""},
                {inputType: 'phoneNumber1', error: false, errorText: ""},
                {inputType: 'reference', error: false, errorText: ""},
                {inputType: 'expireDate', error: false, errorText: ""},
                {inputType: 'birthdate', error: false, errorText: ""},
                {inputType: 'citizenNo', error: false, errorText: ""},
                {inputType: 'cartNo', error: false, errorText: ""},
                {inputType: 'creditCardDate', error: false, errorText: ""},
                {inputType: 'walletAddress', error: false, errorText: ""},
                {inputType: 'cvv', error: false, errorText: ""},
                {inputType: 'paparaAccountNumber', error: false, errorText: ""},
                {inputType: 'payfixWalletId', error: false, errorText: ""},
                {inputType: 'friendID', error: false, errorText: ""},
                {inputType: 'bankAccountNo', error: false, errorText: ""},
                {inputType: 'relativeTckn', error: false, errorText: ""}
            ]
        }
    },
});

//Actions
export const {
    setPaymentMethodsWithdraw,
    setSelectedCryptoPayType,
    setSelectedBankName,
    setIsBankTransferCepBank,
    resetValues,
    setIsBankTransfer,
    setWithdrawErrorText,
    setPaymentStateErrors,
    setPaymentValues,
    setWithdrawErrorPopup,
    setWithdrawModal,
    setBankList,
    setSelectedBankCode,
    setBonusesWithdraw,
    setAmount,
    setProgressIndex,
    setSelectedPaymentMethod,
    setPaymentMethodsCurrency,
    setIsLoading,
    setPaymentType
} = paymentSliceWithdraw.actions;

///Selectors
export const selectPaymentMethods = (state) => state.paymentWithdraw.paymentMethods;
export const selectWithdrawModal = (state) => state.paymentWithdraw.withdrawModal;
export const selectBonuses = (state) => state.paymentWithdraw.bonuses;
export const selectProgressIndex = (state) => state.paymentWithdraw.progressIndex;
export const selectAmount = (state) => state.paymentWithdraw.amount;
export const selectSelectedPaymentMethod = (state) => state.paymentWithdraw.selectedPaymentMethod;
export const selectPaymentMethodCurrency = (state) => state.paymentWithdraw.paymentMethodCurrency;
export const selectIsLoading = (state) => state.paymentWithdraw.isLoading;
export const selectBankList = (state) => state.paymentWithdraw.bankList;
export const selectWithdrawErrorText = (state) => state.paymentWithdraw.withdrawErrorText;
export const selectWithdrawErrorPopup = (state) => state.paymentWithdraw.withdrawErrorPopup;
export const selectPaymentStateErrors = (state) => state.paymentWithdraw.errors;
export const selectIsCryptoPay = (state) => state.paymentWithdraw.isCryptoPay;
export const selectCryptoPayType = (state) => state.paymentWithdraw.cryptoPayType;
export const selectIsVevoPay = (state) => state.paymentWithdraw.isVevoPay
export const selectPaymentWithdrawValues = (state) => {
    return {
        amount: state.paymentWithdraw.amount,
        branchNo: state.paymentWithdraw.branchNo,
        accountNo: state.paymentWithdraw.accountNo,
        tckn: state.paymentWithdraw.tckn,
        fullName: state.paymentWithdraw.fullName,
        iban: state.paymentWithdraw.iban,
        currency: state.paymentWithdraw.paymentMethodCurrency,
        cepBankPhoneNumber: state.paymentWithdraw.cepBankPhoneNumber,
        cepBankPhoneNumber2: state.paymentWithdraw.cepBankPhoneNumber2,
        reference: state.paymentWithdraw.reference,
        expireDate: state.paymentWithdraw.expireDate,
        birthdate: state.paymentWithdraw.birthdate,
        citizenNo: state.paymentWithdraw.citizenNo,
        cartNo: state.paymentWithdraw.cartNo,
        creditCardDate: state.paymentWithdraw.creditCardDate,
        cvv: state.paymentWithdraw.cvv,
        paparaAccountNumber: state.paymentWithdraw.paparaAccountNumber,
        walletAddress: state.paymentWithdraw.walletAddress,
        selectedBankName: state.paymentWithdraw.selectedBankName,
        selectedBankCode: state.paymentWithdraw.selectedBankCode,
        payfixWalletId: state.paymentWithdraw.payfixWalletId,
        paymentType: state.paymentWithdraw.paymentType,
        friendID: state.paymentWithdraw.friendID,
        bankAccountNo: state.paymentWithdraw.bankAccountNo,
        relativeTckn: state.paymentWithdraw.relativeTckn
    }
}

export default paymentSliceWithdraw.reducer;