import React, { useEffect, useState } from "react";
import { Container, Dropdown, DropdownButton } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import * as yup from "yup";
import InputBoxArea from "../../../commons/CustomModal/InputBoxArea";
import CustomButton from "../../../commons/Button";
import { selectProfile } from "../../../../redux/slices/profileSlice";
import { changeLocale } from "../../../../services/auth";
import { getProfile } from "../../../../services/profile";

const ChangeLocale = () => {

    const dispatch = useDispatch();
    const [lang, setLang] = useState('tr');
    const locale = useSelector(selectProfile)?.data?.UserAttributes?.filter(att => att?.Name === "locale")[0]?.Value;

    const handleUpdateChangeLocale = () => {
        dispatch(changeLocale(lang));
    }

    useEffect(() => {
        dispatch(getProfile());
    }, [])

    useEffect(() => {
        setLang(locale)
    },[locale])

    return (
        <Container>
            <div className="profileSettings-wrapper">
                <span className="profileSettings-wrapper--header">Dil Ayarları</span>

                <div className="profileSettings-wrapper--dropdown-wrapper">
                    <DropdownButton id="dropdown-item-button" title={lang === "tr" ? "TR" : "EN"} onSelect={(e) => setLang(e)}>
                        <Dropdown.Item eventKey="tr">TR</Dropdown.Item>
                        <Dropdown.Item eventKey="en">EN</Dropdown.Item>
                    </DropdownButton>
                </div>

                <div className="loginModal--btn">
                    <CustomButton
                        title="Güncelle"
                        backgroundColor={null}
                        textColor={"#fff"}
                        backgroundImage={"linear-gradient(to right, #f19524 0%, #fb713a 100%)"}
                        fontWeight={"normal"}
                        // disabled={}
                        fontSize={"14px"}
                        width={"300px"}
                        onClick={() => handleUpdateChangeLocale()}
                    />
                </div>

            </div>
        </Container>
    );
};

export default ChangeLocale;
