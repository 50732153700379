import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    paymentMethods: null,
    withdrawModal: false,
    depositModal: false,
    bonuses: [],
    progressIndex: 0,
    amount: 0,
    selectedPaymentMethod: null,
    paymentMethodCurrency: "Seçiniz",
    isLoading: false,
    bonusForSinglePaymentMethod: null,
    redirectBtnUrlForPayment: "",
    bankList: [],
    selectedBankCode: "Seçiniz",
    selectedBankName: "Seçiniz",
    cryptoPayType: "Seçiniz",
    isPromotionCode: false,
    promotionCodeStatus: "0",
    depositErrorText: "",
    depositErrorPopup: false,
    isBankTransfer: false,
    isThorPay: false,
    isCryptoPay: false,
    isPaparaHizli: false,
    isBankTransferCepBank: false,
    branchNo: "",
    accountNo: "",
    tckn: "",
    fullName: "",
    iban: "",
    cepBankPhoneNumber: "",
    cepBankPhoneNumber2: "",
    reference: "",
    expireDate: "",
    birthdate: "",
    citizenNo: "",
    etoroCryptoPaymentType: "",
    cartNo: "",
    creditCardDate: "",
    cvv: "",
    isPageLoading: false,
    errors: [
        { inputType: 'depositAmount', error: false, errorText: "" },
        { inputType: 'branchNo', error: false, errorText: "" },
        { inputType: 'accountNo', error: false, errorText: "" },
        { inputType: 'tckn', error: false, errorText: "" },
        { inputType: 'fullName', error: false, errorText: "" },
        { inputType: 'iban', error: false, errorText: "" },
        { inputType: 'phoneNumber', error: false, errorText: "" },
        { inputType: 'phoneNumber1', error: false, errorText: "" },
        { inputType: 'reference', error: false, errorText: "" },
        { inputType: 'expireDate', error: false, errorText: "" },
        { inputType: 'birthdate', error: false, errorText: "" },
        { inputType: 'citizenNo', error: false, errorText: "" },
        { inputType: 'cartNo', error: false, errorText: "" },
        { inputType: 'creditCardDate', error: false, errorText: "" },
        { inputType: 'cvv', error: false, errorText: "" },
    ]
};

export const paymentSlice = createSlice({
    name: "payment",
    initialState,
    reducers: {
        setPaymentMethods: (state, action) => {
            state.paymentMethods = action.payload;
        },
        setIsBankTransfer: (state, action) => {
            state.isBankTransfer = action.payload;
        },
        setIsPageLoading: (state, action) => {
            state.isPageLoading = action.payload;
        },
        setIsThorPay: (state, action) => {
            state.isThorPay = action.payload;
        },
        setIsCryptoPay: (state, action) => {
            state.isCryptoPay = action.payload;
        },
        setIsPaparaHizli: (state, action) => {
            state.isPaparaHizli = action.payload;
        },
        setIsBankTransferCepBank: (state, action) => {
            state.isBankTransferCepBank = action.payload;
        },
        setDepositErrorPopup: (state, action) => {
            state.depositErrorPopup = action.payload;
        },
        setDepositErrorText: (state, action) => {
            state.depositErrorText = action.payload;
        },
        setProgressIndex: (state, action) => {
            state.progressIndex = action.payload;
        },
        setAmount: (state, action) => {
            state.amount = action.payload;
        },
        setSelectedPaymentMethod: (state, action) => {
            state.selectedPaymentMethod = action.payload;
        },
        setWithdrawModal: (state, action) => {
            state.withdrawModal = action.payload;
        },
        setDepositModal: (state, action) => {
            state.depositModal = action.payload;
        },
        setBonuses: (state, action) => {
            state.bonuses = action.payload;
        },
        setPaymentMethodsCurrency: (state, action) => {
            state.paymentMethodCurrency = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setBonusForSinglePaymentMethod: (state, action) => {
            state.bonusForSinglePaymentMethod = action.payload;
        },
        setRedirectBtnUrlForPayment: (state, action) => {
            state.redirectBtnUrlForPayment = action.payload;
        },
        setBankList: (state, action) => {
            state.bankList = action.payload;
        },
        setSelectedBankCode: (state, action) => {
            state.selectedBankCode = action.payload;
        },
        setSelectedBankName: (state, action) => {
            state.selectedBankName = action.payload;
        },
        setSelectedCryptoPayType: (state, action) => {
            state.cryptoPayType = action.payload;
        },
        setIsPromotionCode: (state, action) => {
            state.isPromotionCode = action.payload;
        },
        setPromotionCodeStatus: (state, action) => {
            state.promotionCodeStatus = action.payload;
        },
        setEtoroCryptoPaymentType: (state, action) => {
            state.etoroCryptoPaymentType = action.payload;
        },
        setPaymentStateErrors: (state, action) => {
            const { inputType, errorText } = action.payload;
            const errorToUpdate = state.errors.find(
                (error) => error.inputType === inputType
            );
            if (errorToUpdate) {
                errorToUpdate.error = errorText ? true : false;
                errorToUpdate.errorText = errorText;
            }
        },
        setPaymentValues: (state, action) => {
            switch (action.payload.inputType) {
                case "depositAmount":
                    state.amount = action.payload.state
                    break;
                case "branchNo":
                    state.branchNo = action.payload.state
                    break;
                case "accountNo":
                    state.accountNo = action.payload.state
                    break;
                case "tckn":
                    state.tckn = action.payload.state
                    break;
                case "fullName":
                    state.fullName = action.payload.state
                    break;
                case "iban":
                    state.iban = action.payload.state
                    break;
                case "phoneNumber":
                    state.cepBankPhoneNumber = action.payload.state
                    break;
                case "phoneNumber1":
                    state.cepBankPhoneNumber2 = action.payload.state
                    break;
                case "reference":
                    state.reference = action.payload.state
                    break;
                case "expireDate":
                    state.expireDate = action.payload.state
                    break;
                case "birthdate":
                    state.birthdate = action.payload.state
                    break;
                case "citizenNo":
                    state.citizenNo = action.payload.state
                    break;
                case "cartNo":
                    state.cartNo = action.payload.state
                    break;
                case "creditCardDate":
                    state.creditCardDate = action.payload.state
                    break;
                case "cvv":
                    state.cvv = action.payload.state
                    break;
                default:
                    break;
            }
        },
        resetValues: (state) => {
            state.bonuses = []
            state.progressIndex = 0
            state.amount = 0
            state.isLoading = false
            state.bonusForSinglePaymentMethod = null
            state.redirectBtnUrlForPayment = ""
            state.bankList = []
            state.selectedBankCode = "Seçiniz"
            state.selectedBankName = "Seçiniz"
            state.cryptoPayType = "Seçiniz"
            state.isCryptoPay = false
            state.isPromotionCode = false
            state.promotionCodeStatus = "0"
            state.depositErrorText = ""
            state.depositErrorPopup = false
            state.isBankTransfer = false
            state.isPaparaHizli = false
            state.branchNo = ""
            state.accountNo = ""
            state.tckn = ""
            state.fullName = ""
            state.iban = ""
            state.cepBankPhoneNumber = ""
            state.cepBankPhoneNumber2 = ""
            state.reference = ""
            state.expireDate = ""
            state.birthdate = ""
            state.citizenNo = ""
            state.etoroCryptoPaymentType = ""
            state.cartNo = ""
            state.creditCardDate = ""
            state.cvv = ""
            state.errors = initialState.errors
        }
    },
});

//Actions
export const {
    setPaymentMethods,
    setIsPageLoading,
    setIsPaparaHizli,
    setSelectedCryptoPayType,
    setSelectedBankName,
    setIsCryptoPay,
    setEtoroCryptoPaymentType,
    setIsThorPay,
    setIsBankTransferCepBank,
    resetValues,
    setIsBankTransfer,
    setDepositErrorText,
    setPaymentStateErrors,
    setPaymentValues,
    setDepositErrorPopup,
    setDepositModal,
    setIsPromotionCode,
    setPromotionCodeStatus,
    setWithdrawModal,
    setBankList,
    setSelectedBankCode,
    setBonuses,
    setRedirectBtnUrlForPayment,
    setAmount,
    setBonusForSinglePaymentMethod,
    setProgressIndex,
    setSelectedPaymentMethod,
    setPaymentMethodsCurrency,
    setIsLoading
} = paymentSlice.actions;

///Selectors
export const selectPaymentMethods = (state) => state.payment.paymentMethods;
export const selectWithdrawModal = (state) => state.payment.withdrawModal;
export const selectDepositModal = (state) => state.payment.depositModal;
export const selectBonuses = (state) => state.payment.bonuses;
export const selectProgressIndex = (state) => state.payment.progressIndex;
export const selectAmount = (state) => state.payment.amount;
export const selectSelectedPaymentMethod = (state) => state.payment.selectedPaymentMethod;
export const selectPaymentMethodCurrency = (state) => state.payment.paymentMethodCurrency;
export const selectIsLoading = (state) => state.payment.isLoading;
export const selectBonusForSinglePaymentMethod = (state) => state.payment.bonusForSinglePaymentMethod;
export const selectRedirectBtnUrlForPayment = (state) => state.payment.redirectBtnUrlForPayment;
export const selectBankList = (state) => state.payment.bankList;
export const selectSelectedBankCode = (state) => state.payment.selectedBankCode;
export const selectSelectedBankName = (state) => state.payment.selectedBankName
export const selectIsPromotionCode = (state) => state.payment.isPromotionCode;
export const selectPromotionCodeStatus = (state) => state.payment.promotionCodeStatus;
export const selectDepositErrorText = (state) => state.payment.depositErrorText;
export const selectDepositErrorPopup = (state) => state.payment.depositErrorPopup;
export const selectPaymentStateErrors = (state) => state.payment.errors;
export const selectIsBankTransfer = (state) => state.payment.isBankTransfer;
export const selectIsThorPay = (state) => state.payment.isThorPay;
export const selectIsCryptoPay = (state) => state.payment.isCryptoPay;
export const selectIsBankTransferCepBank = (state) => state.payment.isBankTransferCepBank;
export const selectEtoroCryptoPaymentType = (state) => state.payment.etoroCryptoPaymentType;
export const selectIsPaparaHizli = (state) => state.payment.isPaparaHizli;
export const selectIsPageLoading = (state) => state.payment.isPageLoading;
export const selectCryptoPayType = (state) => state.payment.cryptoPayType;
export const selectBankTransferValues = (state) => {
    return {
        amount: state.payment.amount,
        branchNo: state.payment.branchNo,
        accountNo: state.payment.accountNo,
        tckn: state.payment.tckn,
        fullName: state.payment.fullName,
        iban: state.payment.iban,
        currency: state.payment.paymentMethodCurrency,
        cepBankPhoneNumber: state.payment.cepBankPhoneNumber,
        cepBankPhoneNumber2: state.payment.cepBankPhoneNumber2,
        reference: state.payment.reference,
        expireDate: state.payment.expireDate,
        birthdate: state.payment.birthdate,
        citizenNo: state.payment.citizenNo,
        cartNo: state.payment.cartNo,
        creditCardDate: state.payment.creditCardDate,
        cvv: state.payment.cvv,
    }
}

export default paymentSlice.reducer;