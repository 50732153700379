import React, { useState, useEffect } from "react";
import { Container, Offcanvas } from "react-bootstrap";
import PageSideBar from "../commons/PageSideBar";

//Component Imports
import Panel from "./profileTabs/Panel";
import AccountSettings from "./profileTabs/AccountSettings";
import Wallet from "./profileTabs/Wallet";
import Security from "./profileTabs/Security";

//Img Imports
import gostergePaneli from "../../assets/profil/gostergepaneli.svg";
import gostergePaneliActive from "../../assets/profil/gostergepaneli-active.svg";
import kullanici from "../../assets/profil/user.svg";
import kullaniciActive from "../../assets/profil/user-active.svg";
import cuzdan from "../../assets/profil/wallet.svg";
import cuzdanActive from "../../assets/profil/wallet-active.svg";
import guvenlik from "../../assets/profil/guvenlik.svg";
import guvenlikActive from "../../assets/profil/guvenlik-active.svg";
import logo from "../../assets/png/logo.png";

//Style Imports
import "./style.scss";
import CustomButton from "../commons/Button";
import { useDispatch, useSelector } from "react-redux";

import { getBalance, getProfile } from '../../services/profile';
import { selectUserPanel, setUserPanel } from "../../redux/slices/profileSlice";
import { useLocation } from "react-router-dom";

const Profile = () => {
  const [activeTab, setIsActiveTab] = useState(0);
  const userPanelShow = useSelector(selectUserPanel);
  const dispatch = useDispatch();
  const location = useLocation();

  const handleClose = () => {
    dispatch(setUserPanel(false));
  };
  const handleShow = () => {
    dispatch(setUserPanel(true));
  };

  const hesapTabs = [
    { title: "Profil Ayarları", component: <AccountSettings /> },
    { title: "Şifre Değiştir", component: <AccountSettings /> },
    { title: "Gelen Kutusu", component: <AccountSettings /> },
  ];

  const tabs = [
    {
      title: "Gösterge Paneli",
      tabIcon: gostergePaneli,
      activeIcon: gostergePaneliActive,
      component: <Panel />,
    },
    {
      title: "Hesap Ayarları",
      tabIcon: kullanici,
      activeIcon: kullaniciActive,
      component: <AccountSettings />,
      otherTabs: hesapTabs,
    },
    {
      title: "Cüzdan Ayarları",
      tabIcon: cuzdan,
      activeIcon: cuzdanActive,
      component: <Wallet />,
    },
    {
      title: "Güvenlik Ayarları",
      tabIcon: guvenlik,
      activeIcon: guvenlikActive,
      component: <Security />,
    },
  ];

  useEffect(() => {
    dispatch(getBalance());
    dispatch(getProfile());
    if (location.pathname === "/uye-paneli/para-yatir") {
      setIsActiveTab(2)
    }
  }, [])

  return (
    <Container>
      <div className="profile">
        <Offcanvas
          show={userPanelShow}
          onHide={handleClose}
          className="profile--mobileSideMenu"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <img src={logo} />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <PageSideBar
              tabs={tabs}
              isActiveTab={activeTab}
              setActiveTab={setIsActiveTab}
              setShow={handleClose}
            />
          </Offcanvas.Body>
        </Offcanvas>

        <div className="profile--links">
          <PageSideBar
            tabs={tabs}
            isActiveTab={activeTab}
            setActiveTab={setIsActiveTab}
          />
        </div>
        <div className="profile--content">
          <div className="aboutUs--inner--menubtn" onClick={handleShow}>
            <CustomButton
              title={"Diğer Başlıklar"}
              backgroundColor={null}
              backgroundImage={
                "linear-gradient(to right, #f19524 0%, #fb713a 100%)"
              }
              textColor={"#fff"}
              fontWeight={"normal"}
              width={"100%"}
              fontSize={"14px"}
            />
          </div>

          {tabs[activeTab].component}
        </div>
      </div>
    </Container>
  );
};

export default Profile;
