//Style Imports
import "./style.scss";
import playStoreIcon from '../../../assets/icons/playStore.svg';
import appStoreIcon from '../../../assets/icons/appStore.svg';
import React from "react";
import { Link } from "react-router-dom"

const FooterColumn = () => {
  const footerColumns  = [
    {
      title: "Kurumsal",
      links: [
        { link: "/hakkimizda", title: "Etorobet Hakkında" },
        { link: "#", title: "Ortaklık Programı" },
        { link: "#", title: "Sıkça Sorulan Sorular" },
        { link: "#", title: "Etoro TV" },
        { link: "#", title: "İletişime Geçin" },
      ],
    },
    {
      title: "Kurallar",
      links: [
        { link: "#", title: "Kullanım Şartları" },
        { link: "#", title: "Bahis Kuralları" },
        { link: "#", title: "Casino Kuralları" },
        { link: "#", title: "Oyun Kuralları" },
        { link: "#", title: "Poker Kuralları" },
      ],
    },
    {
      title: "Destek",
      links: [
        { link: "#", title: "Para Yatırma" },
        { link: "#", title: "Para Çekme" },
        { link: "#", title: "Promosyonlar" },
        { link: "#", title: "Adaletli Oyun ve Metodlar" },
        { link: "#", title: "Hesaplar,Ödemeler" },
      ],
    },
    {
      title: "Diğer",
      links: [
        { link: "#", title: "Kişisel Verilerin Korunması" },
        { link: "#", title: "Sorumlu Oyun" },
        { link: "#", title: "Para Aklama Önlemleri" },
        { link: "#", title: "Kendini Dışlama" },
        { link: "#", title: "Çerezler" },
      ],
    },
    {
      title: "Diğer",
      links: [
        { link: "#", title: "Kişisel Verilerin Korunması" },
        { link: "#", title: "Sorumlu Oyun" },
        { link: "#", title: "Para Aklama Önlemleri" },
        { link: "#", title: "Kendini Dışlama" },
        { link: "#", title: "Çerezler" },
      ],
    },
  ];

  return (
    <>
      {footerColumns.map((item,index) => (
        <div className="footer-column" key={item.title+index}>
          <div className="footer-column--title">{item.title}</div>
          <div className="footer-column--links">
            <ul>
              {item.links.map((link) => (
                <li key={link.title}><Link to={link.link} onClick={() => window != undefined && window.scrollTo(0, 0)}>{link.title}</Link></li>
              ))}
            </ul>
          </div>
        </div>
      ))}
      <div className="footer-mobile-links">
            <div className="footer-mobile-links--title">Mobil Uygulamalar</div>
            <div className="footer-mobile-links--icons">
                <img src={playStoreIcon} />
                <img src={appStoreIcon} />
            </div>
      </div>
    </>
  );
};

export default FooterColumn;
