import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsPromotionCode, selectPromotionCodeStatus, selectRedirectBtnUrlForPayment, setProgressIndex } from '../../../redux/slices/paymentSlice'
import ButtonET from '../../commons/CustomButton'

const DepositModal3 = () => {

    const dispatch = useDispatch()
    const paymentUrl = useSelector(selectRedirectBtnUrlForPayment)
    const isPromotionCode = useSelector(selectIsPromotionCode);
    const promotionCodeStatus = useSelector(selectPromotionCodeStatus)

    return (
        <>
            {
                !isPromotionCode ?
                    <div className="depositModal--btns">
                        <ButtonET
                            title="İptal Et ve Geri Dön"
                            onClick={() => dispatch(setProgressIndex(1))}
                        />
                        <ButtonET
                            btnType='secondaryBtn'
                            title="Ödemeye Git"
                            onClick={() => window.open(paymentUrl, '_blank', 'noreferrer')}
                        />
                    </div> : (
                        <div className='depositModal--promotionCodeResult'>
                            <img alt='icon' src={promotionCodeStatus === "1" ? "/assets/success.png" : promotionCodeStatus === "2" ? "/assets/failure.png" : ""} />

                            <span>{promotionCodeStatus === "1" ? "Odeme Onaylandi" : promotionCodeStatus === "2" ? "Odeme Reddedildi" : ""}</span>
                        </div>
                    )
            }
        </>
    )
}

export default DepositModal3