import React, { useState } from 'react'
import './style.scss'
import PasswordShowIcon from '../../../assets/icons/password-show.svg'
import PasswordHideIcon from '../../../assets/icons/password-hide.svg'

const Input = ({
    labelText = "",
    isPassword = false,
    type = "text",
    placeholder,
    error = false,
    errorText = "",
    onChange = () => { },
    onBlur = () => { },
    onKeyDown = () => { },
    disabled = false,
    value,
    maxLength = 0
}) => {

    const [showPassword, setShowPassword] = useState(false);

    return (
        <div className='customInputWrapper'>
            {
                labelText !== "" ?
                    <div className={`input-label ${error ? "input-error" : ""}`}>{labelText}</div>
                    : <></>
            }
            <div className={`${error ? "input input-error" : "input"} ${disabled ? "input-disabled" : ""}`}>
                <input
                    onChange={onChange}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                    placeholder={placeholder}
                    value={value}
                    disabled={disabled}
                    maxLength={maxLength !== 0 ? maxLength : 150}
                    type={type === "password" || type === "passwordRepeat" ? showPassword ? "text" : "password" : type} />
                {
                    isPassword ? <img className='password-icon' alt="password-icon" src={showPassword ? PasswordShowIcon : PasswordHideIcon} onClick={() => setShowPassword(!showPassword)} /> : <></>
                }
                {
                    error && errorText ?
                        <div className='inputErrorText'>
                            {
                                errorText
                            }
                        </div>
                        : <></>
                }
            </div>
        </div>
    )
}

export default Input