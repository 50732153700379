import React from "react";
import "./style.scss";

const Aboutus = () => {
  return (
    <div className="tab--aboutus">
      <span>Hakkımızda</span>

      <p>
        Etorobet 300‘den fazla çalışanı ile dünyanın önde gelen online bahis ve
        oyun şirketlerinden biridir.
      </p>

      <p>
        Hizmet hayatımıza başladığımız 2013 yılından itibaren oyuncularımız, iş
        ortaklarımız ve birbirimizle olan ilişkilerimizde sonuna kadar şeffaf,
        dürüst ve adil politikalar tercih ediyoruz. İşimizi ve verdiğimiz
        hizmeti eğlenceli yapmanın tek yolunun bu olduğuna inanıyoruz.
      </p>

      <p>
        Müşterilerimizi, ortaklarımızı ve çalışanlarımızı desteklemek adına her
        zaman doğru olanı yapmayı çabalayacağız. Belirlediğimiz yüksek
        standartlar ile örnek bir online bahis ve oyun şirketi olarak bu
        sektörde öncü olacağız.
      </p>
      <p>
        Pozitif bahis anlayışının öncülerinden olarak yenilikçi, teknolojik
        olarak ileri seviyede konseptler geliştirerek en iyi seviyede hizmet
        anlayışı ile mümkün olan her türlü alana yayılarak müşterilerimize maddi
        ve manevi kazanç sağlamayı kendimize görev ediniyoruz.
      </p>

      <p>
        Online bahsin eğlenceli olması gerektiğine inanıyoruz ve elimizden
        gelenin en iyisini yaparak online bahis tecrübesini herkes için
        eğlenceli bir hale getirmek için çalışıyoruz. Etorobet hayallerin gerçek
        olduğu yer…
      </p>
    </div>
  );
};

export default Aboutus;
