import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
    authenticatedUser: '',
    isFailed: false,
    loginModal: false,
    activeTab: 0,
    registerModal: false,
    loginErrorMessage: "",
    userName: "",
    password: "",
    errors: [
        { inputType: 'username', error: false, errorText: "" },
        { inputType: 'password', error: false, errorText: "" },
    ]
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLoggedInUser: (state, action) => {
            state.authenticatedUser = action.payload
        },
        setIsAttemptFailed: (state, action) => {
            state.isFailed = action.payload
        },
        setLoginModal: (state, action) => {
            state.loginModal = action.payload
        },
        setActiveTab: (state, action) => {
            state.activeTab = action.payload
        },
        setRegisterModal: (state, action) => {
            state.registerModal = action.payload
        },
        setLoginErrorMessage: (state, action) => {
            state.loginErrorMessage = action.payload
        },
        setAuthErrors: (state, action) => {
            const { inputType, errorText } = action.payload;
            const errorToUpdate = state.errors.find(
                (error) => error.inputType === inputType
            );
            if (errorToUpdate) {
                errorToUpdate.error = errorText ? true : false;
                errorToUpdate.errorText = errorText;
            }
        },
        setValuesAuth: (state, action) => {
            switch (action.payload.inputType) {
                case "username":
                    state.userName = action.payload.state
                    break;
                case "password":
                    state.password = action.payload.state
                    break;
                default:
                    break;
            }
        }
    }
});

//Actions 
export const { setIsAttemptFailed, setAuthErrors, setValuesAuth, setLoginErrorMessage, setLoginModal, setLoggedInUser, setActiveTab, setRegisterModal } = authSlice.actions;

///Selectors
export const selectLoggedInUser = (state) => state.auth.authenticatedUser;
export const selectIsFailed = (state) => state.auth.isFailed;
export const selectLoginModal = (state) => state.auth.loginModal;
export const selectActiveTab = (state) => state.auth.activeTab;
export const selectRegisterModal = (state) => state.auth.registerModal;
export const selectLoginErrorMessage = (state) => state.auth.loginErrorMessage;
export const selectAuthInputErrors = (state) => state.auth.errors;
export const selectLoginUserName = (state) => state.auth.userName;
export const selectLoginPassword = (state) => state.auth.password;

export default authSlice.reducer;