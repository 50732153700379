import React, { useState } from "react";
import "../style.scss";

import closeIcon from "../../../assets/sportsbook/X.svg";
import CustomButton from "../../commons/Button";

const BetsBox = () => {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <div className="sportsbook--content--right--betsBox">
      <div className="sportsbook--content--right--betsBox--tabs">
        <div
          onClick={() => setActiveTab(0)}
          className={`sportsbook--content--right--betsBox--tabs--tab ${
            activeTab == 0 && "activeBetsBoxTab"
          }`}
        >
          Tekli
        </div>
        <div
          onClick={() => setActiveTab(1)}
          className={`sportsbook--content--right--betsBox--tabs--tab ${
            activeTab == 1 && "activeBetsBoxTab"
          }`}
        >
          Kombine
        </div>
        <div
          onClick={() => setActiveTab(2)}
          className={`sportsbook--content--right--betsBox--tabs--tab ${
            activeTab == 2 && "activeBetsBoxTab"
          }`}
        >
          Sistem
        </div>
      </div>
      <div className="sportsbook--content--right--betsBox--content">
        <div className="sportsbook--content--right--betsBox--content--cod">
          <span>{`Seçimler (1)`}</span>
          <span>{`Hepsini Sil`}</span>
        </div>
        <div className="sportsbook--content--right--betsBox--content--event">
          <div className="sportsbook--content--right--betsBox--content--event--top">
            <div>
              <input type="checkbox" />
              <span>Gine - Gambiya</span>
            </div>
            <div>
              <span>1,68</span>
              <img src={closeIcon} />
            </div>
          </div>
          <div className="sportsbook--content--right--betsBox--content--event--bot">
            <span>Kim Kazanır (NS)</span>
            <span>Gine - Gambiya</span>
            <span>20:45 - Salı, 25. Ocak</span>
          </div>
        </div>
        <div className="sportsbook--content--right--betsBox--content--event">
          <div className="sportsbook--content--right--betsBox--content--event--top">
            <div>
              <input type="checkbox" />
              <span>Gine - Gambiya</span>
            </div>
            <div>
              <span>1,68</span>
              <img src={closeIcon} />
            </div>
          </div>
          <div className="sportsbook--content--right--betsBox--content--event--bot">
            <span>Kim Kazanır (NS)</span>
            <span>Gine - Gambiya</span>
            <span>20:45 - Salı, 25. Ocak</span>
          </div>
        </div>

        <div className="sportsbook--content--right--betsBox--content--selectedBetsInfo">
          <div>
            <div>
              <span>Kupon Başına Tutar</span>
              <span>1 Kupon</span>
            </div>
            <div>
              <span>TRY</span>
              <span>5.00</span>
            </div>
          </div>
          <div>
            <span>Toplam bahis tutarı</span>
            <span>5.00 ₺</span>
          </div>
          <div>
            <span>Toplam Oranlar</span>
            <span>7,73</span>
          </div>
          <div>
            <span>Toplam Kazanç</span>
            <span>38.64 ₺</span>
          </div>

          <div>
            <input type="checkbox" id="betsCheckBox" />
            <label>Bahislerimi Tut</label>
          </div>

          <CustomButton
            title={"Bahis Yap"}
            backgroundColor={null}
            backgroundImage={"linear-gradient(84deg, #1631ba 0%, #863cc4 100%)"}
            textColor={"#fff"}
            fontWeight={"500"}
            width={"100%"}
            height={"34.2px"}
            fontSize={"12px"}
          />
        </div>
      </div>
    </div>
  );
};

export default BetsBox;
