import React from "react";

const AccordionType4 = () => {
  return (
    <div className="accordionType4">
      <div className="accordionType4--line">
        <div className="accordionType4--line--section">
          <div>
            <span>1.07</span>
            <span>-2.5</span>
          </div>
          <span className="accordionType4--line--section--team">Üst</span>
        </div>
        <div className="accordionType4--line--section">
          <span className="accordionType4--line--section--team">Alt</span>
          <div>
            <span>-2.5</span>
            <span>1.07</span>
          </div>
        </div>
      </div>
      <div className="accordionType4--line">
        <div className="accordionType4--line--section">
          <div>
            <span>1.07</span>
            <span>-2.5</span>
          </div>
          <span className="accordionType4--line--section--team">Üst</span>
        </div>
        <div className="accordionType4--line--section">
          <span className="accordionType4--line--section--team">Alt</span>
          <div>
            <span>-2.5</span>
            <span>1.07</span>
          </div>
        </div>
      </div>
      <div className="accordionType4--line">
        <div className="accordionType4--line--section">
          <div>
            <span>1.07</span>
            <span>-2.5</span>
          </div>
          <span className="accordionType4--line--section--team">Üst</span>
        </div>
        <div className="accordionType4--line--section">
          <span className="accordionType4--line--section--team">Alt</span>
          <div>
            <span>-2.5</span>
            <span>1.07</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccordionType4;
