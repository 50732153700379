//Component Imports
import { Container } from "react-bootstrap";
import NavItem from "../../commons/NavItem";
import React from "react";
import { Link } from "react-router-dom";

//SVG Imports
import sports from "../../../assets/icons/sports.svg";
import live from "../../../assets/icons/live.svg";
import virtual from "../../../assets/icons/virtual.svg";
import poker from "../../../assets/icons/poker.svg";
import casino from "../../../assets/icons/casino.svg";
import slots from "../../../assets/icons/slots.svg";
import other from "../../../assets/icons/others.svg";
import promotionIcon from "../../../assets/icons/promotion.svg";

//Style Imports
import "./styles.scss";

const NavTabs = ({ handleMobileMenuClick }) => {
  return (
    <div className="header headerBot">
      <Container>
        <div className="headerBot__wrapper">
          <div className="headerBot__wrapper--left">
            <Link to="/sportsbook-live">
              <NavItem
                title={"Spor Bahisleri"}
                img={sports}
                iconName={"sports"}
                handleMobileMenuClick={handleMobileMenuClick}
              />
            </Link>
            <NavItem
              title={"Canlı Bahis"}
              img={live}
              iconName={"live"}
              handleMobileMenuClick={handleMobileMenuClick}
            />
            <NavItem
              title={"Sanal Bahis"}
              img={virtual}
              iconName={"virtual"}
              handleMobileMenuClick={handleMobileMenuClick}
            />
            <NavItem
              title={"Canlı Poker"}
              img={poker}
              iconName={"poker"}
              handleMobileMenuClick={handleMobileMenuClick}
            />
            <Link to="/casino">
              <NavItem
                title={"Canlı Casino"}
                img={casino}
                iconName={"casino"}
                handleMobileMenuClick={handleMobileMenuClick}
              />
            </Link>
            <NavItem
              title={"3D Slotlar"}
              img={slots}
              iconName={"slots"}
              handleMobileMenuClick={handleMobileMenuClick}
            />
            <NavItem
              title={"Diğer Oyunlar"}
              img={other}
              iconName={"other"}
              handleMobileMenuClick={handleMobileMenuClick}
            />
            <div className="headerBot__wrapper--promotion">
              <img src={promotionIcon} alt="promotion" />
              <span>PROMOSYONLAR</span>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default NavTabs;
