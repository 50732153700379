import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    mobileMenu: false,
}

export const headerSlice = createSlice({
    name: 'header',
    initialState,
    reducers: {
        setMobileMenu: (state, action) => {
            state.mobileMenu = action.payload
        },
    }
});

//Actions 
export const { setMobileMenu } = headerSlice.actions;

///Selectors
export const selectMobileMenu = (state) => state.header.mobileMenu;

export default headerSlice.reducer;