import React from "react";
import { Button, Spinner } from "react-bootstrap";
import "./style.scss";

const CustomButton = ({
  imgSrc,
  title,
  backgroundColor,
  textColor,
  backgroundImage,
  fontWeight,
  width,
  height,
  fontSize,
  setState,
  disabled,
  stateToSet,
  onClick,
  isLoadingIndicator = false
}) => {
  return (
    <Button
      className={`customBtn ${isLoadingIndicator ? "loadingIndicatorBtn" : ""}`}
      disabled={disabled}
      onClick={() => {
        if (setState) {
          if (typeof stateToSet == "boolean") {
            setState(!stateToSet)
          } else {
            setState(stateToSet)
          }
        } else if (onClick) {
          onClick()
        }
      }}
      style={{
        color: textColor,
        backgroundColor: backgroundColor ?? "",
        backgroundImage: backgroundImage ?? "",
        fontWeight: fontWeight,
        width: width ? width : "",
        height: height ? height : "",
        fontSize: fontSize ? fontSize : ""
      }}
    >
      {imgSrc ? <img src={imgSrc} /> : <></>}
      <span>{title}</span>
      {
        isLoadingIndicator ?
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner> : <></>
      }
    </Button>
  );
};

export default CustomButton;
