import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import * as yup from "yup";
import InputBoxArea from "../../../commons/CustomModal/InputBoxArea";
import CustomButton from "../../../commons/Button";

import CloseEyeIcon from '../../../../assets/icons/open_eye.svg';
import OpenEyeIcon from '../../../../assets/icons/close_eye.svg';
import { selectIsFailed, setIsAttemptFailed } from "../../../../redux/slices/authSlice";
import { changePassword } from "../../../../services/auth";

const ChangePassword = () => {

    let reset;
    const dispatch = useDispatch();
    const isFailed = useSelector(selectIsFailed);
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showNewPasswordRepeat, setShowNewPasswordRepeat] = useState(false);

    useEffect(() => {
        dispatch(setIsAttemptFailed(false));
    }, [])

    const changePasswordSchema = yup.object({
        previousPassword: yup.string().required("* Eski şifrenizi girin"),
        newPassword: yup.string().required("* Şifre Girin").matches(/^[\S]+.*[\S]+$/g).min(6, "* En az 6 karakter girin"),
        newPasswordRepeat: yup.string().required("* Şifre Girin").matches(/^[\S]+.*[\S]+$/g).min(6, "* En az 6 karakter girin").oneOf([yup.ref("newPassword"), null], "* Şifreler aynı değil"),
    });

    useEffect(() => {
        if (isFailed) {
            reset()
            dispatch(setIsAttemptFailed(false));
        }
    }, [isFailed])

    const initialValues = {
        previousPassword: "",
        newPassword: "",
        newPasswordRepeat: ""
    };

    const handleUpdatePassword = (values) => {
        dispatch(changePassword(values))
    }

    return (
        <Container>
            <div className="profileSettings-wrapper">
                <span className="profileSettings-wrapper--header">Şifre Değiştir</span>

                <Formik
                    validationSchema={changePasswordSchema}
                    initialValues={initialValues}
                    onSubmit={(values) => { }}
                >
                    {({ handleSubmit, handleChange, values, touched, errors, resetForm }) => {
                        reset = resetForm;
                        return <Form noValidate onSubmit={handleSubmit}>

                            <div className="profileSettings-wrapper--passwordArea">
                                <div className="profileSettings-wrapper--passwordArea--oldPassword">
                                    <InputBoxArea
                                        key={"previousPassword"}
                                        error={errors["previousPassword"]}
                                        value={values["previousPassword"]}
                                        handleChange={handleChange}
                                        touched={touched["previousPassword"]}
                                        formName={"previousPassword"}
                                        placeholder={"••••••••"}
                                        title={"Şifre"}
                                        type={showPassword ? "text" : "password"}
                                        showPasswordIcons
                                        showPasswordIconState={showPassword}
                                        setShowIconState={setShowPassword}
                                    />
                                </div>


                                <div className="profileSettings-wrapper--passwordArea--newPassword">
                                    <InputBoxArea
                                        key={"newPassword"}
                                        error={errors["newPassword"]}
                                        value={values["newPassword"]}
                                        handleChange={handleChange}
                                        touched={touched["newPassword"]}
                                        formName={"newPassword"}
                                        placeholder={"••••••••"}
                                        title={"Yeni Şifre"}
                                        type={showNewPassword ? "text" : "password"}
                                        showPasswordIcons
                                        showPasswordIconState={showNewPassword}
                                        setShowIconState={setShowNewPassword}
                                    />

                                    <InputBoxArea
                                        key={"newPasswordRepeat"}
                                        error={errors["newPasswordRepeat"]}
                                        value={values["newPasswordRepeat"]}
                                        handleChange={handleChange}
                                        touched={touched["newPasswordRepeat"]}
                                        formName={"newPasswordRepeat"}
                                        placeholder={"••••••••"}
                                        title={"Yeni Şifre (Tekrar)"}
                                        type={showNewPasswordRepeat ? "text" : "password"}
                                        showPasswordIcons
                                        showPasswordIconState={showNewPasswordRepeat}
                                        setShowIconState={setShowNewPasswordRepeat}
                                    />
                                </div>
                            </div>

                            <div className="loginModal--btn">
                                <CustomButton
                                    title="Güncelle"
                                    backgroundColor={null}
                                    textColor={"#fff"}
                                    backgroundImage={
                                        "linear-gradient(84deg, #863cc4 0%, #1631ba 100%)"
                                    }
                                    fontWeight={"normal"}
                                    disabled={Object.keys(errors).length === 0 && values.previousPassword && values.newPassword && values.newPasswordRepeat && values.newPassword === values.newPasswordRepeat ? false : true}
                                    fontSize={"14px"}
                                    width={"100%"}
                                    onClick={() => handleUpdatePassword(values)}
                                />
                            </div>
                        </Form>
                    }}
                </Formik>
            </div>
        </Container>
    );
};

export default ChangePassword;
