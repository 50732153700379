import React from 'react';
import "../style.scss";
import MatchesBoxItem from './MatchesBoxItem';

const MatchesBox = () => {
  return (
    <div className="sportsbook--content--left--matchesBox">
      <MatchesBoxItem />
      <MatchesBoxItem />
      <MatchesBoxItem />
      <MatchesBoxItem />
      <MatchesBoxItem />
      <MatchesBoxItem />
      <MatchesBoxItem />
      <MatchesBoxItem />
    </div>
  )
}

export default MatchesBox