import React from "react";

//Style Imports
import "./style.scss";

//Component Imports
import { Accordion } from "react-bootstrap";
import AccordionType1 from "./AccordionTypes/AccordionType1";
import AccordionType2 from "./AccordionTypes/AccordionType2";
import AccordionType3 from "./AccordionTypes/AccordionType3";
import AccordionType4 from "./AccordionTypes/AccordionType4";
import MatchInfoAccordionBody from "./MatchInfoAccordionBody";

//Img Imports
import streamIcon from "../../../assets/sportsbook/stream.svg";
import linesIcon from "../../../assets/sportsbook/3Line.svg";

const CustomAccordion = ({
  accordionItem,
  isTable,
  isMatchInfo,
  accordionType,
}) => {
  const detectType = () => {
    switch (accordionType) {
      case "1":
        return <AccordionType1 />;
      case "2":
        return <AccordionType2 />;
      case "3":
        return <AccordionType3 />;
      case "4":
        return <AccordionType4 />;
      default:
        return <></>;
        break;
    }
  };

  return (
    <Accordion className="customAccordions" defaultActiveKey={"0"}>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          {accordionItem.eventIcon && <img src={accordionItem.eventIcon} />}
          <span>{accordionItem.event}</span>
        </Accordion.Header>
        <Accordion.Body>
          <div>
          {isTable && (
            <table>
              <tr className="customAccordions--header">
                <th>Tarih</th>
                <th>Maç</th>
                <th></th>
                <th></th>
                <th>1</th>
                <th>X</th>
                <th>2</th>
                <th>Üst</th>
                <th>Alt</th>
                <th>+</th>
              </tr>
              {accordionItem.listOfMatches &&
                accordionItem?.listOfMatches.map((match) => (
                  <tr className="customAccordions--tableItem" key={match.teams}>
                    <td>
                      <span>{match.eventDay}</span>
                      <span>{match.eventTime}</span>
                    </td>
                    <td>{match.teams}</td>
                    <td>
                      <img src={streamIcon} />
                    </td>
                    <td>
                      <img src={linesIcon} />
                    </td>
                    <td>
                      <span className="customAccordions--tableItem--ratio">
                        {match.ratio1}
                      </span>
                    </td>
                    <td>
                      <span className="customAccordions--tableItem--ratio">
                        {match.ratioX}
                      </span>
                    </td>
                    <td>
                      <span className="customAccordions--tableItem--ratio">
                        {match.ratio2}
                      </span>
                    </td>
                    <td>
                      <span className="customAccordions--tableItem--ratio">
                        {match.ratioUst}
                      </span>
                    </td>
                    <td>
                      <span className="customAccordions--tableItem--ratio">
                        {match.ratioAlt}
                      </span>
                    </td>
                    <td>
                      <span className="customAccordions--tableItem--ratio ratio-black">
                        {match.ratioPlus}
                      </span>
                    </td>
                  </tr>
                ))}
            </table>
          )}
          {isMatchInfo && <MatchInfoAccordionBody />}
          {detectType()}
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default CustomAccordion;
