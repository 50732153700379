import React from "react";
import { Accordion } from "react-bootstrap";

import "../style.scss";
import favoritedIcon from "../../../assets/sportsbook/favorited.svg";
import ukFlag from "../../../assets/sportsbook/ukFlag.svg";

const LiveBetsBoxAccordionItem = ({ isActive }) => {
  const count = [1, 2, 3];

  return (
    <div className="sportsbook-live-liveBetsBox--content--countries--item">
      <Accordion defaultActiveKey={isActive ? "0" : "-1"}>
        <Accordion.Item eventKey="0">
          <Accordion.Header className="sportsbook-live-liveBetsBox--content--countries--item--header">
            <img src={ukFlag} />
            <span>İngiltere</span>
            <span>12</span>
          </Accordion.Header>
          <Accordion.Body>
            {count.map((c) => (
              <div className="sportsbook-live-liveBetsBox--content--countries--item--match" key={c+6}>
                <div className="sportsbook-live-liveBetsBox--content--countries--item--match--left">
                  <span>Fransa</span>
                  <span>Türkiye</span>
                  <span>45' Devre Arası</span>
                </div>
                <div className="sportsbook-live-liveBetsBox--content--countries--item--match--right">
                  <span>1</span>
                  <span>3</span>
                  <img src={favoritedIcon} />
                </div>
              </div>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default LiveBetsBoxAccordionItem;
